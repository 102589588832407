import { useState } from 'react'

import { AxiosResponse } from 'axios'

export enum HighRiskContactCardState {
  START = 'start',
  INPUT = 'input',
  LOADING = 'loading',
  CONFIRMED = 'confirmed',
  ERROR = 'error',
}

export interface HighRiskContactCardProps {
  onHighRiskContactCardDismiss: () => void
  onCallYouPressed: () => void
  onSubmitPhoneNumberPressed: (values: { smsNumber: string }) => void
  state: HighRiskContactCardState
  customEmergencyInfo?: React.ReactNode
  userPhoneNumber?: string | null
  hideCloseIcon?: boolean
}

export interface useHighRiskContactCardArgs {
  onHighRiskContactCardDismiss: () => void
  postQueueHighAlert: (args: { searchId: string; phoneNumber: string }) => Promise<AxiosResponse>
  searchId: string
}

export const useHighRiskContactCard = ({
  onHighRiskContactCardDismiss,
  postQueueHighAlert,
  searchId,
}: useHighRiskContactCardArgs): HighRiskContactCardProps => {
  const [formState, setFormState] = useState(HighRiskContactCardState.START)

  async function onSubmitPhoneNumberPressed(values: { smsNumber: string }) {
    setFormState(HighRiskContactCardState.LOADING)
    await postQueueHighAlert({
      searchId,
      phoneNumber: values.smsNumber,
    })
      .then((response) => {
        if (response.status === 200) {
          setFormState(HighRiskContactCardState.CONFIRMED)
        } else {
          setFormState(HighRiskContactCardState.ERROR)
        }
      })
      .catch(() => {
        setFormState(HighRiskContactCardState.ERROR)
      })
  }

  return {
    onHighRiskContactCardDismiss: () => {
      onHighRiskContactCardDismiss()
    },
    onCallYouPressed: () => {
      setFormState(HighRiskContactCardState.INPUT)
    },
    onSubmitPhoneNumberPressed,
    state: formState,
  }
}
