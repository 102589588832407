import {
  SEARCH_FOR_CARE_CALL_TO_ACTION_VARIANT_TYPE,
  SEARCH_FOR_CARE_EXPERIMENT_VARIANT_TYPE,
  SEARCH_FOR_CARE_ILLUSTRATION_VARIANT_TYPE,
} from './types'

export const getIsSingleCTA = (type: SEARCH_FOR_CARE_CALL_TO_ACTION_VARIANT_TYPE): boolean => {
  return type === SEARCH_FOR_CARE_EXPERIMENT_VARIANT_TYPE.D
}

export const getIsCenterIllustration = (type: SEARCH_FOR_CARE_ILLUSTRATION_VARIANT_TYPE): boolean => {
  return type === SEARCH_FOR_CARE_EXPERIMENT_VARIANT_TYPE.A
}
