import { useCallback, useEffect, useMemo, useState } from 'react'

import { isEmpty } from 'lodash-es'

import { useInProgressSearch } from './useInProgressSearch'
import { useSearchAgeAndType } from './useSearchAgeAndType'
import { Appointment } from '../../../models'
import {
  GetAllTriageSearchesActions,
  GetNextQuestionActions,
  GetTriageMetadataActions,
  NewSearchData,
  SearcherInfo,
  TriageSearch,
  TriageSearchStatus,
} from '../../../models/searchForCare/SearchForCare'
import { TriageScreener, TriageScreenerNames } from '../../../models/triageScreener/TriageScreener'
import { User } from '../../../models/user/User'
import { SEARCHER_TYPE_TO_MATCH_FOR } from '../constants'
import { getNewSearchData } from '../utils'

export interface useCommonTriageSearchDataArgs {
  searches: TriageSearch[]
  user: User
  searcherInfo: SearcherInfo | null
  selectedSearch?: TriageSearch
  triageMetadata: TriageScreener[]
  triageQuestions: TriageScreenerNames[]
  loadingNextQuestion: boolean
  updatingSearch: boolean
  appointments: Appointment[]
  matchFor: SEARCHER_TYPE_TO_MATCH_FOR
  log: (searchId?: string) => void
  getAllTriageSearches: (arg: GetAllTriageSearchesActions) => Promise<any>
  getTriageMetadata: (arg: GetTriageMetadataActions) => Promise<any>
  postTriageSearch: (arg: NewSearchData) => Promise<any>
  setSelectedSearch: (searchId: string) => void
  setTriageQuestions: (arg: Array<TriageScreenerNames | undefined | string>) => void
  getNextTriageQuestion: (arg: GetNextQuestionActions) => Promise<any>
  logException: (message: string, extras: any, error: unknown) => void
  shouldCreateNewSearch?: boolean
  isLctTeensEnabled?: boolean
  triageScreenersMetadataVersion?: string
}

/**
 * Handles initial apis calls for triage searches
 */
export const useCommonTriageSearchData = ({
  searches,
  user,
  searcherInfo,
  selectedSearch,
  triageMetadata,
  triageQuestions,
  loadingNextQuestion,
  updatingSearch,
  appointments,
  matchFor,
  log,
  getAllTriageSearches,
  getTriageMetadata,
  postTriageSearch,
  setSelectedSearch,
  setTriageQuestions,
  getNextTriageQuestion,
  logException,
  shouldCreateNewSearch,
  isLctTeensEnabled,
  triageScreenersMetadataVersion,
}: useCommonTriageSearchDataArgs) => {
  const [hasLoadedInitial, setHasLoadedInitial] = useState(false)
  const [fetchInitialCalled, setFetchInitialCalled] = useState(false)
  const { id: userId, gender, customers, dob } = user || {}
  const newSearchData = useMemo(
    () =>
      getNewSearchData({
        dob,
        userId,
        gender: gender?.gender,
        employer: customers?.name,
        type: searcherInfo?.type,
        isLctTeensEnabled,
      }),
    [customers?.name, dob, gender?.gender, isLctTeensEnabled, searcherInfo?.type, userId],
  )
  const inProgressSearch = useInProgressSearch(searches, appointments)
  const getMetadataAge = useSearchAgeAndType()

  // Initial fetch to get all searches
  useEffect(() => {
    const fetchInitialSearchesAndMetadata = async (userId: string) => {
      const { age } = getMetadataAge({ dob, matchFor, age: searcherInfo?.age })
      try {
        setFetchInitialCalled(true)
        await Promise.all([
          getAllTriageSearches({ userId, hideError: [404] }),
          getTriageMetadata({ age, version: triageScreenersMetadataVersion }),
        ])
      } catch (error) {
        logException('Unable to fetch initial searches metadata', { source: 'useTriageSearchData.ts' }, error)
      } finally {
        setHasLoadedInitial(true)
      }
    }
    if (!hasLoadedInitial && !!userId && !shouldCreateNewSearch && !fetchInitialCalled && !updatingSearch) {
      fetchInitialSearchesAndMetadata(userId)
    }
  }, [
    dob,
    getAllTriageSearches,
    getMetadataAge,
    getTriageMetadata,
    hasLoadedInitial,
    matchFor,
    searcherInfo?.age,
    logException,
    userId,
    shouldCreateNewSearch,
    fetchInitialCalled,
    triageScreenersMetadataVersion,
    updatingSearch,
  ])

  const createNewSearch = useCallback(
    (newSearchData: NewSearchData) => {
      try {
        postTriageSearch(newSearchData)
      } catch (error) {
        logException('Unable to create new search', { source: 'useTriageSearchData.ts' }, error)
      }
    },
    [postTriageSearch, logException],
  )

  useEffect(() => {
    if (hasLoadedInitial && !isEmpty(newSearchData) && !selectedSearch && !updatingSearch) {
      if (!searches.length) {
        // If searches are still empty after initial load then create a new search
        createNewSearch(newSearchData)
      } else if (searches.length) {
        if (inProgressSearch?.id) {
          // If a search is already in progress set it to active
          setSelectedSearch(inProgressSearch.id)
          log(inProgressSearch?.id)
        } else {
          // If there are no active searches then create a new one
          createNewSearch(newSearchData)
        }
      }
    }
  }, [
    hasLoadedInitial,
    inProgressSearch?.id,
    log,
    newSearchData,
    postTriageSearch,
    searches.length,
    selectedSearch,
    logException,
    setSelectedSearch,
    updatingSearch,
    createNewSearch,
  ])

  useEffect(() => {
    if (shouldCreateNewSearch && !isEmpty(newSearchData) && !updatingSearch) {
      createNewSearch(newSearchData)
    }
  }, [createNewSearch, newSearchData, shouldCreateNewSearch, updatingSearch])

  // Handle in progress searches
  useEffect(() => {
    if (
      !!selectedSearch &&
      selectedSearch?.triage_status === TriageSearchStatus?.IN_PROGRESS &&
      !triageQuestions.length &&
      triageMetadata.length
    ) {
      // If selected search already has triage_metadata we should add them to triageQuestions in the store
      if (selectedSearch.triage_metadata.length) {
        // TODO: We shouldn't have to map id to names
        const searchMetadataNames = selectedSearch?.triage_metadata.map(
          (id) => triageMetadata.find((metadata) => metadata.id === id)?.name,
        )
        !!searchMetadataNames.length && setTriageQuestions(searchMetadataNames)
      } else if (!loadingNextQuestion) {
        // If selected search does not have triage_metadata we should get the first question
        getNextTriageQuestion({ id: selectedSearch.id, hideError: [404] })
      }
    }
  }, [
    getNextTriageQuestion,
    loadingNextQuestion,
    selectedSearch,
    setTriageQuestions,
    triageMetadata,
    triageQuestions.length,
  ])

  return { hasLoadedInitial }
}
