import React, { FunctionComponent, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { defineMessage, FormattedMessage, useIntl } from 'react-intl'
import { Dimensions, GestureResponderEvent, ImageSourcePropType } from 'react-native'

import { StatusBar } from 'expo-status-bar'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { required } from '@lyrahealth-inc/shared-app-logic'

import {
  DawnBackground,
  DawnBackgroundMobile,
  DawnBackgroundMobileWeb,
  DawnBackgroundWiderAspectRatio,
} from '../../assets'
import { BodyText, BulletedList, CheckMarkInCircleIcon, Headline, PrimaryButton } from '../../atoms'
import { InputFieldRFF } from '../../atoms/formElements/InputField'
import { Image } from '../../atoms/image/Image'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { IS_WEB } from '../../constants'
import { InputAccessory } from '../../molecules'
import { BodyTextSize, getFloatingStyles, HeadlineSize } from '../../styles'
import { Flex1View } from '../../templates/content/CommonViews'
import { FixedButtonFormPageTemplate } from '../../templates/fixedButtonFormPageTemplate/FixedButtonFormPageTemplate'
import { ThemeType, tID } from '../../utils'

export interface FindEmployerProps {
  onSubmit: (values: FindEmployerFormValues) => void
  onBackPress?: () => void
  loading?: boolean
}

const WELCOME_TO_LYRA_HEADER_TEXT = defineMessage({
  defaultMessage: 'Welcome to Lyra Health',
  description: 'Title above organization input',
})

const LETS_GET_STARTED_HEADER_TEXT = defineMessage({
  defaultMessage: 'Let’s get started',
  description: 'Title of page',
})

const CONTINUE_BUTTON_TEXT = defineMessage({
  defaultMessage: 'Continue',
  description:
    'Button text placed under an input field that once clicked, redirects the user to the organization’s microsite page that they are searching for.',
})

export type FindEmployerFormValues = {
  FindEmployerInputField: string
}

const FindEmployerContainer = styled(Flex1View)<{ theme: ThemeType; height?: number }>(({ theme, height }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  ...(height && { height: `${height}px` }),
}))

const OrganizationInputFloatingHeader = styled(Headline)<{ theme: ThemeType }>({
  marginBottom: '26px',
  width: '100%',
})

const MobileSizedHeader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : '26px',
}))

const OrganizationInputFloatingContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  border: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.borderDefault,
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: theme.spacing['16px'],
  padding: theme.spacing['40px'],
  ...(theme.breakpoints.isMinWidthDesktopL && { width: '542px' }),
  ...(!theme.breakpoints.isMinWidthDesktopL && theme.breakpoints.isMinWidthDesktop && { width: '492px' }),
  ...(!theme.breakpoints.isMinWidthDesktop && theme.breakpoints.isMinWidthLaptop && { width: '442px' }),
  ...(!theme.breakpoints.isMinWidthLaptop && { width: '350px' }),
}))

const OrganizationInputFormContent = styled.View({
  width: '100%',
})

const FindEmployerContentContainerDesktopSized = styled.ImageBackground({
  flex: 1,
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})

const LyraBulletPointsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthDesktopL && { width: '542px', marginRight: '120px' }),
  ...(!theme.breakpoints.isMinWidthDesktopL &&
    theme.breakpoints.isMinWidthDesktop && { width: '492px', marginRight: theme.spacing['72px'] }),
  ...(!theme.breakpoints.isMinWidthDesktop && { width: '442px', marginRight: theme.spacing['56px'] }),
  ...(!theme.breakpoints.isMinWidthLaptop && { width: '358px', marginRight: theme.spacing['40px'] }),
}))

const BulletPointContainer = styled.View({
  flexDirection: 'column',
})

const FindEmployerContentContainerMobileSized = styled.View<{ theme: ThemeType }>({
  flex: 1,
  flexDirection: 'column',
})

const DawnImageContainer = styled.View<{ theme: ThemeType }>({
  flex: IS_WEB ? 0.5 : 0.4,
})

const DawnImageMobileSized = styled(Image)<{ updatedHeight?: number }>(({ updatedHeight }) => ({
  height: updatedHeight ? `${updatedHeight}px` : '100%',
  width: '100%',
}))

const MobileSizedFormContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flex: IS_WEB ? 0.5 : 0.6,
  margin: `34px ${theme.spacing['16px']} ${theme.spacing['40px']}  ${theme.spacing['16px']}`,
}))

const DescriptionTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

export const FindEmployer: FunctionComponent<FindEmployerProps> = ({ onSubmit, onBackPress = noop, loading }) => {
  const submit = useRef(noop)
  const { formatMessage } = useIntl()
  const testID = 'FindEmployerPage'
  const { colors, breakpoints } = useTheme()
  const { isMobileSized, isMinWidthLaptop, isMinWidthDesktop } = breakpoints
  // Shadows on web only work with StyleSheet.create. Using it with styled-components/native throws an error and breaks the app
  const floatingStyles = getFloatingStyles({})

  const navBarProps = {
    onBack: onBackPress,
    isTransparent: true,
    backIconColor: colors.iconLightBackground,
  }

  const handleRequired = (value: string) => {
    const error = required(value)
    return error ? formatMessage(error) : ''
  }

  const generateBulletPointComponent = (item: {
    bodyText: React.ReactNode
    headerText: React.ReactNode
    testID: string
  }) => {
    return {
      value: (
        <BulletPointContainer testID={tID(`${testID}-${item.testID}`)}>
          <BodyText text={item.headerText} size={isMinWidthLaptop ? BodyTextSize.LARGE : BodyTextSize.DEFAULT} />
          <BodyText text={item.bodyText} color={colors.textInactive} />
        </BulletPointContainer>
      ),
      bulletIcon: <CheckMarkInCircleIcon />,
    }
  }

  const lyraBulletPointsText = [
    {
      headerText: (
        <FormattedMessage
          defaultMessage='Care for you and your family'
          description='Header for bullet point highlighting that Lyra can provide care for the user and their family.'
        />
      ),
      bodyText: (
        <FormattedMessage
          defaultMessage='Personalized care for adults, kids, teens, and couples'
          description='Text highlighting the sub-populations Lyra can provide care for.'
        />
      ),
      testID: 'careForYouAndFamily-bulletPoint',
    },
    {
      headerText: (
        <FormattedMessage
          defaultMessage='Diverse providers who understand'
          description='Header for bullet point highlighting the the diversity of Lyra providers/coaches.'
        />
      ),
      bodyText: (
        <FormattedMessage
          defaultMessage='Our certified coaches and licensed therapists represent varying races, genders, faiths, sexual orientations, and backgrounds'
          description='Text highlighting the diversity of Lyra providers/coaches.'
        />
      ),
      testID: 'diverseProviders-bulletPoint',
    },
    {
      headerText: (
        <FormattedMessage
          defaultMessage='24/7 Care Navigator support'
          description='Header for bullet point highlighting 24/7 availability of Lyra Care Navigators.'
        />
      ),
      bodyText: (
        <FormattedMessage
          defaultMessage='Reach out anytime with questions about your care options'
          description='Text highlighting the 24/7 availability of Lyra Care Navigators.'
        />
      ),
      testID: 'careNavigatorSupport-bulletPoint',
    },
    {
      headerText: (
        <FormattedMessage
          defaultMessage='100% confidential'
          description='Header for bullet point highlighting the confidentiality of care that Lyra provides.'
        />
      ),
      bodyText: (
        <FormattedMessage
          defaultMessage='Care is confidential so you can get the support you deserve'
          description='Text highlighting the confidentiality of care that Lyra provides.'
        />
      ),
      testID: 'confidentialCare-bulletPoint',
    },
  ]

  const lyraBulletPoints = lyraBulletPointsText.map((item) => generateBulletPointComponent(item))

  const welcomeToLyraHeader = (
    <MobileSizedHeader
      testID={tID(tID(`${testID}-welcomeToLyraHeader`))}
      text={formatMessage(WELCOME_TO_LYRA_HEADER_TEXT)}
      size={SubheadSize.LARGE}
    />
  )

  const letsGetStartedHeader = (
    <MobileSizedHeader
      testID={tID(`${testID}-letsGetStartedHeader`)}
      text={formatMessage(LETS_GET_STARTED_HEADER_TEXT)}
      size={SubheadSize.LARGE}
    />
  )

  const findEmployerInputField = (
    <Field
      name='FindEmployerInputField'
      inputAccessoryViewID={testID}
      label={formatMessage({
        defaultMessage: 'What organization is sponsoring your access to Lyra?',
        description:
          'Input field label where users can enter the organization’s name that sponsors their access to Lyra and be redirected to the organization’s microsite page.',
      })}
      component={InputFieldRFF}
      validate={handleRequired}
      returnKeyType='done'
      baseInputStyle={{ marginBottom: isMobileSized ? 16 : 26 }}
    />
  )

  const generateFormContent = (handleSubmit: (event: GestureResponderEvent) => void) => {
    return (
      <OrganizationInputFormContent>
        {findEmployerInputField}
        <PrimaryButton
          testID={tID(`${testID}-findEmployerInputField-submit`)}
          onPress={handleSubmit}
          text={formatMessage(CONTINUE_BUTTON_TEXT)}
          fullWidth
        />
      </OrganizationInputFormContent>
    )
  }

  const MobileSizedWrapperComponent = IS_WEB ? React.Fragment : FixedButtonFormPageTemplate
  const mobileSizedWrapperProps = IS_WEB
    ? {}
    : {
        onSubmitPress: () => {
          return submit.current()
        },
        testID,
        buttonText: formatMessage(CONTINUE_BUTTON_TEXT),
        isButtonTransparent: true,
        loading,
        navBarProps,
        overlayHeader: true,
        ignoreTopInset: true,
      }

  const windowHeight = Dimensions.get('window').height
  // setting a minimum height for non-mobile sized screens so that the main portion of the background remains viewable
  const minimumHeight = 900

  return (
    <FindEmployerContainer
      testID={tID(testID)}
      height={isMobileSized || windowHeight > minimumHeight ? windowHeight : minimumHeight}
    >
      {isMobileSized ? (
        <FindEmployerContentContainerMobileSized testID={tID(`${testID}-mobileSizedContainer`)}>
          {/* @ts-expect-error TS(2322): Type Element[]; onSubmitPress?: undefined;... Remove this comment to see the full error message */}
          <MobileSizedWrapperComponent {...mobileSizedWrapperProps}>
            <DawnImageContainer>
              <DawnImageMobileSized
                testID={tID(`${testID}-dawnImage-mobileSized`)}
                source={(IS_WEB ? DawnBackgroundMobileWeb : DawnBackgroundMobile) as ImageSourcePropType}
                accessibilityIgnoresInvertColors
                updatedHeight={!IS_WEB ? Dimensions.get('window').height * 0.4 : undefined}
                contentFit='cover'
              />
            </DawnImageContainer>
            <MobileSizedFormContainer>
              {IS_WEB ? (
                <>
                  {welcomeToLyraHeader}
                  <DescriptionTextContainer>
                    <BodyText
                      testID={tID(`${testID}-lyraOffers-description`)}
                      text={
                        <FormattedMessage
                          defaultMessage='Lyra offers 100% confidential care for individuals and their family, a diverse provider network, and 24/7 Care Navigator support.'
                          description='Short description of what Lyra provides.'
                        />
                      }
                      color={colors.textInactive}
                    />
                  </DescriptionTextContainer>
                  <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => {
                      submit.current = handleSubmit
                      return generateFormContent(handleSubmit)
                    }}
                  />
                </>
              ) : (
                <>
                  {letsGetStartedHeader}
                  <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => {
                      submit.current = handleSubmit
                      return findEmployerInputField
                    }}
                  />
                </>
              )}
            </MobileSizedFormContainer>
          </MobileSizedWrapperComponent>
        </FindEmployerContentContainerMobileSized>
      ) : (
        <FindEmployerContentContainerDesktopSized
          testID={tID(`${testID}-desktopSizedContainer`)}
          source={(isMinWidthDesktop ? DawnBackgroundWiderAspectRatio : DawnBackground) as ImageSourcePropType}
          resizeMode={'cover'}
        >
          <LyraBulletPointsContainer>
            <BulletedList data={lyraBulletPoints} />
          </LyraBulletPointsContainer>
          <OrganizationInputFloatingContainer style={floatingStyles.floatingCard}>
            {isMinWidthLaptop ? (
              <OrganizationInputFloatingHeader
                testID={tID(`${testID}-welcomeToLyraHeader-floating`)}
                text={formatMessage(WELCOME_TO_LYRA_HEADER_TEXT)}
                size={HeadlineSize.SMALL}
              />
            ) : (
              welcomeToLyraHeader
            )}
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                submit.current = handleSubmit
                return generateFormContent(handleSubmit)
              }}
            />
          </OrganizationInputFloatingContainer>
        </FindEmployerContentContainerDesktopSized>
      )}
      <InputAccessory nativeID={testID} />
      <StatusBar style='dark' />
    </FindEmployerContainer>
  )
}
