import { Client } from '@twilio/conversations'
import { AxiosError } from 'axios'
import { Map } from 'immutable'
import { AnyAction, Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import {
  Appointment,
  CustomNotificationsPreferences,
  Feedback,
  GetOutcomesAction,
  LW_API_ERROR_CODE,
  Message,
  NotificationPreferences,
  UpdateNotificationPreferences,
} from '@lyrahealth-inc/shared-app-logic'
import { appointmentUtils } from '@lyrahealth-inc/ui-core'
import { SessionInfo } from '@lyrahealth-inc/ui-core-crossplatform'

import * as LyraTherapyConstants from './LyraTherapyConstants'
import LyraTherapyReducer from './LyraTherapyReducer'
import { setBannerAndErrorMessage } from '../../../common/components/banner/banner-actions'
import { noOpActionErrorHandler } from '../../../common/http/actionErrorHandler'
import { editAppointmentByUserId, patchAppointmentByUserId } from '../../../common/http/data/appointmentDashboard'
import {
  deleteCustomNotificationsData,
  getAccessTokenData,
  getAppointmentsByProviderData,
  getAppointmentsForClientData,
  getChildEpisodesData,
  getCustomNotificationsData,
  getDummyVideoAppointmentData,
  getMessageConversationsData,
  getMessageData,
  getMessagesData,
  getNotificationPreferencesData,
  getOutcomesData,
  getProvidersData,
  getTokenData,
  getVideoSessionData,
  ltNoop,
  submitCustomNotificationsData,
  submitMessageData,
  submitNotificationPreferencesData,
  updateUnreadMessageCountData,
} from '../../../common/http/data/lyraTherapy'
import { deleteAppointment } from '../../appointmentDashboard/data/appointmentDashboardActions'
import { changeRoute, PAGE_ROUTES } from '../../onboard/data/page-navigation/location-actions'

type IAppState = ReturnType<typeof LyraTherapyReducer>
export type ApplicationDispatch = ThunkDispatch<IAppState, void, AnyAction> & Dispatch

export const setDashboardFetched = (value: boolean) => ({
  type: 'SET_DASHBOARD_FETCHED',
  value,
})

export function markDashboardAsStale() {
  return (dispatch: ApplicationDispatch) => {
    dispatch(setDashboardFetched(false))
  }
}

export function getToken(ids: { apptID: string; providerID: string; patientID: string; sessionNumber: number }) {
  const request = getTokenData(ids)

  return (dispatch: ApplicationDispatch) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (tokenReturn) => {
          dispatch({
            type: LyraTherapyConstants.GET_VIDEO_CALL_TOKEN,
            data: tokenReturn.data,
          })

          resolve(tokenReturn.data)
        },
        (error) => {
          dispatch(setBannerAndErrorMessage(error, true))
          reject(error)
        },
      )
    })
  }
}

// Gets the video session corresponding to the apptId parameter.
// This is currently used as the equivalent of a no-op to keep the session alive and is not used in any other context -
// hence the lack of reducer handling, etc. from this action handler.
export const getVideoSession = ({ apptId, patientId }: { apptId: string; patientId: string }) => {
  return () => {
    return getVideoSessionData({ apptId, patientId })
  }
}

export const toggleVideoSessionStatus = (toggle: boolean) => {
  return {
    type: LyraTherapyConstants.VIDEO_SESSION_STATUS_UPDATE,
    payload: toggle,
  }
}

export const submitVideoQualityFeedback = (data: Feedback) => ({
  action: LyraTherapyConstants.SUBMIT_VIDEO_QUALITY_FEEDBACK,
  request: {
    method: 'post',
    url: '/fdbks/v1/feedback',
    data,
  },
})

// temp for LTV dev
export function getDummyVideoAppointment(id: string, data: object) {
  const request = getDummyVideoAppointmentData(id, data)
  return (dispatch: ApplicationDispatch) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (returnObj) => {
          dispatch({
            type: LyraTherapyConstants.GET_LT_APPOINTMENTS,
            data: returnObj.data,
            appointmentType: 'video',
          })
          resolve(returnObj.data)
        },
        (error) => {
          dispatch(setBannerAndErrorMessage(error, true))
          reject(error)
        },
      )
    })
  }
}

export function getLTAppointmentsByProvider(data: { patientId: string; providerId: string; type: string }) {
  const request = getAppointmentsByProviderData(data)
  return (dispatch: ApplicationDispatch) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (returnObj) => {
          dispatch({
            type: LyraTherapyConstants.GET_LT_APPOINTMENTS_BY_PROVIDER,
            data: returnObj.data,
            appointmentType: data.type,
          })
          resolve(returnObj.data)
        },
        (error) => {
          dispatch(setBannerAndErrorMessage(error, true))
          reject(error)
        },
      )
    })
  }
}

export function getLTAppointmentsForClient(data: { patientId: string; type: string }) {
  const request = getAppointmentsForClientData(data)
  return (dispatch: ApplicationDispatch) => {
    return new Promise(function (resolve, reject) {
      request.then(
        (returnObj) => {
          dispatch({
            type: LyraTherapyConstants.GET_LT_APPOINTMENTS_FOR_CLIENT,
            data: returnObj.data,
            appointmentType: data.type,
          })
          resolve(returnObj.data)
        },
        (error) => {
          dispatch(setBannerAndErrorMessage(error, true))
          reject(error)
        },
      )
    })
  }
}

export const updateVideoSessionSettings = (settingsObj: {
  videoInput: string
  audioInput: string
  audioOutput: string
}) => {
  return {
    type: LyraTherapyConstants.VIDEO_SESSION_SETTINGS_UPDATE,
    payload: settingsObj,
  }
}

export const toggleVideoSessionModal = (bool: boolean) => {
  return {
    type: LyraTherapyConstants.TOGGLE_VIDEO_SESSION_MODAL,
    payload: bool,
  }
}

const ROOT_URL = '/secure/index/'

export const setAppointment = ({ appointment }: { appointment: Appointment }) => {
  return {
    appointment,
    type: LyraTherapyConstants.SET_APPOINTMENT,
  }
}

export const setAppointmentReschedule = ({ appointment }: { appointment: Appointment }) => {
  return {
    appointment,
    type: LyraTherapyConstants.SET_APPOINTMENT_RESCHEDULE,
  }
}

export const goToSessionSelection = () => {
  return (dispatch: ApplicationDispatch) => {
    dispatch(changeRoute(PAGE_ROUTES.SESSION_SELECTION, ROOT_URL))
  }
}

export const goToSessionDetails = () => {
  return (dispatch: ApplicationDispatch) => {
    dispatch(changeRoute(PAGE_ROUTES.SESSION_DETAILS, ROOT_URL))
  }
}

export const goToSessions = () => {
  return (dispatch: ApplicationDispatch) => {
    dispatch(changeRoute(PAGE_ROUTES.SESSIONS, ROOT_URL))
  }
}

export const updateAppointment = ({ userId, appointment }: { userId: string; appointment: Appointment }) => {
  return () => {
    return editAppointmentByUserId({
      userId,
      // @ts-expect-error TS(2740): Type '{ address: any; appointmentDuration: any; ap... Remove this comment to see the full error message
      data: appointmentUtils.createAppointmentPayload({
        appointment,
        userID: userId,
        referenceAppointment: Map(),
      }),
    })
  }
}

export const patchAppointment = ({
  userId,
  appointmentId,
  partialAppointment,
}: {
  userId: string
  appointmentId: number
  partialAppointment: Partial<Appointment>
}) => {
  return () => {
    return patchAppointmentByUserId({ userId, appointmentId, partialAppointment })
  }
}

export const cancelAppointment = ({ appointmentId }: { appointmentId: string }) => {
  return (dispatch: ApplicationDispatch) => {
    return dispatch(deleteAppointment(appointmentId))
  }
}

export const clearSessionStore = () => {
  return {
    type: LyraTherapyConstants.CLEAR_SESSION_STORE,
  }
}

export const clearSelectedAppointment = () => {
  return {
    type: LyraTherapyConstants.CLEAR_SELECTED_APPOINTMENT,
  }
}

export const showSessionCancellationModal = () => {
  return {
    type: LyraTherapyConstants.SHOW_SESSION_CANCELLATION_MODAL,
  }
}

export const hideSessionCancellationModal = () => {
  return {
    type: LyraTherapyConstants.HIDE_SESSION_CANCELLATION_MODAL,
  }
}

export const getNotificationPreferences = ({ lyraId }: { lyraId: string }) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const preferences = await getNotificationPreferencesData({ lyraId })
      return dispatch({
        type: LyraTherapyConstants.GET_NOTIFICATION_PREFERENCES,
        preferences,
      })
    } catch (err) {
      return err
    }
  }
}

export const deleteCustomNotificationsPreferences = ({ lyraId }: { lyraId: string }) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      await deleteCustomNotificationsData({ lyraId })
      return dispatch({
        type: LyraTherapyConstants.DELETE_CUSTOM_NOTIFICATION_PREFERENCES,
      })
    } catch (err) {
      return err
    }
  }
}

export const getCustomNotificationsPreferences = ({ lyraId }: { lyraId: string }) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const preferences = await getCustomNotificationsData({ lyraId })
      return dispatch({
        type: LyraTherapyConstants.GET_CUSTOM_NOTIFICATION_PREFERENCES,
        preferences,
      })
    } catch (err) {
      return err
    }
  }
}

export const getMessageConversations = (providerId: string, clientId: string) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const conversation = await getMessageConversationsData(providerId, clientId)
      return dispatch({
        type: LyraTherapyConstants.GET_MESSAGE_CONVERSATION,
        data: conversation.data,
      })
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      dispatch(setBannerAndErrorMessage(error, true))
      return error
    }
  }
}

export const selectMessengerProvider = (providerId: string) => {
  return {
    type: LyraTherapyConstants.SELECT_MESSENGER_PROVIDER,
    data: providerId,
  }
}

export const deselectMessengerProvider = () => {
  return {
    type: LyraTherapyConstants.DESELECT_MESSENGER_PROVIDER,
  }
}

export const setNotificationPreferences = (preferences: NotificationPreferences) => {
  return {
    type: LyraTherapyConstants.SET_NOTIFICATION_PREFERENCES,
    preferences,
  }
}

export const setShowSessionNotificationPrompt = (value: boolean) => {
  return {
    type: LyraTherapyConstants.SET_SHOW_SESSION_NOTIFICATION_PROMPT,
    value,
  }
}

export const submitNotificationPreferences = ({
  lyraId,
  data,
}: {
  lyraId: string
  data: UpdateNotificationPreferences
}) => {
  return (dispatch: ApplicationDispatch) => {
    return submitNotificationPreferencesData({ lyraId, data }).then(
      (success) => {
        dispatch(setNotificationPreferences(success.data))
        return Promise.resolve(success)
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  }
}

export const setCustomNotificationsPreferences = ({
  lyraId,
  preferences,
}: {
  lyraId: string
  preferences: CustomNotificationsPreferences
}) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      await submitCustomNotificationsData({ lyraId, data: preferences })
      dispatch({
        type: LyraTherapyConstants.SET_CUSTOM_NOTIFICATION_PREFERENCES,
        preferences,
      })
    } catch (error: any) {
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const getMessages = (conversationId: string) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const messages = await getMessagesData(conversationId)
      dispatch({
        type: LyraTherapyConstants.GET_MESSAGES,
        data: messages.data,
        conversationId,
      })
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const fetchMessage = ({ conversationId, messageId }: { conversationId: string; messageId: string }) => {
  // @ts-expect-error TS(7030): Not all code paths return a value.
  return async (dispatch: ApplicationDispatch | (() => void)) => {
    try {
      return await getMessageData(conversationId, messageId)
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const getMessage = ({ conversationId, messageId }: { conversationId: string; messageId: string }) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const message = await getMessageData(conversationId, messageId)
      dispatch({
        type: LyraTherapyConstants.ADD_MESSAGE,
        data: message.data,
      })
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const submitMessage = (conversationId: string, data: Message) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const message = await submitMessageData(conversationId, data)
      dispatch({
        type: LyraTherapyConstants.ADD_MESSAGE,
        data: message.data,
        conversationId,
      })
      return message.data
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const updateUnreadMessageCount = (conversationId: string, data: { unread_patient_messages_count: number }) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const count = await updateUnreadMessageCountData(conversationId, data)
      dispatch({
        type: LyraTherapyConstants.UPDATE_UNREAD_MESSAGE_COUNT,
        data: count.data,
        conversationId,
      })
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const incrementUnreadMessageCount = (conversationId: string) => {
  return {
    type: LyraTherapyConstants.INCREMENT_UNREAD_MESSAGE_COUNT,
    conversationId,
  }
}

export const setTwilioClient = () => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const res = await getAccessTokenData()
      const client = new Client(res.data)
      return dispatch({
        type: LyraTherapyConstants.SET_CONVERSATIONS_CLIENT,
        data: client,
      })
    } catch (error) {
      return error
    }
  }
}

export const updateSelectedConversationId = (data: { oldConversationId: string; newConversationId: string }) => {
  return {
    type: LyraTherapyConstants.UPDATE_SELECTED_CONVERSATION_ID,
    data,
  }
}

export const getCurrentEpisodes = ({ lyraId }: { lyraId: string }) => ({
  action: LyraTherapyConstants.GET_CURRENT_EPISODES,
  request: {
    method: 'get',
    url: `/lt/v1/patient/${lyraId}/episodes/current`,
  },
  errorHandler: noOpActionErrorHandler,
})

export const getEpisodes = ({ lyraId }: { lyraId: string }) => ({
  action: LyraTherapyConstants.SET_EPISODES,
  request: {
    method: 'get',
    url: `/lt/v1/patient/${lyraId}/episodes`,
  },
  errorHandler: noOpActionErrorHandler,
})

export const getPrograms = () => ({
  action: LyraTherapyConstants.SET_PROGRAMS,
  request: {
    method: 'get',
    url: '/lt/v1/programs',
  },
})

export const getOutcomes = (data: GetOutcomesAction) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const outcomes = await getOutcomesData(data)
      dispatch({
        type: LyraTherapyConstants.SET_OUTCOMES,
        data: outcomes.data,
      })
      return outcomes.data
    } catch (err) {
      const error = err as AxiosError<{ message?: string; errorCode?: LW_API_ERROR_CODE }>
      dispatch(setBannerAndErrorMessage(error, true))
      return error
    }
  }
}

export const getProviders = ({ clientId, includeChild }: { clientId: string; includeChild?: boolean }) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const providers = await getProvidersData(clientId, includeChild)
      dispatch({
        type: LyraTherapyConstants.GET_PROVIDERS,
        data: providers.data,
      })
    } catch (error) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      dispatch(setBannerAndErrorMessage(error, true))
    }
  }
}

export const getChildEpisodes = ({ userLyraId, childLyraId }: { userLyraId: string; childLyraId: string }) => {
  return async (dispatch: ApplicationDispatch) => {
    try {
      const episodes = await getChildEpisodesData(userLyraId, childLyraId)
      dispatch({
        type: LyraTherapyConstants.GET_CHILD_EPISODES,
        data: {
          childLyraId,
          episodes: episodes.data,
        },
      })
    } catch (error) {
      console.error('Failed to get episodes for child', error)
    }
  }
}

export const pingLT = () => () => ltNoop()

export const saveMessageDraft = (data: { content: string; conversationId: string }) => ({
  type: LyraTherapyConstants.SAVE_MESSAGE_DRAFT,
  data,
})

export const toggleLiveMsgSession = (bool: boolean) => ({
  type: LyraTherapyConstants.TOGGLE_LIVE_MSG_SESSION,
  payload: bool,
})

export const toggleShownLiveMsgModal = (bool: boolean) => ({
  type: LyraTherapyConstants.TOGGLE_SHOWN_LIVE_MSG_MODAL,
  payload: bool,
})

export const setActiveLiveMsgSession = (data: SessionInfo) => {
  return {
    type: LyraTherapyConstants.SET_ACTIVE_LIVE_MSG_SESSION,
    payload: data,
  }
}

export const toggleShowLiveMsgModal = (bool: boolean) => ({
  type: LyraTherapyConstants.TOGGLE_SHOW_LIVE_MSG_MODAL,
  payload: bool,
})

export const setLiveMsgProviderIdToOpen = (data: string | null) => ({
  type: LyraTherapyConstants.SET_LIVE_MSG_PROVIDER_ID_TO_OPEN,
  payload: data,
})

export const setIntakeFormOpen = (bool: boolean) => ({
  type: LyraTherapyConstants.SET_INTAKE_FORM_OPEN,
  payload: bool,
})

export const setFirstAppointmentBooked = (value: boolean) => ({
  type: LyraTherapyConstants.SET_FIRST_APPOINTMENT_BOOKED,
  payload: value,
})

export const setViewedNotificationModal = (value: boolean) => ({
  type: LyraTherapyConstants.SET_VIEWED_NOTIFICATION_MODAL,
  payload: value,
})
