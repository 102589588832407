import { PayloadAction } from '@reduxjs/toolkit'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { UserObject } from '@lyrahealth-inc/shared-app-logic'

import {
  POST_GUARDIAN_INDICATED,
  SET_USER_CHILDREN,
  SET_USER_DEDICATED_CARE_NAVIGATOR_INFO,
  SET_USER_DIRECT_DEDICATED_CARE_NAVIGATOR_LINK,
  SET_USER_INFO,
  SET_USER_PHONE_COUNTRY_CODE,
  SET_USER_REMAINING_TIME,
  TOGGLE_OFF_MFA,
  TOGGLE_ON_MFA,
} from './userActions'
import { POST_COUNTRY_INTERNATIONAL_CONSENT } from '../../common/http/data/country'
import {
  UPDATE_USER_COUNTRY,
  UPDATE_USER_DISPLAY_LANGUAGE,
  UPDATE_USER_TIMEZONE,
} from '../../features/editProfile/data/editProfileActions'
import { SAVE_MODALITY_PREFERENCE } from '../../features/onboard/modalityQuestionnaire/data/modalityQuestionnaireActions'

const initialState = fromJS({})

export default createReducer(initialState, {
  [SET_USER_INFO]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.mergeDeep(fromJS(action.data.user))
    return $$state
  },
  [UPDATE_USER_COUNTRY]: ($$state: $TSFixMe, action: PayloadAction<UserObject>) => {
    $$state = $$state.set('isInternational', fromJS(action.payload.user.isInternational))
    return $$state.set('customerCountries', fromJS(action.payload.user.customerCountries))
  },
  [TOGGLE_OFF_MFA]: ($$state: $TSFixMe, _action: $TSFixMe) => {
    $$state = $$state.set('mfa', false)
    return $$state
  },
  [TOGGLE_ON_MFA]: ($$state: $TSFixMe, _action: $TSFixMe) => {
    $$state = $$state.set('mfa', true)
    return $$state
  },
  [SAVE_MODALITY_PREFERENCE]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('preferredAppointmentTypes', fromJS(action.modalityPreferences))
  },
  [POST_COUNTRY_INTERNATIONAL_CONSENT]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('internationalConsent', fromJS(action.data.internationalConsent))
  },
  [UPDATE_USER_TIMEZONE]: ($$state: $TSFixMe, action: PayloadAction<UserObject>) => {
    return $$state.set('timeZone', fromJS(action.payload.user.timeZone))
  },
  [SET_USER_PHONE_COUNTRY_CODE]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('phoneCountryCode', fromJS(action.data))
  },
  [SET_USER_DIRECT_DEDICATED_CARE_NAVIGATOR_LINK]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('directDedicatedCareNavigatorLink', fromJS(action.data))
  },
  [SET_USER_CHILDREN]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('children', fromJS(action.data))
  },
  [SET_USER_REMAINING_TIME]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('sessionRemainingTime', fromJS(action.data))
  },
  [UPDATE_USER_DISPLAY_LANGUAGE]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('displayLanguage', action.payload.displayLanguage)
  },
  [POST_GUARDIAN_INDICATED]: ($$state: $TSFixMe) => {
    return $$state.set('hasIndicatedIsGuardian', true)
  },
  [SET_USER_DEDICATED_CARE_NAVIGATOR_INFO]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('dedicatedCareNavigatorInfo', fromJS(action.data))
  },
})
