import { SearchForCareHomebaseHeaderExperimentJoinKeys } from './searchForCareExperimentTypes'

export enum ExperimentUUIDs {
  LatestMatchesUUID = '65658a7b-e7c2-4fbf-923f-5640ffe20954',
  SearchForCareHomebaseHeaderUUID = '7491d7ac-742d-4687-89ae-3f338fd27eba',
}

export const LatestMatchesArmMapping = {
  CAN_SEE: 'can see past provider result',
  CANNOT_SEE: 'cannot see past provider result',
}

interface LatestMatchesExperimentJoinKeys {
  client_lyra_id: string
}

interface Assignment {
  arm: string
  joinKeys: LatestMatchesExperimentJoinKeys | SearchForCareHomebaseHeaderExperimentJoinKeys
}

interface Experiment {
  assignment: Assignment[]
}

interface Experiments {
  [key: string]: Experiment
}

export interface ExperimentPayload {
  experimentUUID: string
  deviceUUID: string
  experiments: Experiments
}
