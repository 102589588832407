export enum SEARCH_FOR_CARE_EXPERIMENT_VARIANT_TYPE {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export type SEARCH_FOR_CARE_CALL_TO_ACTION_VARIANT_TYPE =
  | SEARCH_FOR_CARE_EXPERIMENT_VARIANT_TYPE.C
  | SEARCH_FOR_CARE_EXPERIMENT_VARIANT_TYPE.D

export type SEARCH_FOR_CARE_ILLUSTRATION_VARIANT_TYPE =
  | SEARCH_FOR_CARE_EXPERIMENT_VARIANT_TYPE.A
  | SEARCH_FOR_CARE_EXPERIMENT_VARIANT_TYPE.B

export type SearchForCareHeaderExperimentVariant = {
  callToActionVariant: SEARCH_FOR_CARE_CALL_TO_ACTION_VARIANT_TYPE
  illustrationVariant: SEARCH_FOR_CARE_ILLUSTRATION_VARIANT_TYPE
}

export type SearchForCareHeaderExperiment = SearchForCareHeaderExperimentVariant | {}
