import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  ConfirmEligibilityRequest,
  CustomerName,
  EAPEligibilityRequest,
  EAPEligibilityResponse,
  ELIGIBILITY_TYPES,
  User,
} from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../../../../common/http/axiosInstance'
import { submitEditProfileData } from '../../../../common/http/data/editProfile'
import { generateLyraCode, updatePatientEligibility } from '../../../../common/http/data/onboard'
import { setUserInfo } from '../../../../data/user/userActions'

export const DISMISS_ELIGIBILITY_BANNER = 'DISMISS_ELIGIBILITY_BANNER'
export const ENABLE_ELIGIBILITY_BANNER_FROM_CONFIRMATION_FORM = 'ENABLE_ELIGIBILITY_BANNER_FROM_ELIGIBILITY_FORM'

export const dismissEligibilityBanner = () => ({
  type: DISMISS_ELIGIBILITY_BANNER,
})

export const enableEligibilityBannerFromConfirmationForm = () => ({
  type: ENABLE_ELIGIBILITY_BANNER_FROM_CONFIRMATION_FORM,
})

export const getEAPEligibility = createAsyncThunk<EAPEligibilityResponse, EAPEligibilityRequest>(
  'GET_EAP_ELIGIBILITY',
  async (data: EAPEligibilityRequest) => {
    try {
      const response = await axiosInstance.post('/unsecured/v1/eligibility/user', data)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
)
export const GET_EAP_ELIGIBILITY = getEAPEligibility.fulfilled.type
export const GET_EAP_ELIGIBILITY_PENDING = getEAPEligibility.pending.type

export const confirmEAPEligibility = createAsyncThunk<User, ConfirmEligibilityRequest>(
  'CONFIRM_EAP_ELIGIBILITY',
  async (request: ConfirmEligibilityRequest, { dispatch }) => {
    const {
      lyraId,
      firstname,
      lastname,
      dob,
      customerName,
      customerCode,
      customerCountryId,
      eligibilityType,
      employeeFirstname,
      employeeLastname,
      employeeDob,
      checkAdultDependentEligibilityUsingDependentInfo,
    } = request

    const updateUserRequest: Partial<User> = {
      lyraId,
      firstname,
      lastname,
      dob,
    }
    if (customerCode) {
      updateUserRequest.extendedProperties = {
        employee_id: customerCode,
      }
    }
    const updateUserResponse = await submitEditProfileData(lyraId, updateUserRequest)
    let updatedUser: User = updateUserResponse.data

    const shouldUseEmployeeInfo =
      eligibilityType === ELIGIBILITY_TYPES.DEPENDENT && !checkAdultDependentEligibilityUsingDependentInfo
    await updatePatientEligibility(lyraId, {
      customer: customerName,
      customerCode: customerCode,
      firstname: (shouldUseEmployeeInfo ? employeeFirstname : firstname) || '',
      lastname: (shouldUseEmployeeInfo ? employeeLastname : lastname) || '',
      dob: (shouldUseEmployeeInfo ? employeeDob : dob) || '',
      eligibilityType: eligibilityType || 'unknown',
    })

    if (customerName === CustomerName.WALMART) {
      if (customerCountryId == null) {
        throw new Error('confirmEAPEligibility: customerCountryId is missing')
      }
      // generate lyra code for WMT users who confirmed their eligibility post registration
      updatedUser = (await generateLyraCode(lyraId, customerCountryId)).data
    }

    dispatch(setUserInfo({ user: updatedUser }))
    return updatedUser
  },
)

export const CONFIRM_EAP_ELIGIBILITY = confirmEAPEligibility.fulfilled.type
export const CONFIRM_EAP_ELIGIBILITY_REJECTED = confirmEAPEligibility.rejected.type
