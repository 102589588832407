import React from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'

import moment from 'moment-timezone'

import { timeZones } from '../messages'

export const getDisplayTimeZone = (
  formatMessage: (message: MessageDescriptor) => {},
  timeZone: string,
): string | null => {
  const message = timeZones[timeZone]
  if (!message) {
    return null
  }

  const displayTimeZone = formatMessage(message)
  const offset = `(GMT${moment.tz(timeZone).format('Z')})`
  return `${offset} ${displayTimeZone}`
}

export const useDisplayTimeZone = (timeZone: string | null): string | null => {
  const intl = useIntl()
  if (!timeZone) {
    return null
  }
  return getDisplayTimeZone(intl.formatMessage, timeZone)
}

// TODO: Remove this during profile location integration
export const useDisplayTimeZonesDeprecated = (): { [timeZone: string]: string } => {
  const intl = useIntl()
  const displayTimeZones = React.useMemo(() => {
    return Object.keys(timeZones).reduce((result, key) => {
      result[key] = getDisplayTimeZone(intl.formatMessage, key)
      return result
    }, {})
  }, [intl])

  return displayTimeZones
}

export type DisplayTimezonesTypeAhead = {
  id: string
  displayTimeZone: string | null
}

export const useDisplayTimeZones = (): DisplayTimezonesTypeAhead[] => {
  const intl = useIntl()
  const displayTimeZones = React.useMemo(() => {
    return Object.keys(timeZones).map((timezone) => {
      return {
        id: timezone,
        displayTimeZone: getDisplayTimeZone(intl.formatMessage, timezone),
      }
    })
  }, [intl])

  return displayTimeZones
}
