import React, { FunctionComponent } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../../atoms'
import { LegalFooter } from '../../../organisms'
import { BodyTextSize } from '../../../styles'
import { ThemeType } from '../../../utils'
import { tID } from '../../../utils/utils'

export interface LegalAndCopyrightFooterProps {
  onFooterLinkPressed: (url: string) => void
  isInternational?: boolean
  legalNotice?: React.ReactNode
}

const LegalFooterContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  background: theme.colors.backgroundSecondary,
  padding: theme.breakpoints.isMobileSized ? '40px 24px' : '80px 124px',
}))

const CopyrightText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  marginBottom: theme.spacing['16px'],
}))

export const LegalAndCopyrightFooter: FunctionComponent<LegalAndCopyrightFooterProps> = ({
  onFooterLinkPressed,
  isInternational,
  legalNotice,
}) => {
  const { colors } = useTheme()

  return (
    <LegalFooterContainer testID={tID('LegalAndCopyrightFooter')}>
      <CopyrightText
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        text={`© ${new Date().getFullYear()} Lyra Health, INC • 270 East Ln, Burlingame, CA 94010`}
        size={BodyTextSize.SMALL}
      />
      <LegalFooter
        footerLinkPressed={onFooterLinkPressed}
        legalNotice={legalNotice}
        backgroundColor={colors.backgroundSecondary}
        isInternational={isInternational}
        removeBottomSpacing
      />
    </LegalFooterContainer>
  )
}
