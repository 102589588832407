import { GetCustomerCopyFunc, TREATMENT_OPTIONS, TREATMENT_RECOMMENDATION_TIER } from '@lyrahealth-inc/shared-app-logic'
import React from 'react'
import { BodyText } from '../atoms'
import { defineMessage } from 'react-intl'

export const bms: GetCustomerCopyFunc = () => {
  const healthAccessConfiguration = {
    title: 'Employee Assistance Program (EAP)',
    description: (
      <>
        <BodyText
          text={
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            'All employees and members of their household have access to up to 6 sessions, per issue, '
          }
        />

        <BodyText
          bold
          text={
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            'at no cost'
          }
        />
        <BodyText
          text={
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            ' with a professional counselor who can help you, no matter where you are or what kind of situation you are facing. The Employee Assistance Program (EAP) is confidential and provides support for emotional, physical, work life, and financial wellbeing, delivered by Lifeworks. Get help with practical solutions on issues that matter to you - from helping aging parents to raising safe and responsible kids, from dealing with stress to relationships and staying healthy.\n\nAccess 24/7/365 by calling 877-858-2266.'
          }
        />
      </>
    ),
    buttonText: 'Explore the Website',
    url: 'https://login.lifeworks.com/',
  }

  return {
    generateEligibilityQuestionTitle: () =>
      defineMessage({
        defaultMessage: 'Are you an employee/retiree of Bristol Myers Squibb?',
        description: 'Asking the user if they are an employee or retiree of the company Bristol Myers Squibb',
      }),
    treatmentOptions: [
      {
        option: TREATMENT_OPTIONS.ONSITE_THERAPY,
        config: { customCopy: healthAccessConfiguration },
        optionRanking: { [TREATMENT_RECOMMENDATION_TIER.TIER_2]: 2, [TREATMENT_RECOMMENDATION_TIER.TIER_3]: 1 },
      },
    ],
  }
}
