import { defineMessage } from 'react-intl'

import { OPERATORS, PreferenceMetadataConfig, RENDER_CONDITION_TARGETS } from './types'
import {
  MEETING_STYLE_MODAL_OPTIONS,
  MEMBER_PREFERENCE_IDS,
  S4C_PROGRAM_OPTION_FLOW,
} from '../../memberPreferences/constants'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'

const meetingStylesPreferenceTherapy: PreferenceMetadataConfig = {
  name: 'meetingSetting',
  title: MEMBER_PREFERENCE_LABELS.meetingSetting,
  icon: 'MemberPreferencesMeetingSettingIllustrationIcon',
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.highlightProvidersWhoOfferInPersonSessions,
      id: MEMBER_PREFERENCE_IDS.HIGHLIGHT_PROVIDERS_WHO_OFFER_IN_PERSON_SESSIONS,
    },
  ],
  renderConditions: [
    {
      target: RENDER_CONDITION_TARGETS.MODALITY_PREFERENCE_OPTIONS,
      operator: OPERATORS.EQ,
      value: MEETING_STYLE_MODAL_OPTIONS.IN_PERSON,
    },
  ],
  promptText: defineMessage({
    defaultMessage:
      'Meeting by video gives you access to the highest number of eligible providers, as well as a wider array of specialists.',
    description:
      'Description text informing users that there will always be matches who offer video sessions in order to display a wide array of specialists',
  }),
  footerText: {
    message: defineMessage({
      defaultMessage: 'Ok! We will also show you providers who offer in-person',
      description: 'Description text confirming checkbox selection',
    }),
    renderConditions: [
      {
        target: RENDER_CONDITION_TARGETS.SELECTED_PREFERENCES,
        path: 'meetingSetting',
        operator: OPERATORS.EQ,
        value: [MEMBER_PREFERENCE_IDS.HIGHLIGHT_PROVIDERS_WHO_OFFER_IN_PERSON_SESSIONS],
      },
    ],
    color: 'textSecondary',
  },
  visibleFor: [
    S4C_PROGRAM_OPTION_FLOW.THERAPY,
    S4C_PROGRAM_OPTION_FLOW.SELF_INITIATED_TEENS,
    S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD,
    S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN,
  ],
}

export default meetingStylesPreferenceTherapy
