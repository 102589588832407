import { careConsentFormError } from '../common/constants/messages'

type ContentAttributes = {
  [key: string]: {
    bypassReview?: boolean
    customErrorMessage?: { defaultMessage: string; description: string }
    hideInClientActivities?: boolean
    hideInstructionsIfHasPrereq?: boolean
    initialPage?: number
    prerequisite?: string
    postrequisite?: string
    showAllPages?: boolean
  }
}

export const customContentAttributes: ContentAttributes = {
  audCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    postrequisite: 'audClientIntake',
    showAllPages: true,
    hideInClientActivities: true,
  },
  audClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPage: 1,
    prerequisite: 'audCareConsent',
    // postrequisite: 'audInitialAssessmentMetadata',
  },
  bccCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    postrequisite: 'bccClientIntake',
    showAllPages: true,
    hideInClientActivities: true,
  },
  bccClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPage: 1,
    prerequisite: 'bccCareConsent',
    // postrequisite: 'bccBaselineMetadata',
  },
  bcmCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    postrequisite: 'bcmClientIntake',
    showAllPages: true,
    hideInClientActivities: true,
  },
  bcmClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPage: 1,
    prerequisite: 'bcmCareConsent',
    // postrequisite: 'bcmBaselineMetadata',
  },
  bctCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    postrequisite: 'bctClientIntake',
    showAllPages: true,
    hideInClientActivities: true,
  },
  bctClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPage: 1,
    prerequisite: 'bctCareConsent',
    // postrequisite: 'baseInitialAssessmentMetadata',
  },
  cleCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    postrequisite: 'cleClientIntake',
    showAllPages: true,
    hideInClientActivities: true,
  },
  cleClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPage: 1,
    prerequisite: 'cleCareConsent',
    // postrequisite: 'cleInitialAssessment',
  },
  sscCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    postrequisite: 'sscClientIntake',
    showAllPages: true,
    hideInClientActivities: true,
  },
  sscClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPage: 1,
    prerequisite: 'sscCareConsent',
    // postrequisite: 'sscBaselineMetadata',
  },
  lcttCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    postrequisite: 'lcttClientIntake',
    showAllPages: true,
    hideInClientActivities: true,
  },
  lcttClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPage: 1,
    prerequisite: 'lcttCareConsent',
    // postrequisite: 'lcttInitialAssessment',
  },
}
