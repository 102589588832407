import React from 'react'
import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'

const defaultLegalCopy = defineMessage({
  defaultMessage: `Lyra Health, Inc. works in partnership with Lyra Clinical Associates P.C. and other contracted partners to deliver clinical services in the US and works with ICAS and its partners to deliver services internationally.`,
  description: 'Text for describing the partners Lyra works with, displayed as part of the coverage notice',
})

interface CoverageNoticeProps {
  signedOut?: boolean
  legalCopy?: MessageDescriptor
  excludedServices?: MessageDescriptor
  sessionCopy?: MessageDescriptor
  sessionLimit?: string
  considerMeds?: string | undefined
}

export const CoverageNotice: React.FunctionComponent<CoverageNoticeProps> = ({
  signedOut,
  legalCopy,
  excludedServices,
  sessionCopy,
  sessionLimit,
  considerMeds,
}) => {
  if (signedOut) {
    return <FormattedMessage {...defaultLegalCopy} />
  }

  if (!legalCopy) {
    legalCopy = defaultLegalCopy
  }

  const sessionMessage = sessionCopy ? (
    <FormattedMessage {...sessionCopy} values={{ sessionLimit }} />
  ) : sessionLimit && sessionLimit !== '0' ? (
    <FormattedMessage
      defaultMessage='Your Lyra benefit covers the cost of as many in-person or live video
        sessions as are clinically indicated by your Lyra provider, up to {sessionLimit} sessions, as
        long as you are eligible for benefits from your employer.'
      description='Text for describing the session limit, displayed as part of the coverage notice'
      values={{ sessionLimit }}
    />
  ) : null

  const space = <span> </span>

  return (
    <>
      {<FormattedMessage {...legalCopy} />}
      {sessionMessage && (
        <>
          {space}
          {sessionMessage}
        </>
      )}
      {space}
      {excludedServices ? (
        <FormattedMessage {...excludedServices} values={{ sessionLimit }} />
      ) : (
        <FormattedMessage
          defaultMessage='Lyra does not cover {excludePsychiatry, select, yes {} other {psychiatry, }}inpatient or residential treatment, hospitalization (including partial),
            intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication,
            autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care.'
          description='Text for describing the services that Lyra does not cover, displayed as part of the coverage notice'
          values={{ excludePsychiatry: considerMeds === 'true' ? 'yes' : 'other' }}
        />
      )}
    </>
  )
}
