import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { CostMessagingCoverageType, CostMessagingModalContent } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Link } from '../../atoms'
import { BodyTextSize } from '../../styles'
import { tID } from '../../utils'
import { CoverageMessagingModal } from '../coverageMessagingModal/CoverageMessagingModal'

export interface CoverageMessagingLinkProps {
  coverageModalContents: CostMessagingModalContent[]
  costMessagingCoverageType?: CostMessagingCoverageType
  isCostMessagingEnabled: boolean
  trackCoverageModalPress: (isOpen: boolean) => void
}

export const CoverageMessagingLink: FunctionComponent<CoverageMessagingLinkProps> = ({
  coverageModalContents = [],
  costMessagingCoverageType = undefined,
  isCostMessagingEnabled,
  trackCoverageModalPress,
}) => {
  const { colors } = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showCoverageModal =
    isCostMessagingEnabled && costMessagingCoverageType && coverageModalContents && coverageModalContents.length > 0

  if (!showCoverageModal) {
    return <></>
  }

  return (
    <>
      <BodyText
        text={
          <FormattedMessage
            defaultMessage='<link>Understand your coverage</link>'
            description='Understand your coverage link'
            values={{
              link: (chunks: string) => (
                <Link
                  text={chunks}
                  size={BodyTextSize.SMALL}
                  onPress={() => {
                    trackCoverageModalPress(true)
                    setIsModalOpen(true)
                  }}
                  underline
                  testID={tID('CoverageMessagingLink')}
                />
              ),
            }}
          />
        }
        size={BodyTextSize.SMALL}
        color={colors.textSecondary}
        textAlign={'center'}
      />
      <CoverageMessagingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={(isOpen) => setIsModalOpen(isOpen)}
        coverageModalContents={coverageModalContents}
        costMessagingCoverageType={costMessagingCoverageType}
        trackCoverageModalPress={trackCoverageModalPress}
      />
    </>
  )
}
