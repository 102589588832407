import { useMemo } from 'react'

import { useAddCustomerTreatmentOptions } from './useAddCustomerTreatmentOptions'
import {
  CustomerCopy,
  CustomerTreatmentOption,
  CustomerTreatmentsOptionLocation,
  TreatmentRecommendations,
} from '../../../models'
import {
  tierOnePrimaryTreatmentOptionsToDisplay,
  tierOneSecondaryTreatmentOptionsToDisplay,
  tierThreePrimaryTreatmentOptionsToDisplay,
  tierThreeSecondaryTreatmentOptionsToDisplay,
  tierTwoPrimaryTreatmentOptionsToDisplay,
  tierTwoSecondaryTreatmentOptionsToDisplay,
  TREATMENT_OPTIONS,
  TREATMENT_OPTIONS_TYPE,
} from '../../common/constants/treatmentOptions'
import { TREATMENT_RECOMMENDATION_TIER } from '../../common/constants/treatmentRecommendationsConstants'

export type PageSubtitlesKey = 'COACHING' | 'THERAPY' | 'THERAPY_MEDS'

export type CustomTreatmentCardConfig = {
  [key in TREATMENT_OPTIONS_TYPE]?: CustomerTreatmentOption['config']
}

/**
 * Gets treatment options for the treatment recommendations page for s4c
 * - Filters down treatment recommendations by tier, if user is international
 * - Adds custom treatment options per customer
 * TODO: SUPPORT OUS CONFIGS
 * @param treatmentRecs Treatment recs returned from triage flow
 * @param customerCopy Used to add in custom treatment options based on customer
 * @param searcherAge Use for child searches
 */
export const useGetTreatmentOptionsV2 = (
  treatmentRecs: TreatmentRecommendations | null,
  searcherAge: number,
  isAssertiveTriage: boolean,
  customerCopy?: CustomerCopy,
) => {
  const addCustomerTreatmentOptions = useAddCustomerTreatmentOptions()

  const result = useMemo(() => {
    const configForSpecificTreatmentOption: CustomTreatmentCardConfig = {}
    let pageSubtitleType: PageSubtitlesKey = 'COACHING'

    if (treatmentRecs === null) {
      return {
        primaryTreatmentOption: undefined,
        secondaryTreatmentOptions: [],
        pageSubtitleType,
        configForSpecificTreatmentOption,
      }
    }

    const shownTierPage = treatmentRecs.shownTierPage
    let primaryTreatmentRecs: TREATMENT_OPTIONS_TYPE[] = []
    let secondaryTreatmentRecs: TREATMENT_OPTIONS_TYPE[] = []

    switch (shownTierPage) {
      case TREATMENT_RECOMMENDATION_TIER.TIER_1:
        primaryTreatmentRecs = treatmentRecs.primaryTreatmentOptions.filter((option) =>
          tierOnePrimaryTreatmentOptionsToDisplay.includes(option),
        )
        secondaryTreatmentRecs = treatmentRecs.secondaryTreatmentOptions.filter((option) =>
          tierOneSecondaryTreatmentOptionsToDisplay.includes(option),
        )
        if (isAssertiveTriage) {
          secondaryTreatmentRecs = secondaryTreatmentRecs.filter(
            (option) => option !== TREATMENT_OPTIONS.INDIVIDUAL_THERAPY,
          )
        }
        break
      case TREATMENT_RECOMMENDATION_TIER.TIER_2:
        primaryTreatmentRecs = treatmentRecs.primaryTreatmentOptions.filter((option) =>
          tierTwoPrimaryTreatmentOptionsToDisplay.includes(option),
        )
        secondaryTreatmentRecs = treatmentRecs.secondaryTreatmentOptions.filter((option) =>
          tierTwoSecondaryTreatmentOptionsToDisplay.includes(option),
        )
        pageSubtitleType = 'THERAPY'
        break
      case TREATMENT_RECOMMENDATION_TIER.TIER_3:
        primaryTreatmentRecs = treatmentRecs.primaryTreatmentOptions.filter((option) =>
          tierThreePrimaryTreatmentOptionsToDisplay.includes(option),
        )
        secondaryTreatmentRecs = treatmentRecs.secondaryTreatmentOptions.filter((option) =>
          tierThreeSecondaryTreatmentOptionsToDisplay.includes(option),
        )
        pageSubtitleType = primaryTreatmentRecs.length > 1 ? 'THERAPY_MEDS' : 'THERAPY'
        break
    }

    // TODO: Do not show customer specific treatment options for international users
    // TODO: Change this to not mutate the result
    // customer treatment options are only shown in secondary cards
    addCustomerTreatmentOptions(
      searcherAge,
      shownTierPage as CustomerTreatmentsOptionLocation,
      secondaryTreatmentRecs,
      configForSpecificTreatmentOption,
      customerCopy,
    )

    return {
      primaryTreatmentOption: primaryTreatmentRecs[0],
      alternateTreatmentOption: primaryTreatmentRecs[1],
      secondaryTreatmentOptions: secondaryTreatmentRecs,
      pageSubtitleType,
      // TODO: validate logic
      configForSpecificTreatmentOption,
    }
  }, [treatmentRecs, addCustomerTreatmentOptions, searcherAge, customerCopy, isAssertiveTriage])

  return result
}
