import { useCallback, useMemo } from 'react'

import { isEmpty } from 'lodash-es'

import { useFilterBenefitsForExplorer } from './useFilterBenefitsForExplorer'
import { Benefit, BenefitGroup } from '../../../models/benefit/Benefit'
import {
  COMMON_MIXPANEL_PROPERTIES,
  INTEGRATED_BENEFITS_EVENTS,
  INTEGRATED_BENEFITS_MIXPANEL_PROPERTIES,
  PATHWAYS_EVENTS,
} from '../../common/constants/mixpanelConstants'

type Params = {
  registeredCountry?: string
  matchFor: string
  userLyraId: string
  customerLyraId?: number
  recommendedBenefitGroupsForUser: BenefitGroup[]
  allBenefitGroupsForUser: BenefitGroup[]
  maxBenefitsToShowInExplorer: number
}

export type CallTimeParams = {
  event: INTEGRATED_BENEFITS_EVENTS | PATHWAYS_EVENTS
  benefitClicked?: string
  entryPoint?: string
  categoryOfClickedBenefit?: string
}

export type OnPressBenefitParams = {
  benefitClicked?: string
  categoryOfClickedBenefit?: string
}

export type OnPressBenefit = (params: OnPressBenefitParams) => void

type MixpanelBenefit = {
  vendor: string
  category: string
  isRecommended?: boolean
}

const makeMixpanelBenefitsArray = (
  benefitsCategoryMap: Map<string, Benefit[]>,
  options: { isRecommended?: boolean } = {},
): MixpanelBenefit[] =>
  Object.entries(Object.fromEntries(benefitsCategoryMap)).flatMap(([category, benefits]) => {
    const benefitsForMixpanel = benefits.map((b) => ({ vendor: b.displayName, category, ...options }))
    return benefitsForMixpanel
  })

export const useGetBenefitsEventProps = ({
  registeredCountry,
  matchFor,
  userLyraId,
  customerLyraId,
  recommendedBenefitGroupsForUser = [],
  allBenefitGroupsForUser = [],
  maxBenefitsToShowInExplorer = 3,
}: Params) => {
  const otherBenefitsForUser = useMemo(() => {
    const recommendedBenefitGroupKeys = new Set((recommendedBenefitGroupsForUser ?? []).map((bg) => bg.benefitCategory))
    return (allBenefitGroupsForUser ?? []).filter((bg) => !recommendedBenefitGroupKeys.has(bg.benefitCategory))
  }, [recommendedBenefitGroupsForUser, allBenefitGroupsForUser])

  const displayedRecommendedBenefitsInExplorer = makeMixpanelBenefitsArray(
    useFilterBenefitsForExplorer({
      benefitGroups: recommendedBenefitGroupsForUser,
      maxBenefitsToShowInExplorer,
    }),
    {
      isRecommended: true,
    },
  )

  const displayedOtherBenefitsInExplorer = makeMixpanelBenefitsArray(
    useFilterBenefitsForExplorer({
      benefitGroups: otherBenefitsForUser,
      maxBenefitsToShowInExplorer,
    }),
    {
      isRecommended: false,
    },
  )

  const recommendedBenefitsGroupsMap = new Map<string, Benefit[]>(
    recommendedBenefitGroupsForUser.map(({ benefitCategory, benefits }) => [benefitCategory, benefits]),
  )
  const allRecommendedBenefits = makeMixpanelBenefitsArray(recommendedBenefitsGroupsMap, {
    isRecommended: true,
  })

  const otherBenefitsGroupsMap = new Map<string, Benefit[]>(
    otherBenefitsForUser.map(({ benefitCategory, benefits }) => [benefitCategory, benefits]),
  )
  const allOtherBenefits = makeMixpanelBenefitsArray(otherBenefitsGroupsMap, {
    isRecommended: false,
  })

  return useCallback(
    ({ event, entryPoint, benefitClicked, categoryOfClickedBenefit }: CallTimeParams) => {
      const commonProps = {
        [COMMON_MIXPANEL_PROPERTIES.LYRA_ID]: userLyraId,
        [COMMON_MIXPANEL_PROPERTIES.CUSTOMER_ID]: customerLyraId,
        [COMMON_MIXPANEL_PROPERTIES.REGISTERED_COUNTRY]: registeredCountry,
        [INTEGRATED_BENEFITS_MIXPANEL_PROPERTIES.MATCH_FOR]: matchFor,
        [INTEGRATED_BENEFITS_MIXPANEL_PROPERTIES.BENEFITS]: [
          ...displayedRecommendedBenefitsInExplorer,
          ...displayedOtherBenefitsInExplorer,
        ],
        [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: entryPoint,
      }

      switch (event) {
        case INTEGRATED_BENEFITS_EVENTS.VIEW_INTEGRATED_BENEFITS_PAGE:
          return {
            ...commonProps,
            [INTEGRATED_BENEFITS_MIXPANEL_PROPERTIES.BENEFITS]: [...allRecommendedBenefits, ...allOtherBenefits],
          }
        case INTEGRATED_BENEFITS_EVENTS.CLICK_INTEGRATED_BENEFIT:
          const clickedBenefit = [...allRecommendedBenefits, ...allOtherBenefits].filter(
            (benefit) => benefit.vendor === benefitClicked && benefit.category === categoryOfClickedBenefit,
          )
          return {
            ...commonProps,
            [INTEGRATED_BENEFITS_MIXPANEL_PROPERTIES.BENEFITS]: !isEmpty(clickedBenefit)
              ? clickedBenefit
              : [{ vendor: benefitClicked, isRecommended: false }],
          }
        case INTEGRATED_BENEFITS_EVENTS.VIEW_INTEGRATED_BENEFITS_ENTRYCARD:
          return {
            ...commonProps,
            [INTEGRATED_BENEFITS_MIXPANEL_PROPERTIES.BENEFITS]: displayedRecommendedBenefitsInExplorer,
          }
        default:
          return commonProps
      }
    },
    [
      userLyraId,
      customerLyraId,
      registeredCountry,
      matchFor,
      displayedRecommendedBenefitsInExplorer,
      displayedOtherBenefitsInExplorer,
      allRecommendedBenefits,
      allOtherBenefits,
    ],
  )
}
