import { Response } from '../../models/assignments/Assignments'
import { ProgramNames } from '../../models/programs/Programs'
import { RiskFactorsResponse } from '../../models/riskFactors/RiskFactors'
import { PROGRAMS, ProgramsType, ProgramType } from '../common/constants/customerProgram'

export const isHighAlert = (values: Response, programName: string) => {
  const isTeensProgram = programName === ProgramNames.TeensTherapy
  const isTherapyProgram = programName === ProgramNames.BlendedCareTherapy

  const isActiveSI =
    values.phq9_9_1 === 1 ||
    values.phq9_9_2 === 1 ||
    (values.rcads25_18 as number) > 0 ||
    (isTeensProgram && (values.suicide === 1 || values.selfInjury === 1))
  const isPassiveSI = values.phq9_9_1 === 0 && values.phq9_9_2 === 0
  const isSI = isActiveSI || isPassiveSI

  const program: ProgramType = PROGRAMS[programName as keyof ProgramsType]
  const checkInSIQuestion = program.CHECK_IN_SI_QUESTION
  const checkInSelfHarmQuestion = program.CHECK_IN_SELF_HARM_QUESTION

  const CHRTPlanQuestion = program.CHRT_SI_PLAN_QUESTION
  const CHRTThoughtsQuestion = program.CHRT_SI_QUESTION
  const CHRTThoughtsAboutHowQuestion = program.CHRT_SI_QUESTION_2

  const isActiveSICheckIn = Boolean(
    (isTherapyProgram || isTeensProgram) &&
      ((checkInSIQuestion && values[checkInSIQuestion] > 5) ||
        (checkInSelfHarmQuestion && values[checkInSelfHarmQuestion] > 5)),
  )
  const isActiveSICHRT = Boolean(
    (isTherapyProgram || isTeensProgram) &&
      ((CHRTPlanQuestion && values[CHRTPlanQuestion] > 0) ||
        (CHRTThoughtsQuestion && values[CHRTThoughtsQuestion] > 0) ||
        (CHRTThoughtsAboutHowQuestion && values[CHRTThoughtsAboutHowQuestion] > 0)),
  )

  return { isActiveSI, isPassiveSI, isSI, isActiveSICheckIn, isActiveSICHRT }
}

export const hasPastSI = (response: RiskFactorsResponse) => {
  return (
    response.is_high_alert_email_sent ||
    response.clinical_factors.safety_alert_indicated ||
    response.clinical_factors.self_harm_indicated ||
    response.clinical_factors.suicidal_ideation_indicated
  )
}
