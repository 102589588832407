import { Map } from 'immutable'
import isEmpty from 'lodash-es/isEmpty'

import { SESSION_FORMAT, STATES } from '@lyrahealth-inc/shared-app-logic'

import { resetPlacesTypeahead } from './google-places-typeahead/google-places-typeahead-actions'
import MatcherSearchQueries from './matcher-search-queries'
import * as BulkCopyActions from '../../../../data/bulk-copy/bulk-copy-actions'
import {
  getMatcherSearchAge,
  getMatcherSearchAgeBucket,
  getMatcherSearchAreAllRequired,
  getMatcherSearchDistance,
  getMatcherSearchName,
  getMatcherSearchQualitySearch,
  getMatcherSearchRequiredStateLicenses,
  getMatcherSearchSessionFormat,
  getMatcherSearchShowLCProvidersOnly,
  getMatcherSearchText,
  getMatcherSearchTreatmentPreference,
} from '../../data/careManagerSelectors'
import { LoadingIndicatorController } from '../../loading-indicator/loading-indicator-actions'

export const PREFERRED_CONTACT = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  PHONE_EMAIL: 'PHONE_EMAIL',
  NONE: 'NONE',
}

export function getPreferredContactMethod(contactMethods: $TSFixMe) {
  let likesPhone = false
  let likesEmail = false

  contactMethods &&
    // eslint-disable-next-line array-callback-return
    contactMethods.map((method: $TSFixMe) => {
      if (method.toLowerCase().includes('phone')) {
        likesPhone = true
      }
      if (method.toLowerCase().includes('email')) {
        likesEmail = true
      }
    })

  if (likesPhone && likesEmail) {
    return PREFERRED_CONTACT.PHONE_EMAIL
  }
  if (likesPhone && !likesEmail) {
    return PREFERRED_CONTACT.PHONE
  }
  if (!likesPhone && likesEmail) {
    return PREFERRED_CONTACT.EMAIL
  }
  return PREFERRED_CONTACT.NONE
}

export function safelyStringifyArray(arr: $TSFixMe) {
  if (arr) {
    return arr.join(', ')
  }
  return ''
}

export const SET_MATCHER_LIST_DATA = 'SET_MATCHER_LIST_DATA'
// @ts-expect-error TS(7006): Parameter 'searchType' implicitly has an 'any' typ... Remove this comment to see the full error message
export const setMatcherListData = (searchType, data) => ({
  type: SET_MATCHER_LIST_DATA,
  searchType,
  data,
})

export const SET_MATCHER_SEARCH_PARAM = 'SET_MATCHER_SEARCH_PARAM'
export const setMatcherSearchParam = ({ param, value }: $TSFixMe) => ({ type: SET_MATCHER_SEARCH_PARAM, param, value })

export const SET_SEARCH_CUSTOMER_PARAM = 'SET_SEARCH_CUSTOMER_PARAM'
export const setSearchCustomerParam = ({ value }: $TSFixMe) => ({ type: SET_SEARCH_CUSTOMER_PARAM, value })

export const RESET_MATCHER_LIST_DATA = 'RESET_MATCHER_LIST_DATA'
export const resetMatcherListData = () => ({ type: RESET_MATCHER_LIST_DATA })

export const SET_MATCHER_BULK_COPY = 'SET_MATCHER_BULK_COPY'
export const setMatcherBulkCopy = (copy: $TSFixMe) => ({
  type: SET_MATCHER_BULK_COPY,
  copy,
})

export const CLEAR_MATCHER_BULK_COPY = 'CLEAR_MATCHER_BULK_COPY'
export const clearMatcherBulkCopy = () => ({ type: CLEAR_MATCHER_BULK_COPY })

export const RESET_MATCHER_SEARCH = 'RESET_MATCHER_SEARCH'
export const resetMatcherSearch = () => ({ type: RESET_MATCHER_SEARCH })

export const SET_MATCHER_RESULTS_PAGE = 'SET_MATCHER_RESULTS_PAGE'
export const setMatcherResultsPage = (page: number) => ({ type: SET_MATCHER_RESULTS_PAGE, page })

export function resetMatcherSearchParams() {
  return function (dispatch: $TSFixMe) {
    dispatch(resetMatcherSearch())
    dispatch(resetPlacesTypeahead())
    dispatch(setMatcherResultsPage(1))
  }
}

export function getSearchQuery({ state, sortField = 'none' }: $TSFixMe, isLctTeensEnabled = false) {
  const areAllRequired = getMatcherSearchAreAllRequired(state)
  let sessionFormatState = getMatcherSearchSessionFormat(state)

  // If no formats are selected in internal matcher
  // Treat it as a search for all types of session formats
  if (!Array.isArray(sessionFormatState) || !sessionFormatState.length)
    sessionFormatState = SESSION_FORMAT.map((format) => format.text)

  // Convert to new sessionFormat structure e.g. {"All": ["In-Person", "Live-Messaging"]}
  const sessionFormat = areAllRequired ? { All: sessionFormatState } : { Any: sessionFormatState }
  const payload = {
    searchQuery: ((getMatcherSearchText(state) ?? '') as string).replace(/"/g, '\\"'),
    location: state.getIn(['googlePlacesTypeahead', 'coordinates']) || state.getIn(['googlePlacesTypeahead', 'text']),
    treatmentPreference: getMatcherSearchTreatmentPreference(state),
    distance: getMatcherSearchDistance(state),
    locationState: state.getIn(['googlePlacesTypeahead', 'state']),
    searchName: getMatcherSearchName(state),
    sessionFormat: JSON.stringify(sessionFormat).replace(/"/g, '\\"'),
    qualitySearch: getMatcherSearchQualitySearch(state),
    showLCProvidersOnly: getMatcherSearchShowLCProvidersOnly(state),
    sortField,
  }

  if (isLctTeensEnabled) {
    Object.assign(payload, { age: getMatcherSearchAge(state) })
  } else {
    Object.assign(payload, { ageBucket: getMatcherSearchAgeBucket(state) })
  }
  return payload
}

export function searchDirectAccessProvider(isLctTeensEnabled: boolean) {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    const state = getState()
    const sortField = 'none'

    const locationState = state.getIn(['googlePlacesTypeahead', 'state'])
    const requiredStateLicenses = getMatcherSearchRequiredStateLicenses(state).map(
      ({ value }: { value: string }) => value,
    )
    const locationsToSearch = [...requiredStateLicenses, locationState]
    dispatch(LoadingIndicatorController.setIndicatorLoading())

    MatcherSearchQueries.directAccessProvidersQuery(getSearchQuery({ state, sortField }, isLctTeensEnabled)).then(
      (r) => {
        const stateAbbreviations = STATES.map(({ value }: { value: string }) => value)
        dispatch(
          setMatcherListData(
            'directAccessProviders',
            r?.data?.directAccessProviders?.filter(
              (provider: { license_info: string[] }) =>
                isEmpty(locationsToSearch) ||
                // If the provider doesn't have any licenses, pass the provider back and assume operational vetting will occur
                isEmpty(provider.license_info) ||
                // If the provider has a license that doesn't match to a US state / territory, assume it's something
                // that can't be vetted with a simple state-matching algorithm and pass through
                provider.license_info.some((location) => !stateAbbreviations.includes(location)) ||
                // For every desired searched location, check whether that location is represented in the provider's licenses
                locationsToSearch
                  .filter((location) => location)
                  .every((location) => provider.license_info.includes(location)),
            ) || [],
          ),
        )
        dispatch(LoadingIndicatorController.setIndicatorIdle())
      },
      () => {
        dispatch(LoadingIndicatorController.setIndicatorIdle())
      },
    )
  }
}

export function handleSearch(isLctTeensEnabled: boolean) {
  return function (dispatch: $TSFixMe) {
    dispatch(resetMatcherListData())
    dispatch(BulkCopyActions.resetBulkCopy())
    dispatch(setMatcherResultsPage(1))
    return dispatch(searchDirectAccessProvider(isLctTeensEnabled))
  }
}

export function setBulkCopyValue() {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    const $$state = getState()
    const bulkCopy = $$state.get('bulkCopy', Map()).toJS()
    dispatch(setMatcherBulkCopy(bulkCopy))
  }
}

export function clearBulkCopy() {
  return function (dispatch: $TSFixMe) {
    dispatch(clearMatcherBulkCopy())
    dispatch(BulkCopyActions.resetBulkCopy())
  }
}

export const SET_CNT_BOOKING_PROVIDER = 'SET_CNT_BOOKING_PROVIDER'
export const setCNTBookingProvider = (providerLyraId: string) => ({
  type: SET_CNT_BOOKING_PROVIDER,
  providerLyraId,
})

export const CLEAR_CNT_BOOKING_PROVIDER = 'CLEAR_CNT_BOOKING_PROVIDER'
export const clearCNTBookingProvider = () => ({
  type: CLEAR_CNT_BOOKING_PROVIDER,
})
