import { isEmpty } from 'lodash-es'

import { OnboardingData, RegistrationData, RegistrationFormValues, RegistrationType } from './types'
import { User } from '../../models'
import { Attestation } from '../../models/attestations/Attestations'
import { RaceCategory } from '../../models/registration/Registration'
import { ELIGIBILITY_TYPES } from '../common/constants/constants'
import { convertDateStringFromISOToUSFormat } from '../common/utils/dateUtils'
import { EligibilityField } from '../eligibility/types'

export const getRegistrationDataFromFormValues = (
  formValues: RegistrationFormValues,
  formProps: {
    isAttestationEnabled?: boolean
    attestationData: Attestation[]
    ethnicityInformation?: { id: number; raceCategories: RaceCategory[] }
    customerName?: string
    id: string
    token: string
    sso?: boolean
  },
  isInternationalRegistrationExperience: boolean,
  registrationType?: RegistrationType | undefined,
  entryPoint?: string,
): RegistrationData => {
  const { isAttestationEnabled, attestationData, ethnicityInformation, customerName, id, token, sso } = formProps
  const {
    userFirstName,
    userLastName,
    userPreferredFirstName,
    userPreferredLastName,
    password,
    userDOB,
    hasConsentedToTermsOfUse,
    hasConsentedToTermsOfUseAndHIPAA,
    eligibilityType = ELIGIBILITY_TYPES.UNKNOWN,
    dependentTypeId,
    ...restOfValues
  } = formValues

  // Depending on the employee or dependent type, the field name for the partner field changes
  let customerCode
  if (eligibilityType === ELIGIBILITY_TYPES.EMPLOYEE) {
    customerCode = formValues[EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]
  } else if (eligibilityType === ELIGIBILITY_TYPES.DEPENDENT) {
    customerCode = formValues[EligibilityField.DEPENDENT_CUSTOMER_CODE_FIELD_NAME]
  }

  const attestations = Object.keys(restOfValues)
    .filter((formKey) => formKey.startsWith('attestation-'))
    .map((formKey) => restOfValues[formKey])
  const hasAttested = attestations.every((attestation) => attestation) && isAttestationEnabled
  const attestationIds = attestationData.map((attestation: Attestation) => attestation.id.toString())

  // International users don't see HIPAA attestations, so we can ignore it
  const hasConsented =
    (hasConsentedToTermsOfUse && isInternationalRegistrationExperience) ||
    // for US users, we combine terms of use and HIPAA
    (!isInternationalRegistrationExperience && hasConsentedToTermsOfUseAndHIPAA)

  const registrationData: RegistrationData = {
    customerCode,
    dependentTypeId,
    eligibilityType,
    customer: customerName,
    firstname: userFirstName ? userFirstName : undefined,
    lastname: userLastName ? userLastName : undefined,
    preferredFirstName: userPreferredFirstName ? userPreferredFirstName : undefined,
    preferredLastName: userPreferredLastName ? userPreferredLastName : undefined,
    username: id,
    password,
    // Convert DOB values to US format in the payload before sending to BE, since the BE uses U.S. formatted dates
    dob: convertDateStringFromISOToUSFormat(userDOB),
    hasConsented,
    attestationIds: hasAttested ? attestationIds : undefined,
    token,
    raceVersionId:
      registrationType === 'CNT' || registrationType === 'PARENT_LED_TEEN' || registrationType === 'FAMILY_HUB'
        ? undefined
        : ethnicityInformation?.id?.toString(),
    sso,
    ...(registrationType === 'PARENT_LED_TEEN' && { parentLedTeen: true }),
    ...(registrationType === 'FAMILY_HUB' && { entryPoint: 'FAMILY_HUB' }),
  }

  return registrationData
}

export const getEligibilityDataFromRegistration = (
  formValues: RegistrationFormValues,
  registrationData: RegistrationData,
  newUser: User,
) => {
  const { customer, eligibilityType = ELIGIBILITY_TYPES.UNKNOWN, customerCode, dependentTypeId } = registrationData
  let {
    dob = newUser?.dob, // FIXME: Starbucks dependents won't have a DOB to pass in - need to figure out how it can be removed if possible
    firstname = newUser?.firstname,
    lastname = newUser?.lastname,
  } = registrationData

  // Eligibility Check
  // We need to override firstname and lastname fields from registration field if the user is a dependent.
  // Need to set the first/last name to the name of the employee giving the dependent access to Lyra services.
  if (
    eligibilityType === ELIGIBILITY_TYPES.DEPENDENT &&
    formValues?.employeeFirstName &&
    formValues?.employeeLastName
  ) {
    firstname = formValues.employeeFirstName
    lastname = formValues.employeeLastName
  }

  // We also need to override for dob. (This is not always present in the eligibility check e.g. Starbucks)
  if (eligibilityType === ELIGIBILITY_TYPES.DEPENDENT && formValues?.employeeDOB) {
    dob = formValues.employeeDOB
  }

  return {
    customer,
    dob: convertDateStringFromISOToUSFormat(dob),
    firstname,
    lastname,
    eligibilityType,
    customerCode,
    dependentTypeId,
  }
}

export const getOnboardDataFromFormValues = (
  formValues: RegistrationFormValues,
  raceVersionId?: number,
): OnboardingData => {
  const { gender, race, allowUserResearch } = formValues

  const onboardingData: OnboardingData = {
    gender,
    race: race,
    allowUserResearch: !!allowUserResearch,
    raceVersionId: !isEmpty(race as string[]) ? raceVersionId : undefined,
  }
  return onboardingData
}
