import React, { FunctionComponent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect, useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { useTheme } from 'styled-components/native'

import {
  COMMON_MIXPANEL_PROPERTIES,
  PATHWAYS_EVENTS,
  PATHWAYS_PAGE,
  PATHWAYS_PROPERTIES,
  PATHWAYS_TYPE,
  REFERRAL_ENTRY_POINTS,
  REFERRAL_EVENTS,
  useFlags,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import { LoadingIndicator, openUrl, ToastContent } from '@lyrahealth-inc/ui-core-crossplatform'
import { ComingSoonMicrositeHomePage } from '@lyrahealth-inc/ui-core-crossplatform/src/microsite'

import { LYRA_MARKETING_LAUNCH_DAY_REMINDER_LINK } from '../../../../common/constants/appConstants'
import { useCustomerCostProperties } from '../../../../common/hooks/useCustomerCostProperties'
import { useIsCostMessagingEnabled } from '../../../../common/hooks/useIsCostMessagingEnabled'
import { useMicrositeCoverageType } from '../../../../common/hooks/useMicrositeCoverageType'
import { useUpdatedMicrositeConfig } from '../../../../common/hooks/useUpdatedMicrositeConfig'
import { doesUserHaveMembershipInCustomer } from '../../../../common/utils/customerUtils'
import { shareReferral } from '../../../../common/utils/referralUtils'
import { setToastContent } from '../../../../data/appGlobals/appGlobalsActions'
import { getDeviceUUID } from '../../../../data/appGlobals/appGlobalsSelectors'
import {
  getBeforeLaunch,
  getCustomerInfo,
  getCustomerLaunchDate,
  getCustomerName,
  getCustomerPropertiesMap,
  getEssentialsDisabled,
  getLegalNotice,
  getLwMobileEnabled,
  getNumberOfSessions,
  getProgramMembershipType,
} from '../../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../../data/mixpanel'
import { TrackEventWithObjAction } from '../../../../data/mixpanel/types'
import { getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer } from '../../../../data/user/userSelectors'
import { doPageLoad } from '../../data/micrositeActions'

interface ComingSoonMicrositeHomeContainerProps {
  actions: {
    doPageLoad: (customerName: string, eventProps?: Dict, page?: string) => void
    trackEventWithObj: TrackEventWithObjAction
    setToastContent: (content: ToastContent) => void
  }
}

const ComingSoonMicrositeHomeContainer: FunctionComponent<ComingSoonMicrositeHomeContainerProps> = ({
  actions: { doPageLoad, trackEventWithObj, setToastContent },
}) => {
  const { isReferralBannerEnabled } = useFlags()
  const { activeLanguage } = useLyraIntl()
  const startDate = useSelector(getCustomerLaunchDate)
  const customerName = useSelector(getCustomerName)
  const isUserAssumedInternational = useSelector(getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer)
  const membershipType = useSelector(getProgramMembershipType)
  const beforeLaunch = useSelector(getBeforeLaunch)
  const legalNotice = useSelector(getLegalNotice)
  const customerPropertiesMap = useSelector(getCustomerPropertiesMap)?.toJS()
  const hasMembershipInCustomer = doesUserHaveMembershipInCustomer({ membershipType })
  const deviceUUID = useSelector(getDeviceUUID)
  const isMobileAppDisabled = !useSelector(getLwMobileEnabled)
  const essentialsDisabled = useSelector(getEssentialsDisabled)
  const numberOfSessions = useSelector(getNumberOfSessions)
  const customerDisplayName = useSelector(getCustomerInfo)

  const updatedMicrositeConfig = useUpdatedMicrositeConfig(PATHWAYS_TYPE.COMING_SOON)
  const coverageType = useMicrositeCoverageType(PATHWAYS_TYPE.COMING_SOON)
  const {
    breakpoints: { isMobileSized },
  } = useTheme()
  const { formatMessage } = useIntl()
  const { search } = useLocation()
  const [searchParams] = useSearchParams()
  const customerCostProperties = useCustomerCostProperties()
  const isCostMessagingEnabled = useIsCostMessagingEnabled()

  useEffect(() => {
    if (isReferralBannerEnabled && search.includes('utm_source=coming-soon-microsite-banner')) {
      trackEventWithObj({
        event: REFERRAL_EVENTS.VIEW_COMING_SOON_MICROSITE,
        ...{
          utm_source: searchParams.get('utm_source'),
          utm_medium: searchParams.get('utm_medium'),
          utm_campaign: searchParams.get('utm_campaign'),
        },
      })

      setTimeout(() => {
        window.location.href = `${LYRA_MARKETING_LAUNCH_DAY_REMINDER_LINK}?customer=${customerName}&lang=${activeLanguage}`
      }, 1000)
    }
  }, [activeLanguage, customerName, isReferralBannerEnabled, search, searchParams, trackEventWithObj])

  useEffect(() => {
    if (customerName) {
      doPageLoad(
        customerName,
        {
          [COMMON_MIXPANEL_PROPERTIES.LD_DEVICE_UUID]: deviceUUID,
          ...(customerName !== 'care' && {
            [COMMON_MIXPANEL_PROPERTIES.CUSTOMER_NAME]: customerName,
          }),
        },
        PATHWAYS_PAGE.COMING_SOON,
      )
    }
  }, [customerName, doPageLoad, deviceUUID])

  const onLegalFooterLinkPress = (url: string) => {
    openUrl(url)
  }
  const onNotifyMeOnLaunchPress = () => {
    window.location.href = `${LYRA_MARKETING_LAUNCH_DAY_REMINDER_LINK}?customer=${customerName}&lang=${activeLanguage}`
    trackEventWithObj({
      [COMMON_MIXPANEL_PROPERTIES.LD_DEVICE_UUID]: deviceUUID,
      event: PATHWAYS_EVENTS.CLICK_NOTIFY_ME_ON_LAUNCH,
      page: PATHWAYS_PAGE.COMING_SOON,
    })
  }
  const onReferralBannerPress = () => {
    shareReferral({
      source: 'coming-soon-microsite-banner',
      formatMessage,
      setToastContent,
      trackEventWithObj,
      isMobileSized,
      entryPoint: REFERRAL_ENTRY_POINTS.COMING_SOON_MICROSITE_BANNER,
    })
  }

  if (isReferralBannerEnabled && search.includes('utm_source=coming-soon-microsite-banner')) {
    return <LoadingIndicator size={45} />
  }

  return (
    <ComingSoonMicrositeHomePage
      micrositeConfig={updatedMicrositeConfig}
      heroSectionProps={{
        pathwaysType: PATHWAYS_TYPE.COMING_SOON,
        startDate,
        onNotifyMeOnLaunchPress,
        onExploreOnYourOwnCTAPress: () => {
          document.getElementById('lyra-offerings-section')?.scrollIntoView({ behavior: 'smooth' })
          trackEventWithObj({
            event: PATHWAYS_EVENTS.CLICK_EXPLORE_ON_YOUR_OWN,
            page: PATHWAYS_PAGE.COMING_SOON,
          })
        },
        onReferralBannerPress,
      }}
      lyraOfferingsSectionProps={{
        pathwaysType: PATHWAYS_TYPE.COMING_SOON,
        customerPropertiesMap,
        isInternational: isUserAssumedInternational,
        meetWithAnExpertTabPress: () => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.VIEW_MEET_WITH_AN_EXPERT_TAB,
            page: PATHWAYS_PAGE.COMING_SOON,
          })
        },
        wellnessResourcesTabPress: () => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.VIEW_CONTENT_LIBRARY_TAB,
            page: PATHWAYS_PAGE.COMING_SOON,
          })
        },
        lyraOfferingsTabPress: () => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.VIEW_ALL_OFFERINGS_TAB,
            page: PATHWAYS_PAGE.COMING_SOON,
          })
        },
      }}
      selfCareSectionProps={{
        language: activeLanguage,
        pathwaysType: PATHWAYS_TYPE.COMING_SOON,
        onContentClicked: (data) => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.CLICK_SELF_CARE_RESOURCE,
            page: PATHWAYS_PAGE.COMING_SOON,
            article_name: data.title,
          })
        },
      }}
      careNavigatorSectionProps={{
        pathwaysType: PATHWAYS_TYPE.COMING_SOON,
      }}
      faqSectionProps={{
        membershipType,
        beforeLaunch,
        startDate,
        hasMembershipInCustomer,
        onNotifyMeOnLaunchPress,
        onQuestionPress: (title: string) => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.CLICK_FAQS,
            page: PATHWAYS_PAGE.COMING_SOON,
            [PATHWAYS_PROPERTIES.FAQ_QUESTION]: title,
          })
        },
        customerCostProperties,
        isCostMessagingEnabled,
      }}
      legalAndCopyrightFooterProps={{
        onFooterLinkPressed: onLegalFooterLinkPress,
        isInternational: isUserAssumedInternational,
        legalNotice,
      }}
      membershipSectionProps={{
        isMobileAppDisabled,
        customerDisplayName,
        numberOfSessions,
        coverageType,
        isEssentialsDisabled: essentialsDisabled,
        language: activeLanguage,
        pathwayType: PATHWAYS_TYPE.COMING_SOON,
        onGetAppLinkPress: () => {
          trackEventWithObj({
            event: PATHWAYS_EVENTS.CLICK_MOBILE_APP_DOWNLOAD_LINK,
          })
        },
      }}
      mobileAppPromoSectionProps={{ pathwaysType: PATHWAYS_TYPE.COMING_SOON }}
    />
  )
}

function mapDispatchToProps(dispatch: $TSFixMe) {
  return {
    actions: bindActionCreators({ doPageLoad, trackEventWithObj, setToastContent }, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(ComingSoonMicrositeHomeContainer)
