import {
  getSearchForCareProgramOptionFlow,
  S4C_PROGRAM_OPTION_FLOW,
  SearcherInfo,
} from '@lyrahealth-inc/shared-app-logic'

export interface useDisplayCareLanguagePreferencesArgs {
  isSearchingForAdultTherapy: boolean
  isSearchingForCoaching: boolean
  isSearchingForChildTherapy: boolean
  isParentInitiatedChildPreferencesEnabled: boolean
  isSelfInitiatedTeensPreferencesEnabled: boolean
  searcherInfo: SearcherInfo | null
}

export const useDisplayCareLanguagePreferences = ({
  isSearchingForAdultTherapy,
  isSearchingForCoaching,
  isSearchingForChildTherapy,
  isParentInitiatedChildPreferencesEnabled,
  isSelfInitiatedTeensPreferencesEnabled,
  searcherInfo,
}: useDisplayCareLanguagePreferencesArgs) => {
  const searchForCareProgramOptionFlow = getSearchForCareProgramOptionFlow({
    isSearchingForAdultTherapy,
    isSearchingForCoaching,
    searcherDOB: searcherInfo?.dob as string,
    isSearchingForChildTherapy,
    isParentInitiatedChildPreferencesEnabled,
    isSelfInitiatedTeensPreferencesEnabled,
  })

  return searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.THERAPY
}
