import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { type LDFlags } from '@lyrahealth-inc/shared-app-logic/src/features/flags/useFlags'

import { SET_FEATURE_FLAGS } from './featureFlagActions'

interface FeatureFlagsState extends Map<string, any> {
  flags: LDFlags
}

const initialState: FeatureFlagsState = fromJS({
  flags: fromJS({}),
})

export default createReducer(initialState, {
  [SET_FEATURE_FLAGS]: ($$state: FeatureFlagsState, action: { value: LDFlags }) => {
    return $$state.mergeIn(['flags'], fromJS(action.value))
  },
})
