import React from 'react'
import { FormattedMessage, IntlShape } from 'react-intl'

import { CustomerRedirection, FormMetadata } from '@lyrahealth-inc/shared-app-logic'

import { Link } from '../../atoms'
import { tID } from '../../utils'

export const generateCustomerRedirectionMetadata = (
  customerRedirection: CustomerRedirection,
  formatMessage: IntlShape['formatMessage'],
): FormMetadata => {
  const { header, checkboxLabel, redirectionText, redirectionLinkPress, shouldShowBanner } = customerRedirection
  return {
    schema: {
      type: 'object',
      properties: {
        customerRedirectionCheckbox: {
          name: 'customerRedirectionCheckbox',
          type: 'string',
          validation: {
            isRequired: true,
          },
        },
      },
    },
    uiSchema: {
      'ui:order': ['customerRedirectionCheckbox'],
      customerRedirectionCheckbox: {
        'ui:widget': 'requiredCheckbox',
        'ui:options': {
          headerText: formatMessage(header),
          bannerMessage: (
            <FormattedMessage
              {...redirectionText}
              values={{
                link: (text: string) => (
                  <Link
                    testID={tID('RedirectionCheckbox-redirectLink')}
                    text={text}
                    onPress={redirectionLinkPress}
                    underline
                  />
                ),
              }}
            />
          ),
          checkboxLabel: formatMessage(checkboxLabel),
          shouldShowBanner: shouldShowBanner,
          baseInputStyle: { marginBottom: 24 },
        },
      },
    },
  }
}
