import { useMemo } from 'react'

import { Assignment, AssignmentGroup } from '../../../models/assignments/Assignments'
import { Provider, PROVIDER_TYPES, ProviderEnums } from '../../../models/providers/Providers'
import { getAppointmentDateTimeObject } from '../../appointments/utils'
import { AssignmentStatuses } from '../../assignments/types'
import { ActivitiesByProvider } from '../types'
/**
 * Handles parsing the assignments model data to create an object of provider id to assignments
 *
 *  { [providerId]: {
 *     sessions: {
 *      1: {
 *          sessionEndDate: XX/XX/XXXX
 *          groups: {
 *             lessons: [],
 *             exercises: [],
 *             assessments: []
 *          }
 *        }
 *     }
 *     2: ...
 *  }
 */
export const useAssignmentsByProviderSession = ({ assignments, providers }: Params): ActivitiesByProvider => {
  const getAssignmentsByProgram = (assignments: Assignment[], providers: Provider[]) => {
    const uiData = assignments.reduce<ActivitiesByProvider>((data, assignment) => {
      const { content, provider_id, session_period } = assignment
      const group = content.group
      const providerInfo = providers.find((p) => p.provider.lyra_id === provider_id)
      // handle initial assessments where the session_period starts at 0
      const sessionPeriod = session_period === 0 ? session_period + 1 : session_period
      const appt = providerInfo && providerInfo.appointments.find((a) => a.sessionNumber === sessionPeriod)
      const sessionEndDate = appt ? getAppointmentDateTimeObject(appt).format() : ''

      // add the provider id
      if (!data[provider_id]) {
        data[provider_id] = {
          providerName: providerInfo ? providerInfo.provider.display_name : '',
          providerImage: providerInfo ? providerInfo.provider.lyraProviderImage.imageUrl : '',
          providerType: providerInfo
            ? PROVIDER_TYPES[ProviderEnums[providerInfo.provider.lyra_type.toUpperCase()]].TITLE
            : '',
          sessions: {},
        }
      }

      // add the session period to the provider if it doesn't exist
      if (!data[provider_id].sessions[sessionPeriod]) {
        data[provider_id].sessions[sessionPeriod] = {
          sessionEndDate,
          groups: {
            lesson: [],
            exercise: [],
            assessment: [],
            infosheet: [],
          },
        }
      }

      // add the group to the session period if it doesn't exist
      if (!data[provider_id].sessions[sessionPeriod].groups[group]) {
        /**
         * Handle assignments with an initial session period of 0. Because these are
         * initial, they should belong to the first sessions
         */
        data[provider_id].sessions[sessionPeriod].groups[group] = []
      }
      // don't include missed assessments
      if (assignment.content.group !== AssignmentGroup.ASSESSMENT || assignment.status !== AssignmentStatuses.missed) {
        data[provider_id].sessions[sessionPeriod].groups[group].push(assignment)
      }

      return data
    }, {})
    return uiData
  }

  return useMemo(() => getAssignmentsByProgram(assignments, providers), [assignments, providers])
}

type Params = {
  assignments: Assignment[]
  providers: Provider[]
}
