import { User } from '@lyrahealth-inc/shared-app-logic'

import { axiosInstance } from '../axiosInstance'

export function submitEditProfileData(userId: string, data: Partial<User>) {
  return axiosInstance.put(`/v1/user/${userId}`, data)
}

export function updateBasicInfo(userId: $TSFixMe, data: $TSFixMe) {
  return axiosInstance.post(`/v1/user/${userId}/basic-info`, data)
}

export function updateChildInfo(userId: string, childLyraId: string, data: Partial<User>) {
  return axiosInstance.patch(`/v1/user/${userId}/child/${childLyraId}`, data)
}

export function updateTimeZoneData(lyraId: string, timezone: string) {
  return axiosInstance.post(`/v1/user/${lyraId}/timezone`, { timezone })
}

export function updateCountryData(lyraId: string, customerCountryId: number) {
  return axiosInstance.post(`/v1/user/${lyraId}/country`, { customerCountryId })
}

export function updateDisplayLanguageData(lyraId: string, displayLanguage: string) {
  return axiosInstance.post(`/v1/user/${lyraId}/displayLanguage`, { displayLanguage })
}

export function verifyEmailToUpdateBasicInfo() {
  return axiosInstance.post(`/v1/user/email/verification/update-info`)
}

export function enrollMFAData(lyraId: $TSFixMe) {
  return axiosInstance.post(`v1/mfa/enroll/${lyraId}`, { type: 'email' })
}

export function activateMFAData(lyraId: $TSFixMe, passCode: $TSFixMe) {
  return axiosInstance.post(`v1/mfa/activate/${lyraId}`, { type: 'email', passCode })
}

export function disableMFAData(lyraId: $TSFixMe) {
  return axiosInstance.post(`v1/mfa/disable/${lyraId}`, { type: 'email' })
}

export function getDataFileData() {
  return axiosInstance.get(`/v1/user/download/userdata`, {
    responseType: 'arraybuffer',
  })
}

export function onboard(lyraId: string, data: any) {
  return axiosInstance.put(`/v1/user/${lyraId}/onboard`, data)
}

export function registerSelfManagedDependent(lyraId: string, data: any) {
  return axiosInstance.post(`/v1/user/${lyraId}/register-family-hub-dependent-pending-user`, data)
}
