import { useEffect, useState } from 'react'

import { AxiosInstance, AxiosResponse } from 'axios'

import { SearchRequestData } from '../../../models'
import { PROGRAM_TAXONOMY } from '../../common/constants/customerProgram'
import { PARENTING_PARAMS } from '../../common/constants/speculativeConstant'
import { TREATMENT_OPTIONS } from '../../common/constants/treatmentOptions'
import { getAge, getAgeBucket } from '../utils'

export interface useHomebaseArgs {
  axiosInstance: AxiosInstance
  lyraId: string
  userDOB: string | undefined
  userCountryName: string | undefined
  isInternationalUser: boolean
  isCoachingEntryPointEnabled: boolean
  isCoachingEnabled: boolean
  isWellnessPromotionAvailable: boolean
  postSpeculativeSearch: (lyraId: string, searchData: SearchRequestData) => Promise<AxiosResponse<boolean>>
  isLctTeensEnabled: boolean
  isUserTeen: boolean
}

export type SpeculativeSearch = {
  hasSpeculativeCoaches: boolean
  hasSpeculativeParenting: boolean
}

export const useHomebase = ({
  axiosInstance,
  lyraId,
  userDOB,
  userCountryName,
  isInternationalUser,
  isCoachingEntryPointEnabled,
  isCoachingEnabled,
  isWellnessPromotionAvailable,
  postSpeculativeSearch,
  isLctTeensEnabled,
  isUserTeen,
}: useHomebaseArgs) => {
  const [speculativeSearch, setSpeculativeSearch] = useState<SpeculativeSearch>({
    hasSpeculativeCoaches: false,
    hasSpeculativeParenting: false,
  })

  useEffect(() => {
    async function fetchData() {
      const defaultSearchParams: SearchRequestData = {
        isUserInternational: isInternationalUser,
        onsite: false,
        matchFor: 'self',
        locationCountry: userCountryName,
        treatmentNaive: TREATMENT_OPTIONS.COACHING,
        treatmentPreference: TREATMENT_OPTIONS.COACHING,
      }

      if (isLctTeensEnabled) {
        defaultSearchParams.age = getAge({ date: userDOB ?? '' })
      } else {
        defaultSearchParams.ageBucket = getAgeBucket({ date: userDOB ?? '' })?.bucket
      }

      // Hide Wellness promotion if no coaches are available by calling speculative endpoint
      const coachingSearchData: SearchRequestData = {
        ...defaultSearchParams,
        clientele: PROGRAM_TAXONOMY.clientele.individual,
        treatment: PROGRAM_TAXONOMY.treatment.coaching,
        partner: PROGRAM_TAXONOMY.partner.blendedCare,
        offering: PROGRAM_TAXONOMY.offering.default,
      }
      const parentingSearchData: SearchRequestData = {
        ...defaultSearchParams,
        clientele: PARENTING_PARAMS.clientele,
        treatment: PARENTING_PARAMS.treatment,
        partner: PARENTING_PARAMS.partner,
        offering: PARENTING_PARAMS.offering,
        directLinkIntent: PARENTING_PARAMS.directLinkIntent,
      }
      await Promise.all([
        postSpeculativeSearch(lyraId, coachingSearchData),
        postSpeculativeSearch(lyraId, parentingSearchData),
      ])
        .then(([hasSpeculativeCoaches, hasSpeculativeParenting]) => {
          setSpeculativeSearch({
            hasSpeculativeCoaches: hasSpeculativeCoaches.data,
            hasSpeculativeParenting: hasSpeculativeParenting.data,
          })
        })
        .catch((e) => {
          console.error(e)
        })
    }
    fetchData()
  }, [axiosInstance, lyraId, userDOB, isInternationalUser, userCountryName, postSpeculativeSearch])

  return {
    showWellnessPromotion:
      isCoachingEntryPointEnabled &&
      isCoachingEnabled &&
      isWellnessPromotionAvailable &&
      speculativeSearch.hasSpeculativeCoaches &&
      !isUserTeen,
    isSpeculativeParentingAvailable: speculativeSearch.hasSpeculativeParenting,
  }
}
