import { useMemo } from 'react'

import {
  PROVIDER_AVAILABILITY_STATUS,
  ProviderAvailabilityBreakdown,
  ProviderCalendar,
  ProviderCalendarEntry,
  ProviderInfo,
} from '../../../models'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'
import { checkProviderCapacity, isContactForAvailabilityDisabled, isShowAppointmentsDisabled } from '../utils'

interface Params {
  providers?: ProviderInfo[]
  providerAvailabilities?: ProviderCalendar[]
  selectedTreatmentOption: TREATMENT_OPTIONS_TYPE
}

export interface AvailabilityState {
  contactForAvailabilityDisabled: boolean
  timeZoneId?: string
  availabilityBreakdown?: ProviderAvailabilityBreakdown
  availability?: ProviderCalendarEntry[]
  hasConnectedCalendar: boolean
  showAppointmentsDisabled: boolean
  interval?: number
}

export interface ProvidersDisplayed {
  providers: { [providerId: string]: AvailabilityState }
  statuses: Array<PROVIDER_AVAILABILITY_STATUS>
}

/**
 * Hook to encapsulate the logic that parses through all of the provider's calendar information and availability
 * to determine whether or not to display certain CTAs or availability breakdowns
 */
export const useProvidersDisplayed = ({ providers, providerAvailabilities, selectedTreatmentOption }: Params) => {
  return useMemo(() => {
    return (
      providers &&
      providers?.reduce<{
        providers: { [providerId: string]: AvailabilityState }
        statuses: Array<PROVIDER_AVAILABILITY_STATUS>
      }>(
        (acc, provider) => {
          let calendar: ProviderCalendar | undefined
          let availability
          let availabilityBreakdown
          let timeZoneId
          let interval
          const providerAvailability = providerAvailabilities?.find(
            (providerAvailabilityData) => providerAvailabilityData.id === provider.lyra_id,
          )

          if (providerAvailability) {
            availability = providerAvailability.availability
            availabilityBreakdown = providerAvailability.availabilityBreakdown
            timeZoneId = providerAvailability.timeZoneId
            interval = providerAvailability.interval
          } else if (!Array.isArray(provider.calendar)) {
            calendar = provider.calendar
            availability = calendar?.availability
            availabilityBreakdown = calendar?.availabilityBreakdown
            timeZoneId = calendar?.timeZoneId
            interval = calendar?.interval
          }

          const providerCapacity = provider.providerCapacity
          const providerMissingForSearch = provider.isMissingForSearch

          const hasAvailability = !!availability && availability.length > 0
          const hasConnectedCalendar = Array.isArray(availability)
          const showAppointmentsDisabled = isShowAppointmentsDisabled({
            hasConnectedCalendar,
            providerCapacity,
            selectedTreatmentOption,
            hasAvailability,
            providerMissingForSearch,
          })
          const contactForAvailabilityDisabled = isContactForAvailabilityDisabled({
            providerCapacity,
            selectedTreatmentOption,
          })
          const hasCapacity = checkProviderCapacity({ selectedTreatmentOption, providerCapacity })

          const getStatus = () => {
            if (!hasCapacity && !hasAvailability) {
              return PROVIDER_AVAILABILITY_STATUS.NO_CAPACITY_AND_NO_AVAILABILITY
            } else if (!hasCapacity && hasAvailability) {
              return PROVIDER_AVAILABILITY_STATUS.NO_CAPACITY_AND_HAS_AVAILABILITY
            } else if (hasCapacity && !hasAvailability) {
              return PROVIDER_AVAILABILITY_STATUS.HAS_CAPACITY_AND_NO_AVAILABILITY
            }
            return PROVIDER_AVAILABILITY_STATUS.HAS_CAPACITY_AND_HAS_AVAILABILITY
          }

          // add status to list, this is used to track to mixpanel the CTA that is displayed for latest provider results
          acc.statuses.push(getStatus())

          acc.providers[provider.lyra_id] = {
            contactForAvailabilityDisabled,
            timeZoneId,
            availabilityBreakdown,
            availability,
            hasConnectedCalendar,
            showAppointmentsDisabled,
            interval,
          }
          return acc
        },
        {
          statuses: [],
          providers: {},
        },
      )
    )
  }, [providers, providerAvailabilities, selectedTreatmentOption])
}
