import { Map } from 'immutable'
import { createSelector } from 'reselect'

import { getDevFlags } from '@lyrahealth-inc/shared-app-logic'

export const getFeatureFlagState = (state: Map<string, any>) => state?.get('featureFlag')

const getFeatureFlags = createSelector([getFeatureFlagState], (featureFlagState) => ({
  ...featureFlagState.get('flags').toJS(),
  ...getDevFlags(),
}))

export const getIsParentInitiatedChildPreferencesEnabled = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState?.isParentInitiatedChildPreferencesEnabled,
)
