import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText } from '../../atoms/bodyText/BodyText'
import { Link } from '../../atoms/link/Link'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { XButton } from '../../atoms/xButton/XButton'
import { Flex1SafeAreaView, Flex1View } from '../../templates/content/CommonViews'
import { mailTo, openUrl, tID } from '../../utils'

export interface LiveChatUnavailableHelpProps {
  onExit: () => void
  phoneNumber: string
  showExitButton?: boolean
}

const Container = styled(Flex1SafeAreaView)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['32px'] : 0,
  marginHorizontal: theme.breakpoints.isMobileSized ? `${theme.spacing['16px']}` : 0,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const StyledXButton = styled(XButton)({
  alignSelf: 'flex-end',
})

const HeaderContainer = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  marginBottom: theme.spacing['16px'],
}))

const StyledBodyText = styled(BodyText)(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

export const LiveChatUnavailableHelp: FunctionComponent<LiveChatUnavailableHelpProps> = ({
  phoneNumber,
  onExit,
  showExitButton = true,
}) => {
  const { colors } = useTheme()

  return (
    <Container testID={tID('LiveChatUnavailableHelp')}>
      <Flex1View>
        {showExitButton && (
          <StyledXButton onPress={onExit} showExitText={false} testID={tID('LiveChatUnavailableHelp-exit-button')} />
        )}
        <HeaderContainer
          size={SubheadSize.LARGE}
          text={
            <FormattedMessage
              defaultMessage='Live chat is offline'
              description='Header of section with information on how users can still get help even though live chat is unavailable'
            />
          }
        />
        <StyledBodyText
          color={colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='Our live chat isn’t available right now, but we’re still here to help.'
              description='Tells the user that help is available by means other than live chat'
            />
          }
        />
        <StyledBodyText
          color={colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='Contact us at {phoneLink} if you need immediate assistance, or email {emailLink} and we’ll respond within 1 business day.'
              description='Information how to contact us via phone or email for help'
              values={{
                phoneLink: <Link text={phoneNumber} onPress={() => openUrl(`tel:${phoneNumber}`)} />,
                emailLink: <Link text='care@lyrahealth.com' onPress={() => mailTo('care@lyrahealth.com')} />,
              }}
            />
          }
        />
        <BodyText
          color={colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='Live chat business hours are Monday - Thursday from 6am - 8pm PT, and Friday from 6am - 6pm PT.'
              description='Hours during which live chat is available'
            />
          }
        />
        <BodyText
          color={colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='If this is a life threatening emergency please dial 9-1-1 or go to your nearest hospital’s emergency room.'
              description='Emergency message'
            />
          }
        />
      </Flex1View>
    </Container>
  )
}
