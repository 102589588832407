import axios from 'axios'

import {
  EssentialsArticleRaw,
  EssentialsCourseRaw,
  OnEssentialsCardPressFunction,
  SELF_CARE_CATEGORY_TYPE,
  WellnessToolkitEssentialsContent,
} from './types'
import {
  Episode,
  EpisodeStates,
  ProgramNames,
  RiskFactorsResponse,
  TriageScreenerNames,
  TriageSearch,
  TriageSearchStatus,
} from '../../models'
import { TREATMENT_RECOMMENDATION_TIER } from '../common/constants/treatmentRecommendationsConstants'
import { translateToEssentialSiteCode } from '../pathways/utils'
import { PrimaryNeedsWords, TriageScreenerField, TriageWords } from '../searchForCare/constants'

/**
 * Wellness toolkits will not be available if:
 * - user has indicated self harm
 * - the latest search was Tier 3
 * - there's an active episode that's not self guided
 */
export const getIsWellnessPromotionAvailable = (
  triageSearches: TriageSearch[],
  risksFactors: RiskFactorsResponse | undefined,
  episodes: Episode[],
): boolean => {
  const isLatestSearchSafeForPromotion = isLatestCompletedSearchNotTierThree(triageSearches)
  const isSelfHarm = risksFactors === undefined ? true : risksFactors?.clinical_factors?.self_harm_indicated ?? true
  const isUserTreatmentRecSafeForPromotion = !isSelfHarm && isLatestSearchSafeForPromotion
  const isUserEpisodesSafeForPromotion = areActiveEpisodesCompatible(episodes)
  return isUserTreatmentRecSafeForPromotion && isUserEpisodesSafeForPromotion
}

/**
 *  Verify if users triage searches is safe for wellness toolkit.
 *  If the latest completed triage that is not Tier 3, return true
 *
 * @param searches
 */
export const isLatestCompletedSearchNotTierThree = (searches: TriageSearch[]) => {
  let latestCompletedSearch: TriageSearch | undefined = undefined

  for (let i = searches.length - 1; i >= 0; i--) {
    const search = searches[i]
    if (search.triage_status === TriageSearchStatus.COMPLETED) {
      latestCompletedSearch = search
      break
    }
  }

  return (
    searches.length === 0 ||
    !latestCompletedSearch ||
    latestCompletedSearch?.recommended_treatments?.shownTierPage !== TREATMENT_RECOMMENDATION_TIER.TIER_3
  )
}

/**
 *  Verify if users episodes is safe for wellness toolkit.
 *  If there is any active episodes that is not Self Guided return false
 *
 * @param episodes
 */
export const areActiveEpisodesCompatible = (episodes: Episode[]): boolean => {
  return !episodes.some(
    ({ program_name, state }) => program_name !== ProgramNames.SELF_CARE_APPS && state === EpisodeStates.IN_PROGRESS,
  )
}

const getWordCloudFromSearch = (
  search: TriageSearch,
  wordCloudKey: string,
  experienceKey: TriageScreenerField,
): string[] => {
  const questionnaires = search?.body?.questionnaires
  if (questionnaires) {
    return questionnaires[wordCloudKey]?.[experienceKey] ?? []
  }
  return []
}

const checkForWordInWordClouds = (wordsList: TriageWords[], search: TriageSearch) => {
  const wordCloudWords = [
    ...getWordCloudFromSearch(search, TriageScreenerNames.WORD_CLOUD_1, TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING),
    ...getWordCloudFromSearch(
      search,
      TriageScreenerNames.WORD_CLOUD_2,
      TriageScreenerField.WHAT_ARE_YOU_EXPERIENCING_2,
    ),
  ]
  return wordsList.some((word) => wordCloudWords.includes(word))
}

/**
 * Determine if users triage search is eligible for parent toolkit
 * Show parenting promotion if one of the conditions is true
 *  1. wordCloud1 and wordCloud2 contains 'Parenting Issues'
 *  2. if the search is a child search
 *
 * @param triageSearches - Users triage search
 */
export const getIsWellnessPromotionParentAvailable = (triageSearches: TriageSearch[]): boolean => {
  for (const search of triageSearches) {
    const words = [TriageWords.PARENTING_ISSUES, TriageWords.PARENTING]
    const isWellnessPromotionParentEnabled = checkForWordInWordClouds(words, search)

    if (isWellnessPromotionParentEnabled || search.body.childLyraId || search.body.child_lyra_id) {
      return true
    }
  }

  return false
}

/**
 * Returns true if the user is eligible to see the family toolkit based on the results of their past triage searches.
 * Check for any of the following conditions:
 *  1. Family/relationship issues selected for primary need
 *  2. Parenting/parenting issues, pregnancy/new child, or serving as caregiver selected in the word clouds
 *  3. Performed child search
 */
export const getIsFamilyPromotionAvailable = (triageSearches: TriageSearch[]): boolean => {
  for (const search of triageSearches) {
    const questionnaires = search?.body?.questionnaires
    let foundFamilyIndicatorPrimaryNeeds = false
    if (questionnaires) {
      const primaryNeeds = questionnaires[TriageScreenerNames.PRIMARY_NEEDS] as Dict
      if (primaryNeeds) {
        foundFamilyIndicatorPrimaryNeeds =
          primaryNeeds[TriageScreenerField.WHATS_ON_YOUR_MIND] === PrimaryNeedsWords.RELATIONSHIPS_AND_FAMILY
      }
    }

    const words = [
      TriageWords.PREGNANCY_NEW_CHILD,
      TriageWords.SERVING_AS_CAREGIVER,
      TriageWords.PARENTING_ISSUES,
      TriageWords.PARENTING,
    ]
    const foundFamilyIndicatorWordClouds = checkForWordInWordClouds(words, search)

    if (
      foundFamilyIndicatorPrimaryNeeds ||
      foundFamilyIndicatorWordClouds ||
      search.body.childLyraId ||
      search.body.child_lyra_id
    ) {
      return true
    }
  }

  return false
}

const parseContentType = (contentType: string) => {
  return contentType.split('_').join(' ')
}

export const formatEssentialsArticle = (
  data: EssentialsArticleRaw,
  onEssentialsCardPress: OnEssentialsCardPressFunction,
  locale: string,
) => {
  const uriArr = data.uri.split('/')
  const destinationSlug = uriArr.length > 0 ? uriArr[uriArr.length - 1] : ''
  const essentialContent: WellnessToolkitEssentialsContent = {
    title: data.title,
    imageUrl: data.image && data.image.length > 0 ? `https:${data.image[0].url}` : '',
    contentType: parseContentType(data.contentType),
    id: data.id,
    readTime: data.readTime,
    contentFormat: data.contentFormat,
    onPress: () => {
      onEssentialsCardPress(data.uri, data.title, data.contentFormat, data.contentType, destinationSlug, locale)
    },
  }
  return essentialContent
}

export const formatEssentialsCourse = (
  data: EssentialsCourseRaw,
  onEssentialsCardPress: OnEssentialsCardPressFunction,
  locale: string,
) => {
  const uriArr = data.uri.split('/')
  const destinationSlug = uriArr.length > 0 ? uriArr[uriArr.length - 1] : ''
  const essentialContent: WellnessToolkitEssentialsContent = {
    title: data.title,
    imageUrl: data.foreground && data.foreground.length > 0 ? `https:${data.foreground[0].url}` : '',
    contentType: '',
    id: data.id,
    readTime: `${data.subcategories.length} lessons`,
    contentFormat: '',
    categoryType: SELF_CARE_CATEGORY_TYPE.COURSE,
    onPress: () => {
      onEssentialsCardPress(data.uri, data.title, '', '', destinationSlug, locale)
    },
  }
  return essentialContent
}

export const runEssentialsQuery = async (
  locale: string,
  slug: string,
  query: string,
  essentialsCmsUrl: string,
  logError?: (error: Error) => void,
) => {
  const payload = {
    query,
    variables: {
      slug,
      siteCategory: 'essentials',
      site: translateToEssentialSiteCode(locale),
    },
  }

  try {
    if (!essentialsCmsUrl) {
      throw new Error('CMS URL is empty')
    }

    // Use new axios for essential csm external domain
    const instance = axios.create()
    const response = await instance.post(essentialsCmsUrl, payload)

    if (response.status !== 200) {
      throw new Error('Invalid response for essentials query')
    } else {
      return response.data?.data?.entries
    }
  } catch (e) {
    console.error('Failed to retrieve data for essentials query ' + e)
    if (logError) {
      logError(e as Error)
    }
    return []
  }
}
