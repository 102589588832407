import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Element } from 'react-scroll'

import { DirectDedicatedCareNavigatorInfoValues } from '@lyrahealth-inc/shared-app-logic'
import { Header as UICoreHeader } from '@lyrahealth-inc/ui-core'

import ContactCareNavigatorModal, { ContactCareNavigatorModalProps } from './ContactCareNavigatorModal'
import MessagesPopover from '../../../features/lyraTherapy/messagesPopover/MessagesPopover'

type LyraTherapyHeaderProps = ContactCareNavigatorModalProps & {
  user?: $TSFixMe // TODO: PropTypes.instanceOf(Map)
  activeTab?: string
  customClass?: string
  handleBackButtonClick?: $TSFixMeFunction
  handleLogout?: $TSFixMeFunction
  handleLogin?: $TSFixMeFunction
  trackMenuClick?: $TSFixMeFunction
  lyraLogo: React.ReactElement
  handleRegister?: $TSFixMeFunction
  ghost?: boolean
  shouldHideLoginButton?: boolean
  loggedOutButtons?: $TSFixMe[]
  loggedInButtons?: $TSFixMe[]
  essentialsDisabled?: boolean
  isWorkhubDisabled: boolean
  shouldShowCareNavigatorHeaderEntrypoint: boolean
  mixpanelCareNavigatorClick: () => void
  navigationMenuProps?: { className: string }
  headerNavMenu?: React.ReactElement
  handleHomeClick: () => void
  handleCareClick: () => void
  handleSessionsClick: () => void
  handleProgressClick: () => void
  handleEssentialsClick: (e?: React.MouseEvent<HTMLAnchorElement>) => void
  userDedicatedCareNavigatorInfo?: DirectDedicatedCareNavigatorInfoValues
  CareNavigatorHeaderEntryPointButton: React.ReactElement
}

const LyraTherapyHeader: React.FC<LyraTherapyHeaderProps> = ({
  user,
  activeTab,
  customClass,
  handleBackButtonClick,
  handleLogout,
  handleLogin,
  trackMenuClick,
  lyraLogo,
  shouldHideLoginButton,
  handleRegister,
  loggedOutButtons,
  loggedInButtons,
  ghost,
  essentialsDisabled,
  isWorkhubDisabled,
  shouldShowCareNavigatorHeaderEntrypoint,
  isModalOpen,
  setIsModalOpen,
  isInternational,
  customerPhoneNumber,
  onPressEmail,
  onPressFAQ,
  onPressPhoneCall,
  onPressScheduleCall,
  onPressLiveChat,
  onExit,
  isMobile,
  mixpanelCareNavigatorClick,
  showLiveChat,
  startLiveChat,
  isLyrian = false,
  navigationMenuProps,
  headerNavMenu,
  handleHomeClick,
  handleCareClick,
  handleSessionsClick,
  handleProgressClick,
  handleEssentialsClick,
  userDedicatedCareNavigatorInfo,
  CareNavigatorHeaderEntryPointButton,
}) => {
  const activePage = {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    home: activeTab.includes('search-care'),
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    assignments: activeTab.includes('assignments') || /\/secure\/index(\/)?$/.test(activeTab),
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    sessions: activeTab.includes('sessions'),
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    progress: activeTab.includes('progress'),
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    essentials: activeTab.includes('essentials'),
  }
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  loggedInButtons.push(
    <li>
      {/* @ts-expect-error TS(2322): Type '{ children: Element; href: string; className... Remove this comment to see the full error message */}
      <a
        href='#0'
        className={`${activePage.home ? 'active' : ''} lt-searchforcare updated-look-feel`}
        aria-current={activePage.home ? 'page' : null}
        data-test-id='LTHeader-homeTab'
        onClick={handleHomeClick}
      >
        <FormattedMessage defaultMessage='Home' description='Link to the home page' />
      </a>
    </li>,
  )

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  loggedInButtons.push(
    <li>
      {/* @ts-expect-error TS(2322): Type '{ children: Element; href: string; className... Remove this comment to see the full error message */}
      <a
        href='#0'
        className={`${activePage.assignments ? 'active' : ''} lt-care updated-look-feel`}
        aria-current={activePage.assignments ? 'page' : null}
        data-test-id={'LTHeader-careTab'}
        onClick={handleCareClick}
      >
        <FormattedMessage defaultMessage='Care' description='Link to the care page' />
      </a>
    </li>,
  )
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  loggedInButtons.push(
    <li>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Element id='lyraweb-lyraTherapy-sessionsScrollTo'>
        {/* @ts-expect-error TS(2322): Type '{ children: Element; href: string; className... Remove this comment to see the full error message */}
        <a
          href='#0'
          className={`${activePage.sessions ? 'active' : ''} lt-sessions updated-look-feel`}
          aria-current={activePage.sessions ? 'page' : null}
          data-test-id='LTHeader-sessionsTab'
          onClick={handleSessionsClick}
        >
          <FormattedMessage defaultMessage='Sessions' description='Link to view a user’s sessions with providers' />
        </a>
      </Element>
    </li>,
  )
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  loggedInButtons.push(
    <li>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Element id='lyraweb-lyraTherapy-progressScrollTo'>
        {/* @ts-expect-error TS(2322): Type '{ children: Element; href: string; className... Remove this comment to see the full error message */}
        <a
          href='#0'
          className={`${activePage.progress ? 'active' : ''} lt-progress updated-look-feel`}
          aria-current={activePage.progress ? 'page' : null}
          data-test-id='LTHeader-progressTab'
          onClick={handleProgressClick}
        >
          <FormattedMessage defaultMessage='Progress' description='Link to view a user’s assessment progress' />
        </a>
      </Element>
    </li>,
  )

  if (!essentialsDisabled || !isWorkhubDisabled) {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    loggedInButtons.push(
      <li>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Element id='lyraweb-lyraTherapy-essentialsScrollTo'>
          {/* @ts-expect-error TS(2322): Type '{ children: Element; href: string; className... Remove this comment to see the full error message */}
          <a
            href='#0'
            className={`${activePage.essentials ? 'active' : ''} lt-essentials updated-look-feel`}
            aria-current={activePage.essentials ? 'page' : null}
            data-test-id='LTHeader-essentialsTab'
            onClick={(e) => {
              handleEssentialsClick(e)
            }}
          >
            <FormattedMessage defaultMessage='Library' description='Link to Lyra Essentials and Work Hub' />
          </a>
        </Element>
      </li>,
    )
  }

  return (
    <>
      <UICoreHeader
        className={customClass}
        notification
        handleBackButtonClick={handleBackButtonClick}
        handleLogout={handleLogout}
        handleLogin={handleLogin}
        trackMenuClick={trackMenuClick}
        user={user}
        // @ts-expect-error TS(2322): Type '{}' is not assignable to type 'IntrinsicAttr... Remove this comment to see the full error message
        icon={<MessagesPopover />}
        lyraLogo={lyraLogo}
        shouldHideLoginButton={shouldHideLoginButton}
        handleRegister={handleRegister}
        loggedOutButtons={loggedOutButtons}
        loggedInButtons={loggedInButtons}
        ghost={ghost}
        shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
        CareNavigatorHeaderEntryPointButton={CareNavigatorHeaderEntryPointButton}
        isMobileSized={isMobile}
        navigationMenuProps={navigationMenuProps}
        headerNavMenu={headerNavMenu}
      />
      <ContactCareNavigatorModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        customerPhoneNumber={customerPhoneNumber}
        isInternational={isInternational}
        onPressEmail={onPressEmail}
        onPressFAQ={onPressFAQ}
        onPressPhoneCall={onPressPhoneCall}
        onPressScheduleCall={onPressScheduleCall}
        onPressLiveChat={onPressLiveChat}
        onExit={onExit}
        isMobile={isMobile}
        isLyrian={isLyrian}
        showLiveChat={showLiveChat}
        startLiveChat={startLiveChat}
        careNavigatorHeadshot={userDedicatedCareNavigatorInfo?.headShotURL}
        careNavigatorLicense={userDedicatedCareNavigatorInfo?.clinicalLicenses}
        careNavigatorName={userDedicatedCareNavigatorInfo?.firstName}
      />
    </>
  )
}

export default LyraTherapyHeader
