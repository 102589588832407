import { useCallback } from 'react'
import { NavigateFunction, useNavigate } from 'react-router'

import { SEARCHER_TYPE_TO_MATCH_FOR, TriageSearch } from '@lyrahealth-inc/shared-app-logic'

export interface NavigateToLatestProviderMatchesProps {
  setLatestMatchesSearch: (search?: TriageSearch) => void
  setSelectedSearchId: (searchId: string) => void
  inProgressSearch?: TriageSearch
  handleZeroViewSelect: (
    params: { whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR },
    navigate: NavigateFunction,
    location: string,
  ) => void
}

export const useNavigateToLatestProviderMatches = ({
  setLatestMatchesSearch,
  inProgressSearch,
  handleZeroViewSelect,
  setSelectedSearchId,
}: NavigateToLatestProviderMatchesProps) => {
  const navigate = useNavigate()
  return useCallback(() => {
    // If we are using the old triage, there is not search object, so set as an empty object
    setLatestMatchesSearch(inProgressSearch)
    if (inProgressSearch) {
      setSelectedSearchId(inProgressSearch.id)
    }
    handleZeroViewSelect({ whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF }, navigate, location.pathname)
  }, [setLatestMatchesSearch, inProgressSearch, handleZeroViewSelect, navigate, setSelectedSearchId])
}
