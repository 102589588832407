import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { NavigateFunction, useNavigate } from 'react-router'

import { SEARCHER_TYPE_TO_MATCH_FOR, SearcherInfo, useSearchAgeAndType } from '@lyrahealth-inc/shared-app-logic'

import { useGetUserDisplayName } from './useGetUserDisplayName'
import { getUserAge, getUserDOB } from '../../data/user/userSelectors'
import { getIsHealthPlanConfirmed } from '../../features/healthPlan/data/healthPlanSelectors'

export const useNavigateToSearchForCare = ({
  handleZeroViewSelect,
  setSearcherInfo,
}: {
  handleZeroViewSelect: (
    params: { whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR; isHealthPlanConfirmed: boolean },
    navigate: NavigateFunction,
    location: string,
  ) => void
  setSearcherInfo: (searcherInfo: SearcherInfo) => {
    type: string
    data: SearcherInfo
  }
}) => {
  const navigate = useNavigate()
  const { firstName, lastName } = useGetUserDisplayName()
  const userAge = useSelector(getUserAge)
  const userDOB = useSelector(getUserDOB)
  const isHealthPlanConfirmed = useSelector(getIsHealthPlanConfirmed)

  const getSearchType = useSearchAgeAndType()

  return useCallback(() => {
    if (userAge === undefined) {
      console.warn('Failed to navigate to search for care - missing user data')
      return
    }

    handleZeroViewSelect(
      { whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF, isHealthPlanConfirmed },
      navigate,
      location.pathname,
    )
    const { type } = getSearchType({ dob: userDOB, matchFor: SEARCHER_TYPE_TO_MATCH_FOR.SELF })
    setSearcherInfo({
      name: firstName,
      age: userAge,
      type,
      dob: userDOB,
      lastName,
    })
  }, [
    firstName,
    getSearchType,
    handleZeroViewSelect,
    isHealthPlanConfirmed,
    lastName,
    navigate,
    setSearcherInfo,
    userAge,
    userDOB,
  ])
}
