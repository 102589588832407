/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Modal } from 'react-native'
import { connect } from 'react-redux'

import styled, { useTheme } from 'styled-components/native'

import {
  COMMON_MIXPANEL_PROPERTIES,
  INACTIVITY_EVENTS,
  INACTIVITY_PROPERTIES,
  INACTIVITY_WARNING_DURATION_MS,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  colors,
  Flex1View,
  INACTIVITY_STATE,
  InactivityTimeout,
  IS_WEB,
  ThemeType,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { setTimeInactivityWarning, setTimeLastActive } from '../../../data/auth/authActions'
import { getTimeInactivityWarning } from '../../../data/auth/authSelectors'
import { sessionExpiredLogout, setIsSessionTimedOut } from '../../../data/logout/logoutActions'
import { getIsSessionTimedOut } from '../../../data/logout/logoutSelector'
import { trackEventWithObj } from '../../../data/mixpanel'
import mixpanel from '../../../data/mixpanel/mixpanel-browser'
import { TrackEventWithObjAction } from '../../../data/mixpanel/types'
import { getIsUserLoggedIn } from '../../../data/user/userSelectors'

type InactivityWarningScreenProps = {
  isUserLoggedIn: boolean
  isSessionTimedOut: boolean
  timeInactivityWarning: number
  setIsSessionTimedOut: (type: boolean) => void
  setTimeInactivityWarning: (value: number | null) => void
  setTimeLastActive: (value: number) => void
  sessionExpiredLogout: () => void
  trackEventWithObj: TrackEventWithObjAction
}

const INACTIVITY_WARNING_ID = 'InactivityWarning'

const ModalContainer = styled(Flex1View)<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: colors.shadow80,
  justifyContent: theme.breakpoints.isMinWidthTablet ? ('flex-start' as const) : ('flex-end' as const),
  position: IS_WEB ? ('fixed' as const) : ('absolute' as const),
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
}))

const ModalCard = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: colors.white,
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  borderBottomRightRadius: theme.breakpoints.isMinWidthTablet ? 16 : 0,
  borderBottomLeftRadius: theme.breakpoints.isMinWidthTablet ? 16 : 0,
  margin: theme.breakpoints.isMinWidthTablet ? 'auto' : 0,
  padding: theme.breakpoints.isMinWidthTablet ? 24 : '16px 8px',
  shadowColor: colors.black,
  shadowOffset: {
    height: 0,
    width: 0,
  },
  shadowOpacity: 0.05,
  shadowRadius: 15,
  width: theme.breakpoints.isMobileSized ? '100%' : theme.breakpoints.isMinWidthLaptop ? 900 : 768,
}))

const InactivityWarningScreen: React.FC<InactivityWarningScreenProps> = ({
  isUserLoggedIn,
  isSessionTimedOut,
  timeInactivityWarning,
  setIsSessionTimedOut,
  setTimeInactivityWarning,
  setTimeLastActive,
  sessionExpiredLogout,
  trackEventWithObj,
}) => {
  const { isSessionTimerEnabled } = useFlags()
  const [showModal, setShowModal] = useState<boolean>(isSessionTimedOut || !!timeInactivityWarning)
  const [warningDuration, setWarningDuration] = useState<number>(0)
  const { breakpoints } = useTheme()

  const handleModalClose = () => {
    setShowModal(false)

    if (isUserLoggedIn) {
      trackEventWithObj({
        event: INACTIVITY_EVENTS.RESUME,
      })
      setTimeInactivityWarning(null)
      setTimeLastActive(Date.now())
    } else {
      setIsSessionTimedOut(false)
    }
  }

  const handlePressSignOut = () => {
    trackEventWithObj({
      event: INACTIVITY_EVENTS.SIGN_OUT,
      [COMMON_MIXPANEL_PROPERTIES.TRIGGER]: INACTIVITY_PROPERTIES.USER_SIGN_OUT,
    })
    mixpanel.reset()
    pendo?.clearSession?.()
    window.location.href = '/logout'
  }

  const handleWarningTimeUp = () => {
    trackEventWithObj({
      event: INACTIVITY_EVENTS.SIGN_OUT,
      [COMMON_MIXPANEL_PROPERTIES.TRIGGER]: INACTIVITY_PROPERTIES.SESSION_EXPIRED,
    })
    setShowModal(false)
    sessionExpiredLogout()
    setIsSessionTimedOut(true)
    setWarningDuration(0)
  }

  const portal = document.getElementById(INACTIVITY_WARNING_ID)
  useEffect(() => {
    if (timeInactivityWarning && !showModal) {
      setShowModal(true)
      setWarningDuration(Math.max(0, (INACTIVITY_WARNING_DURATION_MS - (Date.now() - timeInactivityWarning)) / 1000))
    }
  }, [showModal, timeInactivityWarning])

  useEffect(() => {
    if (!portal && (isSessionTimedOut || timeInactivityWarning)) {
      // Sets a div at the bottom of the DOM tree for the modal to be rendered to keep the inactivity modal
      // stacked on top of all other Modals or FullScreenOverlays
      const newPortal = document.createElement('div')
      newPortal.id = INACTIVITY_WARNING_ID
      document.body.appendChild(newPortal)
    }
    return () => {
      portal?.remove()
    }
  }, [isSessionTimedOut, portal, timeInactivityWarning])

  const portalContent = (
    <Modal onRequestClose={handleModalClose} visible={showModal} animationType={'fade'} transparent={true}>
      <ModalContainer>
        <ModalCard>
          <InactivityTimeout
            warningDuration={warningDuration}
            onPressResume={handleModalClose}
            onPressClose={handleModalClose}
            onPressSignIn={handleModalClose}
            onPressSignOut={handlePressSignOut}
            onWarningTimeUp={handleWarningTimeUp}
            showClose={breakpoints.isMinWidthTablet}
            inactivityState={isSessionTimedOut ? INACTIVITY_STATE.SIGNED_OUT : undefined}
          />
        </ModalCard>
      </ModalContainer>
    </Modal>
  )

  const modal = portal && ReactDOM.createPortal(portalContent, portal)
  return isSessionTimerEnabled ? modal : null
}

const mapStateToProps = (state: any) => {
  return {
    isUserLoggedIn: getIsUserLoggedIn(state),
    isSessionTimedOut: getIsSessionTimedOut(state),
    timeInactivityWarning: getTimeInactivityWarning(state),
  }
}

export default connect(mapStateToProps, {
  setIsSessionTimedOut,
  setTimeInactivityWarning,
  setTimeLastActive,
  sessionExpiredLogout,
  trackEventWithObj,
})(InactivityWarningScreen)
