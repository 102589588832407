import React, { FunctionComponent, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { EmailIcon } from '../../atoms'
import { EmailIllustrationIcon } from '../../atoms/illustrations/EmailIllustrationIcon'
import { AppContext } from '../../context'
import { CareOptionCard } from '../../templates/careOptionCard/CareOptionCard'

export interface CareNavigatorEmailOptionCardProps {
  isInternational?: boolean
  isLyrians?: boolean
  onPress: (() => void) | undefined
}

export const CareNavigatorEmailOptionCard: FunctionComponent<CareNavigatorEmailOptionCardProps> = ({
  isInternational = false,
  isLyrians = false,
  onPress,
}) => {
  const { isDedicatedCareNavigatorEnabled } = useContext(AppContext)
  const { colors } = useTheme()

  const OptionHeader = () => {
    if (isLyrians) {
      return (
        <FormattedMessage
          defaultMessage='Submit a request'
          description='Text description shown to user to that clicking this option will start the process to send an email to the Lyrian support team'
        />
      )
    } else if (isInternational) {
      return (
        <FormattedMessage
          defaultMessage='Email us'
          description='Text description shown to user that clicking this option will start the process to email internationaleap@icasworld.com'
        />
      )
    } else {
      return (
        <FormattedMessage
          defaultMessage='Send an email'
          description='Text description shown to user to that clicking this option will start the process to send an email'
        />
      )
    }
  }

  const optionDescription = isLyrians ? (
    <FormattedMessage
      defaultMessage='A member of our team will reach out to you.'
      description='Text description explaining that the user will receive a response from the support team.'
    />
  ) : (
    <FormattedMessage
      defaultMessage='We’ll reply within 1 business day.'
      description='Text description explaining that the user will receive a response from their inquiry within one business day'
    />
  )

  return (
    <CareOptionCard
      onPress={onPress}
      optionIcon={
        isDedicatedCareNavigatorEnabled ? <EmailIcon fillColor={colors.textSecondary} /> : <EmailIllustrationIcon />
      }
      optionHeader={<OptionHeader />}
      optionDescription={optionDescription}
      testId={'CareNavigatorEmailOptionCard'}
    />
  )
}
