import React, { FunctionComponent, ReactNode } from 'react'

import styled, { useTheme } from 'styled-components/native'

import { BackButtonSmall } from '../../atoms/backButtonSmall/BackButtonSmall'
import { Image } from '../../atoms/image/Image'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { XButton } from '../../atoms/xButton/XButton'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { BackButtonV2 } from '../../molecules'
import { ContentContainer } from '../../templates/content/ContentContainer'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export interface ContactCareNavigatorBaseProps {
  header: ReactNode
  children: ReactNode
  backgroundColor?: string
  onExit?: () => void
  onBack?: () => void
  useSidePanelStyle?: boolean
  hideXButton?: boolean
  useGetInTouchHeaderStyle?: boolean
  desktopSizedIllustration?: JSX.Element
  mobileSizedIllustration?: JSX.Element
}

const ImageAndContents = styled.View(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.breakpoints.isMinWidthLaptop ? 0 : theme.spacing['48px'],
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['8px'] : theme.spacing['16px'],
}))

const Illustration = styled(Image)<{ theme: ThemeType }>(({ theme }) => ({
  width: theme.breakpoints.isMinWidthLaptop ? '310px' : '211px',
  height: theme.breakpoints.isMinWidthLaptop ? '153px' : '107px',
}))

export const ContactCareNavigatorBase: FunctionComponent<ContactCareNavigatorBaseProps> = ({
  header,
  children,
  backgroundColor,
  onExit,
  onBack,
  useSidePanelStyle = false,
  useGetInTouchHeaderStyle = false,
  hideXButton = false,
  desktopSizedIllustration = (
    <Illustration
      source={require('../../assets/care_navigators_mobile.png')}
      contentFit='contain'
      accessibilityIgnoresInvertColors
    />
  ),
  mobileSizedIllustration = (
    <Illustration
      source={require('../../assets/care_navigators_desktop.png')}
      contentFit='contain'
      accessibilityIgnoresInvertColors
    />
  ),
}) => {
  const { colors } = useTheme()
  const { isMobileSized } = useMediaQuerySize()

  const shouldShowSmallerVersion = isMobileSized || useSidePanelStyle

  const Contents = styled.View(() => ({
    width: shouldShowSmallerVersion ? '100%' : '624px',
  }))

  const StyledSubhead = styled(Subhead)({
    textAlign: useGetInTouchHeaderStyle ? 'center' : 'left',
  })

  const NavBar = styled.View(
    ({
      theme: {
        breakpoints: { isMobileSized },
        spacing,
      },
    }) => ({
      marginTop: isMobileSized ? (useSidePanelStyle ? spacing['32px'] : spacing['64px']) : 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
    }),
  )

  const BackButtonContainer = styled.View(
    ({
      theme: {
        breakpoints: { isMobileSized },
        spacing,
      },
    }) => ({
      marginTop: isMobileSized ? (useSidePanelStyle ? spacing['32px'] : spacing['64px']) : spacing['32px'],
    }),
  )

  const IllustrationContainer = styled.View(({ theme: { spacing } }) => ({
    marginBottom: useSidePanelStyle ? spacing['24px'] : spacing['16px'],
  }))

  const StyledContentContainer = styled(ContentContainer)<{ theme: ThemeType }>(
    ({ theme: { breakpoints, spacing } }) => ({
      backgroundColor: backgroundColor || colors.backgroundPrimary,
      paddingHorizontal: useSidePanelStyle ? 32 : breakpoints.isMobileSized ? 16 : 64,
      ...(breakpoints.isMinWidthLaptop && { maxHeight: '100vh', overflowY: 'auto', paddingTop: spacing['12px'] }),
    }),
  )

  return (
    <StyledContentContainer>
      {hideXButton && onBack && (
        <BackButtonContainer>
          <BackButtonV2 testID={tID('ContactCareNavPage-backButton')} onPress={onBack} isOatmealBackground />
        </BackButtonContainer>
      )}
      {!hideXButton && (
        <NavBar>
          {onBack && <BackButtonSmall testID={tID('ContactCareNavPage-backButton')} onPress={onBack} />}
          {onExit && <XButton testID={tID('ContactCareNavPage-xButton')} onPress={onExit} showExitText={false} />}
        </NavBar>
      )}
      <ImageAndContents testID={tID('ContactCareNavigatorBase')}>
        <IllustrationContainer>
          {isMobileSized ? mobileSizedIllustration : desktopSizedIllustration}
        </IllustrationContainer>
        <Contents>
          <StyledSubhead size={shouldShowSmallerVersion ? SubheadSize.MEDIUM : SubheadSize.LARGE} text={header} />
          {children}
        </Contents>
      </ImageAndContents>
    </StyledContentContainer>
  )
}
