import { defineMessage } from 'react-intl'

import { OPERATORS, PreferenceMetadataConfig, RENDER_CONDITION_TARGETS } from './types'
import {
  MEETING_STYLE_MODAL_OPTIONS,
  MEMBER_PREFERENCE_IDS,
  S4C_PROGRAM_OPTION_FLOW,
} from '../../memberPreferences/constants'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'

const meetingStylesPreferenceCoaching: PreferenceMetadataConfig = {
  name: 'meetingSetting',
  title: MEMBER_PREFERENCE_LABELS.meetingSetting,
  icon: 'MemberPreferencesMeetingSettingIllustrationIcon',
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.Video,
      id: MEMBER_PREFERENCE_IDS.VIDEO,
      description: [
        {
          value: defineMessage({
            defaultMessage: 'Option to switch to messaging after 1st session, when available',
            description: 'Bullet point describing video modality',
          }),
        },
      ],
    },
    {
      text: MEMBER_PREFERENCE_LABELS.live_messaging,
      id: MEMBER_PREFERENCE_IDS.LIVE_MESSAGING,
      description: [
        {
          value: defineMessage({
            defaultMessage:
              'Offers flexibility of messaging on-the-go, and can be a good alternative to video if finding a private space is challenging',
            description: 'First bullet point for live messaging modality description',
          }),
        },
        {
          value: defineMessage({
            defaultMessage: 'Option to switch to video after first session',
            description: 'Second bullet point for live messaging modality description',
          }),
        },
      ],
    },
  ],
  renderConditions: [
    {
      target: RENDER_CONDITION_TARGETS.MODALITY_PREFERENCE_OPTIONS,
      value: MEETING_STYLE_MODAL_OPTIONS.VIDEO_AND_LIVE_MESSAGING,
      operator: OPERATORS.EQ,
    },
  ],
  promptText: defineMessage({
    defaultMessage: 'I prefer to meet with a provider by:',
    description: 'Text prompting the user to select options they associate with',
  }),
  visibleFor: [
    S4C_PROGRAM_OPTION_FLOW.COACHING,
    S4C_PROGRAM_OPTION_FLOW.SELF_INITIATED_TEENS,
    S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD,
  ],
}

export default meetingStylesPreferenceCoaching
