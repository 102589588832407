import React, { FunctionComponent, useState } from 'react'
import { defineMessage, FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, MovingBooksLottie, PressableOpacity, ProviderWavingLottie, Subhead } from '../../../atoms'
import { BodyTextSize, getFloatingStyles, SubheadSize } from '../../../styles'
import { tID } from '../../../utils/utils'

const PathwayTypes = {
  ProviderLedIllustration: {
    Component: ProviderWavingLottie,
    title: defineMessage({
      defaultMessage: 'Meet with a mental health expert',
      description: 'Title describing provider led pathway',
    }),
    testID: 'providerLed',
  },
  SelfCareIllustration: {
    Component: MovingBooksLottie,
    title: defineMessage({
      defaultMessage: 'Access mindfulness, self-care, and events',
      description: 'Title describing self care pathway',
    }),
    testID: 'selfCare',
  },
}

export enum PATHWAY_ILLUSTRATIONS {
  PROVIDER_LED_ILLUSTRATION = 'ProviderLedIllustration',
  SELF_CARE_ILLUSTRATION = 'SelfCareIllustration',
}

export interface PathwayCardProps {
  illustration: PATHWAY_ILLUSTRATIONS
  onPress: () => void
}

const PathwayCardContainer = styled(PressableOpacity)<{ pressed: boolean }>(({ theme, pressed }) => ({
  alignItems: 'center',
  border: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.borderDefault,
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  flex: 1,
  ...(theme.breakpoints.isMinWidthDesktop && {
    width: '308px',
    padding: `${theme.spacing['48px']} ${theme.spacing['32px']}`,
    borderRadius: '32px',
  }),
  ...(theme.breakpoints.isMinWidthLaptop &&
    !theme.breakpoints.isMinWidthDesktop && {
      width: '232px',
      padding: `${theme.spacing['24px']}  ${theme.spacing['16px']}`,
      borderRadius: '32px',
    }),
  ...(theme.breakpoints.isMobileSized && {
    width: theme.breakpoints.isMinWidthMobileXs ? '156px' : '144px',
    borderRadius: '16px',
    padding: theme.spacing['16px'],
  }),
  ...(theme.breakpoints.isMinWidthTablet &&
    !theme.breakpoints.isMinWidthLaptop && {
      width: '174px',
      borderRadius: '16px',
      padding: theme.spacing['16px'],
    }),
  ...(pressed && {
    backgroundColor: theme.colors.backgroundSecondary,
  }),
}))

const SubHeadContainer = styled(Subhead)(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

const BodyTextContainer = styled(BodyText)(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  minWidth: '124px',
}))

const ComponentContainer = styled.View<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  alignItems: 'center',
  justifyContent: 'center',
}))

export const PathwayCard: FunctionComponent<PathwayCardProps> = ({ illustration, onPress }) => {
  const { title, Component, testID } = PathwayTypes[illustration]
  const { colors, breakpoints } = useTheme()
  const { isMobileSized, isMinWidthDesktop, isMinWidthLaptop, isMinWidthTablet } = breakpoints
  const [hovered, setHovered] = useState(false)
  const [pressed, setPressed] = useState(false)
  const isTabletSized = isMinWidthTablet && !isMinWidthLaptop
  const size = isMobileSized ? 64 : isTabletSized ? 80 : isMinWidthDesktop ? 158 : 128
  const floatingStyles = getFloatingStyles({ interactive: true, hovered, pressed })

  return (
    <PathwayCardContainer
      pressed={pressed}
      testID={tID(`PathwayCard-${testID}`)}
      // Shadows on web only work with StyleSheet.create. Using it with styled-components/native throws an error and breaks the app
      style={floatingStyles.floatingCard}
      onPress={() => {
        onPress()
      }}
      onPressIn={() => {
        setPressed(true)
      }}
      onPressOut={() => {
        setPressed(false)
      }}
      pressedOpacity={1}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      <ComponentContainer size={size}>
        <Component play={hovered} size={size} />
      </ComponentContainer>
      {isMobileSized || isTabletSized ? (
        <BodyTextContainer
          size={isTabletSized ? BodyTextSize.DEFAULT : BodyTextSize.SMALL}
          textAlign='center'
          color={colors.textSecondary}
          text={<FormattedMessage {...title} />}
        />
      ) : (
        <SubHeadContainer
          textAlign='center'
          size={isMinWidthDesktop ? SubheadSize.MEDIUM : SubheadSize.SMALL}
          color={colors.textSecondary}
          text={<FormattedMessage {...title} />}
        />
      )}
    </PathwayCardContainer>
  )
}
