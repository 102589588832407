import { defineMessage } from 'react-intl'

import { PreferenceMetadataConfig } from './types'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../../memberPreferences/constants'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'

const veteransPreference: PreferenceMetadataConfig = {
  name: 'isExperiencedWithVeterans',
  title: MEMBER_PREFERENCE_LABELS.isExperiencedWithVeterans,
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.experienceWorkingWithVeterans,
      id: MEMBER_PREFERENCE_IDS.EXPERIENCE_WORKING_WITH_VETERANS,
    },
  ],
  icon: 'MemberPreferencesVeteransIllustrationIcon',
  promptText: defineMessage({
    defaultMessage: 'I prefer a provider who has:',
    description: 'Text prompting the user to select options they associate with',
  }),
  visibleFor: [S4C_PROGRAM_OPTION_FLOW.COACHING, S4C_PROGRAM_OPTION_FLOW.THERAPY],
}

export default veteransPreference
