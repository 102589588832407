import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { AxiosError } from 'axios'
import { add, addYears, format, formatISO, isAfter, isValid, parse } from 'date-fns'
import { Dispatch } from 'redux'
import { useTheme } from 'styled-components/native'

import {
  confirmEligibilitySuccessMessage,
  CustomerName,
  EligibilityStatus,
  LW_API_ERROR_CODE,
} from '@lyrahealth-inc/shared-app-logic'
import { AlertIconStroke } from '@lyrahealth-inc/ui-core-crossplatform'

import { PAGE_ROUTES } from '../../../features/onboard/data/page-navigation/location-actions'
import { dismissEligibilityBanner } from '../../../features/register/eligibility/data/eligibilityActions'
import {
  CONFIRM_COVERAGE_BASIC_INFO_FORM_PATH,
  ELIGIBILITY_EXPIRATION_IN_MINS,
} from '../../constants/registrationFormConstants'
import { APIErrorMessage, buildAPIErrorMessageProps } from '../../http/data/APIErrorMessage'

export const ELIGIBILITY_BANNER_SNOOZE_EXPIRATION = 'eligibilityBannerSnoozeExpiration'
export const SHOW_YEARLY_HEALTH_PLAN_UPDATE = 'showYearlyHealthPlanUpdate'
export const YEARLY_HPI_BANNER_LOCALSTORAGE_DATE_FORMAT = 'MM/dd/yyyy'

// This provides mapping to actual banner color classes
export const BANNER_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
  DANGER: 'danger',
  NOTIFICATION: 'notification',
  CALM: 'calm',
}

const ConfirmEligibilityLink: FunctionComponent<{ text: string }> = ({ text }) => {
  const { colors } = useTheme()

  return (
    <Link
      style={{ color: colors.textWarning, fontWeight: '500', textDecorationLine: 'underline' }}
      data-testid='confirm-coverage-link'
      to={CONFIRM_COVERAGE_BASIC_INFO_FORM_PATH}
    >
      {text}
    </Link>
  )
}

const EligibilityIneligibleBannerContent: FunctionComponent<{ isWalmart?: boolean }> = ({ isWalmart = false }) => {
  const { colors } = useTheme()

  const ineligibilityMessage = isWalmart ? (
    <FormattedMessage
      defaultMessage='<link>Add a WIN number to confirm eligibility</link>'
      description='Banner message informing Walmart user to enter their Walmart Identification Number(WIN number) to confirm their eligibility'
      values={{
        link: (text: string) => <ConfirmEligibilityLink text={text} />,
      }}
    />
  ) : (
    <FormattedMessage
      defaultMessage='We can’t confirm your coverage for the Lyra benefit. <link>Confirm your information</link>'
      description='Banner message informing the user that the information they have entered could not be confirmed for coverage with an action to confirm their information'
      values={{
        link: (text: string) => <ConfirmEligibilityLink text={text} />,
      }}
    />
  )

  return (
    <span style={{ color: colors.textWarning }}>
      <span style={{ verticalAlign: 'middle', paddingRight: 10 }}>
        <AlertIconStroke size={25} />
      </span>
      {ineligibilityMessage}
    </span>
  )
}

export const MESSAGE = {
  APPOINTMENT_BOOKING_ERROR: (
    <FormattedMessage
      defaultMessage='An error occurred in booking your appointment. Please try again.'
      description='Banner message informing the appointment the user attempted to book was unsuccessful due to an error'
    />
  ),
  APPOINTMENT_BOOKING_SUCCESS: (
    <FormattedMessage
      defaultMessage='Your appointment has been booked successfully!'
      description='Banner message informing the user that the appointment the user booked was successful'
    />
  ),
  APPOINTMENT_REQUEST_ERROR: (
    <FormattedMessage
      defaultMessage='An error occurred in requesting your appointment. Please try again.'
      description='Banner message informing the appointment the user attempted to book was unsuccessful due to an error'
    />
  ),
  EDIT_PROFILE_SUCCESS: (
    <span>
      {' '}
      <i className='fa fa-check-circle' aria-hidden='true' />
      &nbsp;
      <FormattedMessage
        defaultMessage='Thank you! Your profile has been updated'
        description='Banner message informing the user that the changes to their profile were saved successfully'
      />
    </span>
  ),
  EDIT_PROFILE_FAIL: (
    <span>
      <FormattedMessage
        defaultMessage='We’re sorry. There was an error in updating your profile. Please try again.'
        description='Banner message informing the user that the changes to their profile were unsuccessful'
      />
    </span>
  ),
  EDIT_PROFILE_DATA_RETRIEVAL_FAILURE: (
    <span>
      <FormattedMessage
        defaultMessage='We’re sorry. There was an error in retrieving your profile data. Please try again.'
        description='Banner message informing that Lyra was not able to successfully load their profile data'
      />
    </span>
  ),
  HEALTHPLAN_NO_MATCH: (
    <span>
      <FormattedMessage
        defaultMessage='We are having trouble matching your information to a supported health plan. Please ensure that you entered your information correctly. For more help, see our <a1>FAQ</a1> or contact us at <a2>care@lyrahealth.com</a2>.'
        description='Banner message informing the user that Lyra was not able to match their plan to a supported health plan and to contact Lyra for any needed assistance'
        values={{
          a1: (text: string) => (
            <a href='/faq' target='_blank'>
              {text}
            </a>
          ),
          a2: (text: string) => <a href='mailto:care@lyrahealth.com'>{text}</a>,
        }}
      />
    </span>
  ),
  ELIGIBILITY_ELIGIBLE: (
    <span>
      <FormattedMessage {...confirmEligibilitySuccessMessage} />
    </span>
  ),
  ELIGIBILITY_INELIGIBLE: <EligibilityIneligibleBannerContent />,
  ELIGIBILITY_INELIGIBLE_WALMART: <EligibilityIneligibleBannerContent isWalmart />,
  CHECK_YEARLY_HEALTH_PLAN_UPDATE_TO_OVERVIEW: (
    <span data-testid='yearly-hpi-update-banner'>
      <FormattedMessage
        defaultMessage='Let’s make sure your health plan information is up to date. <link>Get started</link>'
        description='Banner message prompting the user to review their health plan information and ensure it is up to date'
        values={{
          link: (text: string) => (
            <Link
              style={{ color: 'white', fontWeight: '600' }}
              to='/secure/coverage-check/profile/health-plan-and-payment-information-overview'
            >
              {text}
            </Link>
          ),
        }}
      />
    </span>
  ),
  CHECK_YEARLY_HEALTH_PLAN_UPDATE: (
    <span data-testid='yearly-hpi-update-banner'>
      <FormattedMessage
        defaultMessage='Let’s make sure your health plan information is up to date. <link>Get started</link>'
        description='Banner message prompting the user to review their health plan information and ensure it is up to date'
        values={{
          link: (text: string) => (
            <Link
              style={{ color: 'white', fontWeight: '600' }}
              to={PAGE_ROUTES.HPI_COVERAGE_CHECK.PROFILE.HEALTH_PLAN_FORM}
            >
              {text}
            </Link>
          ),
        }}
      />
    </span>
  ),
  AUD_PROVIDER_FOUND: (
    <span>
      <FormattedMessage
        defaultMessage='<strong>Availability confirmed!</strong> Book an intro session with any Lyra Renew provider below—all of whom specialize in alcohol use.'
        description='Banner message informing the user that their provider has been found and to begin booking sessions with providers who also specialize in alcohol use'
        values={{ strong: (text: string) => <strong>{text}</strong> }}
      />
    </span>
  ),
  LANGUAGE_DISCLOSURE: (
    <span>
      <FormattedMessage
        defaultMessage='The Lyra website will be in English from this point forward.'
        description='Banner message disclosing that the Lyra website will be in English from this point forward'
      />
    </span>
  ),
  GOOGLE_ACCOUNT_UNRECOGNIZED: (
    <FormattedMessage
      defaultMessage='The account you selected isn’t connected to a Lyra profile. To sign-in, please try a different Google account.'
      description='Error message when user is logging in with a Google account which cannot be recognized'
    />
  ),
  EMAIL_REGISTRATION_METHOD_FAILED: (
    <FormattedMessage
      defaultMessage='Please use a different method to register or try again later.'
      description='Message to give suggestions on steps after not registering with Apple ID'
    />
  ),
}

const setHpiBannerYearlyResetDate = (sessionCountStartsOnDate: Date) => {
  let benefitResetDate = sessionCountStartsOnDate
  if (!isValid(benefitResetDate)) {
    benefitResetDate = new Date(`Jan-01-${new Date().getFullYear()}`)
  }

  const nextYearsBenefitResetDateFormatted = format(
    addYears(benefitResetDate, 1),
    YEARLY_HPI_BANNER_LOCALSTORAGE_DATE_FORMAT,
  )

  // Store the reset date as 1 year after the sessionCountStartsOn date
  localStorage.setItem(SHOW_YEARLY_HEALTH_PLAN_UPDATE, nextYearsBenefitResetDateFormatted)
}

const showYearlyHealthPlanUpdateBanner = (sessionCountStartsOnDate: Date): boolean => {
  const localStorageValue = localStorage.getItem(SHOW_YEARLY_HEALTH_PLAN_UPDATE)
  const parsedLocalStorageValue = parse(localStorageValue || '', YEARLY_HPI_BANNER_LOCALSTORAGE_DATE_FORMAT, new Date())

  if (!localStorageValue || !isValid(parsedLocalStorageValue)) {
    setHpiBannerYearlyResetDate(sessionCountStartsOnDate)

    // This check is for backwards compatibility of past implementation where we stored 'false'
    if (localStorageValue === 'false') {
      return false
    } else {
      return true
    }
  }

  // If 1 year or more has passed, we should reset the localStorage to a future date
  if (isAfter(new Date(), parsedLocalStorageValue)) {
    setHpiBannerYearlyResetDate(sessionCountStartsOnDate)
    return true
  } else {
    return false
  }
}

export const CLOSE_BANNER = 'CLOSE_BANNER'

export interface BannerDismissOptions {
  message: React.ReactNode
  shouldDismissEligibilityCheck?: boolean
  eapEligibilityBannerExpiration?: number
}

export const closeBanner = ({
  message,
  shouldDismissEligibilityCheck,
  eapEligibilityBannerExpiration = ELIGIBILITY_EXPIRATION_IN_MINS,
}: BannerDismissOptions) => {
  return (dispatch: $TSFixMe, getState: $TSFixMe) => {
    const bannerMessage = getState().getIn(['banner', 'message'], '')
    const isBannerOpen = getState().getIn(['banner', 'isOpen'], false)
    const bannerAction = getState().getIn(['banner', 'bannerAction'], '')
    if (isBannerOpen && message && message === bannerMessage) {
      dispatch({ type: CLOSE_BANNER, message })
      if (bannerAction && shouldDismissEligibilityCheck) {
        // currently only eligibility banner has banner actions
        switch (bannerAction) {
          case SHOW_YEARLY_HEALTH_PLAN_UPDATE:
            dispatch(dismissEligibilityBanner())
            break
          case ELIGIBILITY_BANNER_SNOOZE_EXPIRATION:
            dispatch(dismissEligibilityBanner())
            // IMO this should store the date and time of the snooze, instead of the date of expiration
            // values written to localStorage are not easy to change
            // the expiration can be caclulated when deciding whether the banner should be shown in `showEligibilityBanner`
            localStorage.setItem(
              ELIGIBILITY_BANNER_SNOOZE_EXPIRATION,
              formatISO(add(new Date(), { minutes: eapEligibilityBannerExpiration })),
            )
            break
          default:
          // do nothing
        }
      }
    }
  }
}

export const SET_BANNER_TYPE_AND_MESSAGE = 'SET_BANNER_TYPE_AND_MESSAGE'

// @ts-expect-error TS(7006): Parameter 'bannerType' implicitly has an 'any' typ... Remove this comment to see the full error message
const setBannerTypeAndMessage = (bannerType, message) => ({
  type: SET_BANNER_TYPE_AND_MESSAGE,
  bannerType,
  message,
})

function handleAutoDismissBanner(shouldAutomaticallyClose: $TSFixMe, dispatch: $TSFixMe, message: $TSFixMe) {
  if (shouldAutomaticallyClose) {
    setTimeout(function () {
      dispatch(closeBanner({ message }))
    }, 5000)
  }
}

export function setBannerPropsAndStatus(bannerType: $TSFixMe, message: $TSFixMe, shouldAutomaticallyClose = false) {
  return function (dispatch: $TSFixMe) {
    document.body.scrollTop = document.documentElement.scrollTop = 0
    dispatch(setBannerTypeAndMessage(bannerType, message))

    handleAutoDismissBanner(shouldAutomaticallyClose, dispatch, message)
  }
}

function shouldBypassBanner(statusCode: number | undefined) {
  // Bypass auth banner for Essentials deeplinks
  return window.location.pathname.includes(PAGE_ROUTES.ESSENTIALS) && statusCode === 401
}

export type LWAPIError = AxiosError<{ message?: string; errorCode?: LW_API_ERROR_CODE }>

export function setBannerAndErrorMessage(
  error: LWAPIError,
  shouldAutomaticallyClose = false,
  errorCallback?: (error: any) => void,
) {
  return function (dispatch: $TSFixMe) {
    if (!error) {
      return
    }
    const errorMessageProps = buildAPIErrorMessageProps(error)

    if (shouldBypassBanner(errorMessageProps.statusCode)) {
      return
    }

    document.body.scrollTop = document.documentElement.scrollTop = 0

    const errorMessage = <APIErrorMessage {...errorMessageProps} />

    dispatch(setBannerTypeAndMessage(BANNER_TYPE.DANGER, errorMessage))
    handleAutoDismissBanner(shouldAutomaticallyClose, dispatch, errorMessage)

    if (errorCallback) {
      errorCallback(errorMessage)
    }
  }
}

const setCustomBannerProps = ({ bannerType, message, showCloseButton, bannerAction = '' }: $TSFixMe) => ({
  type: SET_BANNER_TYPE_AND_MESSAGE,
  bannerType,
  message,
  includeX: showCloseButton,
  bannerAction,
})

type EligibilityBannerData = {
  showEAPIneligibilityBanner: boolean
  eligibilityStatus: EligibilityStatus | undefined
  comingFromConfirmationForm: boolean
  customerName: string
}

export function setEligibilityBanner({
  showEAPIneligibilityBanner,
  eligibilityStatus = EligibilityStatus.INELIGIBLE,
  comingFromConfirmationForm,
  customerName,
}: EligibilityBannerData) {
  return function (dispatch: Dispatch) {
    if (eligibilityStatus === EligibilityStatus.ELIGIBLE && comingFromConfirmationForm) {
      dispatch(
        setCustomBannerProps({
          bannerType: BANNER_TYPE.SUCCESS,
          message: MESSAGE.ELIGIBILITY_ELIGIBLE,
          showCloseButton: true,
        }),
      )
    } else if (showEAPIneligibilityBanner || eligibilityStatus === EligibilityStatus.INELIGIBLE) {
      const message =
        customerName === CustomerName.WALMART ? MESSAGE.ELIGIBILITY_INELIGIBLE_WALMART : MESSAGE.ELIGIBILITY_INELIGIBLE

      dispatch(
        setCustomBannerProps({
          bannerType: BANNER_TYPE.WARNING,
          message,
          showCloseButton: true,
          bannerAction: ELIGIBILITY_BANNER_SNOOZE_EXPIRATION,
        }),
      )
    }
  }
}

export function setHPIBanner(sessionCountStartsOnDate: Date) {
  const showHealthPlanUpdateBanner: boolean = showYearlyHealthPlanUpdateBanner(sessionCountStartsOnDate)

  return function (dispatch: Dispatch) {
    if (showHealthPlanUpdateBanner) {
      dispatch(
        setCustomBannerProps({
          bannerType: BANNER_TYPE.CALM,
          message: MESSAGE.CHECK_YEARLY_HEALTH_PLAN_UPDATE,
          showCloseButton: true,
          bannerAction: SHOW_YEARLY_HEALTH_PLAN_UPDATE,
        }),
      )
    }
  }
}

export function setAvailableAUDProviderBanner() {
  return function (dispatch: $TSFixMe) {
    dispatch(
      setCustomBannerProps({
        bannerType: BANNER_TYPE.CALM,
        message: MESSAGE.AUD_PROVIDER_FOUND,
        showCloseButton: true,
      }),
    )
  }
}

export function setLanguageDisclosure() {
  return function (dispatch: $TSFixMe) {
    dispatch(
      setCustomBannerProps({
        bannerType: BANNER_TYPE.CALM,
        message: MESSAGE.LANGUAGE_DISCLOSURE,
        showCloseButton: true,
      }),
    )
  }
}
