import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { MessageDescriptor, useIntl } from 'react-intl'
import { View } from 'react-native'

import { CountryCode } from 'libphonenumber-js'
import { isEmpty } from 'lodash-es'
import styled, { css, useTheme } from 'styled-components/native'

import {
  ActivityResponseData,
  Attestations,
  CountryValues,
  CustomerRedirection,
  CustomerRedirectionProps,
  DEFAULT_COUNTRY_ISO_CODE,
  ELIGIBILITY_TYPES,
  EligibilityInfo,
  extendMetadata,
  FormMetadata,
  FormStep,
  getEligibilityErrorMessage,
  PasswordPolicy,
  useLyraIntl,
  USER_MIN_AGE_REQUIREMENT,
  USER_MIN_AGE_REQUIREMENT_DEPRECATED,
} from '@lyrahealth-inc/shared-app-logic'

import { BasicInfoFormValues, useBasicInfoMetadata } from './basicInfoMetadata'
import { generateCustomerRedirectionMetadata } from './customerRedirectionMetadata'
import { generateLegalAgreementsMetadata } from './legalAgreementsMetadata'
import { Divider } from '../../atoms'
import { ButtonSize } from '../../atoms/baseButton/BaseButton'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { TextButton } from '../../atoms/textButton/TextButton'
import { AppContext } from '../../context'
import { EligibilityFormValues } from '../../formMetadata/eligibilityMetadata'
import { useEligibilityMetadata } from '../../hooks/useEligibilityMetadata'
import { getFontStyles } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { FormBody, FormBodyProps, FormButtonParams } from '../formBody/FormBody'
import { UICoreFormContext } from '../formBody/types'

const SubmitButtonContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['32px'],
}))

const Container = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  width: '100%',
  padding: theme.breakpoints.isMinWidthTablet ? '52px 32px 16px 32px' : '0 0 0 0',
  ...(theme.breakpoints.isMinWidthTablet && {
    borderRadius: 16,
    width: 624,
    border: `1px solid ${theme.colors.borderDefault}`,
  }),
}))

const StyledTextButton = styled(TextButton)<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'center',
  paddingVertical: theme.spacing['24px'],
}))

const DividerStyled = styled(Divider)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['32px'],
}))

export enum AgeCustomError {
  BELOW_MIN = 'BELOW_MIN_ERROR',
  ABOVE_MAX = 'ABOVE_MAX_ERROR',
  INVALID = 'INVALID_ERROR',
}

export type SetupAccountFormValues = EligibilityFormValues &
  BasicInfoFormValues & { customerRedirectionCheckbox?: boolean }

/**
 * A component that renders the Set Up Account Form.
 */
export interface SetUpAccountFormProps extends Pick<FormBodyProps, 'keyboardFormNavigationProps'> {
  eligibilityInfo: EligibilityInfo
  isCustomerInternational: boolean
  isInternationalRegistrationExperience: boolean
  customerCountryList?: CountryValues[]
  userCountryIsoCode?: CountryCode
  userCountryName?: string
  passwordPolicy?: PasswordPolicy
  isHealthPlanDirect?: boolean
  initialValues?: ActivityResponseData | Dict
  headerText?: MessageDescriptor | string
  subHeaderText?: MessageDescriptor | string
  getPrimaryButtonText: (showEligibilityTip: boolean, hasBlockingEligibilityCheck: boolean) => React.ReactNode
  getSecondaryButtonText: (
    showEligibilityTip: boolean,
    hasBlockingEligibilityCheck: boolean,
  ) => React.ReactNode | undefined
  saveForm: ({ values }: { values: SetupAccountFormValues }) => Promise<any>
  onPrimaryButtonPress?: (
    hasValidationError?: boolean,
    handleSubmit?: FormRenderProps['handleSubmit'],
  ) => void | Promise<any>
  onSecondaryButtonPress?: (
    hasValidationError?: boolean,
    handleSubmit?: FormRenderProps['handleSubmit'],
  ) => void | Promise<any>
  onCountryIsoSelected?: (countryIsoCode: CountryCode) => void
  updateFormValues?: (values: SetupAccountFormValues) => void
  shouldDisplayPasswordField?: boolean
  defaultInlineErrorBannerMessage?: React.ReactNode // error displayed by default before form submission
  inlineErrorBannerMessage?: React.ReactNode // error displayed after form submission
  inlineSuccessBannerMessage?: React.ReactNode
  customerRedirection?: (customerRedirectionProps: CustomerRedirectionProps) => CustomerRedirection
  customerRedirectionLinkPress?: () => void
  eligibilityCheckCount?: number
  hidePasswordChecklistOnBlur?: boolean
  formStep?: FormStep
  formContext?: Pick<UICoreFormContext, 'stringMessage' | 'onContactCNT'>
  hideNameField?: boolean
  hideDobField?: boolean
  hideCountrySelector?: boolean
  hideEligibilityInformation?: boolean
  hideDivider?: boolean
  hidePreferredName?: boolean
  attestationContents?: Attestations | null
  hideAttestations?: boolean
  testID?: string
}

export const SetUpAccountForm: FunctionComponent<SetUpAccountFormProps> = ({
  isCustomerInternational,
  isInternationalRegistrationExperience,
  customerCountryList,
  userCountryIsoCode,
  userCountryName,
  passwordPolicy,
  eligibilityInfo,
  isHealthPlanDirect,
  initialValues,
  headerText,
  subHeaderText,
  saveForm,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
  onCountryIsoSelected,
  updateFormValues,
  getPrimaryButtonText,
  getSecondaryButtonText,
  shouldDisplayPasswordField = true,
  defaultInlineErrorBannerMessage,
  inlineErrorBannerMessage,
  inlineSuccessBannerMessage,
  customerRedirection,
  customerRedirectionLinkPress,
  eligibilityCheckCount,
  hidePasswordChecklistOnBlur = true,
  formContext,
  hideNameField,
  hideDobField,
  hideCountrySelector,
  hideEligibilityInformation,
  hideDivider,
  hidePreferredName,
  attestationContents,
  hideAttestations = false,
  testID = 'setUpAccountForm',
  keyboardFormNavigationProps,
}) => {
  const intl = useIntl()
  const { activeLanguage } = useLyraIntl()
  const { formatMessage, locale } = intl
  const {
    breakpoints: { isMobileSized },
    colors,
  } = useTheme()
  const { isLctTeensEnabled, isPreferredNameEnabled } = useContext(AppContext)
  const minAgeRequirement = isLctTeensEnabled ? USER_MIN_AGE_REQUIREMENT : USER_MIN_AGE_REQUIREMENT_DEPRECATED
  const [showInternationalFormat, setShowInternationalFormat] = useState(false)
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [userMinAgeRequirement, setUserMinAgeRequirement] = useState(minAgeRequirement.DOMESTIC_EMPLOYEE)
  const [showUserAgeBelowMinWarningError, setShowUserAgeBelowMinWarningError] = useState(false)
  const [countryIsoCode, setCountryIsoCode] = useState(userCountryIsoCode || DEFAULT_COUNTRY_ISO_CODE)
  const [shouldShowErrorBanners, setShouldShowErrorBanners] = useState({ eligibility: false, basicInfo: false })
  const [customerRedirectionCheckboxChecked, setCustomerRedirectionCheckboxChecked] = useState(true)
  const showEligibilityTypeQuestion =
    (!isInternationalRegistrationExperience || eligibilityInfo.showEligibilityQuestionForInternationalRegistration) &&
    !isHealthPlanDirect

  const { eligibilityMetadata, customFields, setEligibilityType, eligibilityType } = useEligibilityMetadata({
    eligibilityInfo,
    showInternationalFormat,
    showEligibilityErrorBanner: shouldShowErrorBanners.eligibility,
  })
  let allCustomFields = customFields

  const showEligibilityTip = eligibilityInfo.showRegistrationTips && !isInternationalRegistrationExperience
  let employeeEligibilityError = undefined
  if (eligibilityType === ELIGIBILITY_TYPES.EMPLOYEE && eligibilityInfo.employeeEligibilityError !== undefined) {
    employeeEligibilityError = eligibilityInfo.employeeEligibilityError
  }

  useEffect(() => {
    setShowInternationalFormat(!!countryIsoCode && countryIsoCode !== DEFAULT_COUNTRY_ISO_CODE)
  }, [countryIsoCode])

  useEffect(() => {
    // Clear error banners
    setShouldShowErrorBanners({ eligibility: false, basicInfo: false })

    // Set age requirements
    if (eligibilityType === ELIGIBILITY_TYPES.DEPENDENT) {
      setUserMinAgeRequirement(
        showInternationalFormat ? minAgeRequirement.INTERNATIONAL_DEPENDENT : minAgeRequirement.DOMESTIC_DEPENDENT,
      )
    } else {
      setUserMinAgeRequirement(
        showInternationalFormat ? minAgeRequirement.INTERNATIONAL_EMPLOYEE : minAgeRequirement.DOMESTIC_EMPLOYEE,
      )
    }

    // Set error banners
    if (showEligibilityTip) {
      if (
        eligibilityType === ELIGIBILITY_TYPES.DEPENDENT &&
        !eligibilityInfo.checkAdultDependentEligibilityUsingDependentInfo
      ) {
        setShouldShowErrorBanners((prevState) => ({ ...prevState, eligibility: true }))
      } else {
        setShouldShowErrorBanners((prevState) => ({ ...prevState, basicInfo: true }))
      }
    }
  }, [
    eligibilityType,
    showInternationalFormat,
    showEligibilityTip,
    employeeEligibilityError,
    eligibilityInfo.checkAdultDependentEligibilityUsingDependentInfo,
    minAgeRequirement,
  ])

  const headerContent =
    headerText ||
    `${formatMessage({
      defaultMessage: "Let's set up your account",
      description: 'Registration form header for creating a new account',
    })}`

  const headerMetadata: FormMetadata = {
    schema: {
      type: 'object',
      properties: {
        header: {
          name: 'header',
          content: isMobileSized ? `### ${headerContent}` : `## ${headerContent}`,
          sectionHeader: true,
        },
        ...(subHeaderText && {
          subHeader: {
            name: 'subHeader',
            content: subHeaderText,
          },
        }),
      },
    },
    uiSchema: {
      'ui:order': ['header', 'subHeader'],
      header: {
        'ui:options': {
          center: true,
          customStyles: {
            h2: {
              marginBottom: 16,
            },
            h3: {
              marginTop: 24,
            },
          },
        },
      },
      subHeader: {
        'ui:options': {
          customStyles: {
            p2: {
              ...getFontStyles(colors).subhead.xsmall,
            },
          },
        },
      },
    },
  }

  const transformAndEvaluateErrors = (errors: Dict) => {
    if (errors.hasOwnProperty('userDOB') && errors.userDOB === AgeCustomError.BELOW_MIN) {
      setShowUserAgeBelowMinWarningError(true)
      errors.userDOB = ''
    } else {
      setShowUserAgeBelowMinWarningError(false)
    }
    return errors
  }

  const basicInfoFieldsMetadata = extendMetadata(
    headerMetadata,
    useBasicInfoMetadata({
      isCustomerInternational,
      showInternationalFormat,
      showBasicInfoErrorBanner: shouldShowErrorBanners.basicInfo,
      userMinAgeRequirement,
      intl,
      shouldDisplayPasswordField,
      showUserAgeBelowMinWarningError,
      hasFormSubmitted,
      colors,
      passwordPolicy,
      isMobileSized,
      hidePasswordChecklistOnBlur,
      hideNameField,
      hideDobField,
      hideCountrySelector,
      hasBlockingEligibilityCheck: eligibilityInfo.hasBlockingEligibilityCheck,
      isPreferredNameEnabled:
        isPreferredNameEnabled && !eligibilityInfo.isPristineConfirmEligibility && !hidePreferredName, // Don't show preferred name on Confirm Eligibility page
    }),
  )

  let metadata = basicInfoFieldsMetadata

  if (showEligibilityTypeQuestion && !hideEligibilityInformation) {
    metadata = extendMetadata(metadata, eligibilityMetadata)
  }

  if (customerRedirection && customerRedirectionLinkPress) {
    const customerRedirectionMetadata = generateCustomerRedirectionMetadata(
      customerRedirection?.({
        shouldShowBanner: (eligibilityCheckCount && eligibilityCheckCount > 1) || !customerRedirectionCheckboxChecked,
        onLinkPress: customerRedirectionLinkPress,
      }),
      intl.formatMessage,
    )
    metadata = extendMetadata(metadata, customerRedirectionMetadata)
  }

  if (!hideAttestations) {
    allCustomFields = { ...allCustomFields, ...{ Divider: () => <DividerStyled color={colors.dividerSecondary} /> } }
    const legalAgreementsMetadata = generateLegalAgreementsMetadata({
      intl,
      isInternationalRegistrationExperience,
      activeLanguage,
      attestationContents,
      hideDivider,
    })
    metadata = extendMetadata(metadata, legalAgreementsMetadata)
  }

  const { uiSchema, schema } = metadata

  const SubmitButton = ({ handleSubmit, loading, errors }: FormButtonParams) => {
    const submitButtonText = getPrimaryButtonText(!!showEligibilityTip, !!eligibilityInfo.hasBlockingEligibilityCheck)
    const secondaryButtonText = getSecondaryButtonText(
      !!showEligibilityTip,
      !!eligibilityInfo.hasBlockingEligibilityCheck,
    )

    const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState(false)
    const onPress = async () => {
      if (onPrimaryButtonPress) {
        setIsPrimaryButtonLoading(true)
        try {
          await onPrimaryButtonPress(!isEmpty(errors), handleSubmit)
        } finally {
          setIsPrimaryButtonLoading(false)
        }
      } else {
        await handleSubmit()
      }
      setHasFormSubmitted(true)
    }

    const [isSecondaryButtonLoading, setIsSecondaryButtonLoading] = useState(false)
    const handleSecondaryButtonOnPress = async () => {
      if (onSecondaryButtonPress) {
        setIsSecondaryButtonLoading(true)
        try {
          await onSecondaryButtonPress(!isEmpty(errors), handleSubmit)
        } finally {
          setIsSecondaryButtonLoading(false)
        }
      }
    }

    return (
      <SubmitButtonContainer
        style={{
          marginTop: (attestationContents && attestationContents.length > 0) || hideAttestations ? 0 : 32,
        }}
      >
        <PrimaryButton
          text={submitButtonText}
          onPress={onPress}
          testID={tID(`${testID}-primaryButton`)}
          disabled={loading || isPrimaryButtonLoading}
          loading={loading || isPrimaryButtonLoading}
          size={ButtonSize.LARGE}
          fullWidth={isMobileSized}
          style={{ ...(!isMobileSized && { alignSelf: 'center' }) }}
        />
        {secondaryButtonText && !loading && onSecondaryButtonPress && (
          <StyledTextButton
            text={secondaryButtonText}
            onPress={handleSecondaryButtonOnPress}
            testID={tID(`${testID}-textButton`)}
            loading={isSecondaryButtonLoading}
          />
        )}
      </SubmitButtonContainer>
    )
  }

  let errorBannerMessage = null
  if (!hasFormSubmitted && defaultInlineErrorBannerMessage) {
    errorBannerMessage = defaultInlineErrorBannerMessage
  } else if (inlineErrorBannerMessage) {
    errorBannerMessage = inlineErrorBannerMessage
  } else {
    errorBannerMessage = getEligibilityErrorMessage({
      formatMessage,
      eligibilityInfo,
      eligibilityType,
      isHealthPlanDirect,
      customEmployeeEligibilityError: employeeEligibilityError,
    })
  }

  const onFormChange = ({ values }: { values: SetupAccountFormValues }) => {
    const { countryIsoCode: selectedCountryIsoCode, eligibilityType } = values
    if (selectedCountryIsoCode) {
      setCountryIsoCode(selectedCountryIsoCode)
      onCountryIsoSelected?.(selectedCountryIsoCode)
    }
    if (eligibilityType) {
      setEligibilityType(eligibilityType)
    }
    if (
      typeof values.customerRedirectionCheckbox === 'boolean' &&
      customerRedirectionCheckboxChecked !== values.customerRedirectionCheckbox
    ) {
      setCustomerRedirectionCheckboxChecked(values.customerRedirectionCheckbox)
    }
    updateFormValues && updateFormValues(values)
  }

  const onSaveForm = async ({ values }: { values: SetupAccountFormValues }) => {
    return await saveForm({ values })
  }

  return (
    <Container testID={tID(testID)}>
      {!!schema && (
        <FormBody
          enableTranslations
          intl={intl}
          key={testID}
          name={testID}
          schema={schema}
          uiSchema={uiSchema}
          inlineErrorBannerMessage={errorBannerMessage}
          inlineSuccessBannerMessage={inlineSuccessBannerMessage}
          inlineWarningBannerMessage={errorBannerMessage}
          onFormChange={onFormChange}
          saveForm={onSaveForm}
          transformAndEvaluateErrors={transformAndEvaluateErrors}
          formButton={(props) => <SubmitButton {...props} />}
          customerCountryList={customerCountryList}
          userCountryIsoCode={userCountryIsoCode}
          userCountryName={userCountryName}
          passwordPolicy={passwordPolicy}
          showAsteriskForRequiredFields={false}
          checkPristineOnFormChange={false}
          locale={locale}
          formContext={formContext}
          formBodyCustomStyles={{
            submitButtonContainer: css`
              border: 0;
              border-top-width: 0;
              box-shadow: none;
              border-radius: 16px;
              padding-top: 0;
            `,
          }}
          scrollContainerCustomStyles={{
            formBodyPageContainer: css`
              border-radius: 16px;
              padding-top: 0px;
              padding-bottom: 0px;
            `,
          }}
          customFields={allCustomFields}
          initialValues={initialValues}
          inputAccessoryViewID={testID}
          returnKeyType='done'
          keyboardFormNavigationProps={keyboardFormNavigationProps}
        />
      )}
    </Container>
  )
}
