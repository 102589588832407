export enum ESSENTIALS_CATEGORY {
  PARENTING_SUPPORT = 'parenting-support',
  STRENGTHENING_RELATIONSHIPS = 'strengthening-relationships',
}

export enum ESSENTIALS_SUBCATEGORY {
  NEW_FRIENDS_AND_RELATIONSHIPS = 'new-friends-and-relationships',
}

export enum ESSENTIALS_COURSE {
  PARENTING_IN_THE_REAL_WORLD = 'parenting-in-the-real-world',
}

/** Callback function for pressing on essentials content */
export type OnEssentialsCardPressFunction = (
  title: string,
  subPath: string,
  contentFormat: string,
  contentType: string,
  destinationSlug: string,
  locale: string,
) => void

/** Article data returned from goodness apis */
export interface EssentialsArticleRaw {
  title: string
  uri: string
  image: { url: string }[]
  contentType: string
  readTime: string
  id: number
  contentFormat: string
}

/** Course data returned from goodness apis */
export interface EssentialsCourseRaw {
  title: string
  uri: string
  foreground: { url: string }[]
  id: number
  subcategories: { id: number; title: string }[]
}

/** Essentials content data from goodness apis after being processed and formatted */
export interface WellnessToolkitEssentialsContent {
  title: string
  imageUrl: string
  contentType: string
  readTime: string
  id?: number
  contentFormat: string
  categoryType?: SELF_CARE_CATEGORY_TYPE
  onPress: () => void
}

export type ToolkitEssentialsContentRaw = EssentialsArticleRaw | EssentialsCourseRaw

export enum WELLNESS_TOOLKIT_PROMOTION {
  WORK = 'work',
  PURPOSE = 'purpose',
  RELATIONSHIPS = 'relationships',
  PARENT = 'parent',
  FAMILY = 'family',
}

export enum SELF_CARE_CATEGORY_TYPE {
  TOPIC = 'topic',
  TOOL = 'tool',
  COURSE = 'course',
}
