import { Iterable } from 'immutable'
import { isEmpty } from 'lodash-es'
import SumoLogger from 'sumo-logger'

import { sanitizeData } from '@lyrahealth-inc/shared-app-logic'

import { sumologicConfig } from '../constants/appConstants'

export const LYRA_WEB_UI_SUMO_SOURCE_CATEGORY = 'lyraWebErrors'

export enum SUMO_CATEGORY {
  PROD_DEBUG = 'prod/lyraweb/debug',
  STAGING_DEBUG = 'staging/lyraweb/debug',
}

export const hasRole = (user: $TSFixMe, role: $TSFixMe) => {
  if (Iterable.isIterable(user)) {
    user = user.toJS()
  }
  if (!isEmpty(user) && !!user.roles) {
    let retVal = false

    for (let i = 0; i < user.roles.length; i++) {
      if (user.roles[i].role === role) {
        retVal = true
      }
    }

    return retVal
  }

  return false
}

const sumoLogger = new SumoLogger(sumologicConfig)
/**
 * Utility method to log a message in SumoLogic.
 * @param sourceCategory - the "source category" that this log statement should be associated with. This is a
 * metadata tag that can be used to quickly bucket messages of certain types with the query "_sourceCategory=(item)"
 * when searching through logs.
 * @param userId - the user ID that should be associated with the given log statement. If this is not specified,
 * the user's session ID will be the associated key.
 * @param data - an object containing the data to be logged. This can be either a String, representing the message to
 * be logged; or an Object, in valid JSON format, with key-value pairs to be logged. Some valid keys include:
 * * message - a String representing the message to be logged.
 * * type - a String representing the log level. Valid values for this can be: ['error', 'warning', 'info']
 */
export const logToSumoLogic = (sourceCategory: $TSFixMe, userId: $TSFixMe, data: $TSFixMe) => {
  let logData = {}
  if (data instanceof FormData) {
    ;(data as $TSFixMe).forEach((value: $TSFixMe, key: $TSFixMe) => {
      logData[key] = value
    })
  } else {
    logData = data
  }
  logData = sanitizeData(logData)
  ;(sumoLogger as $TSFixMe).updateConfig({ sourceCategory })
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  sumoLogger.log(logData, { sessionKey: userId })
}
