export const INTL_TREATMENT_RECOMMENDATIONS_PATH = 'international-treatment-recommendations'
export const ICAS_CHILD_PATH = 'adolescent-international-treatment-recommendations'

export const INTL_TREATMENT_RECOMMENDATIONS_ROUTE = `/secure/index/${INTL_TREATMENT_RECOMMENDATIONS_PATH}`
export const ICAS_CHILD_ROUTE = `/secure/index/${ICAS_CHILD_PATH}`

export const REDIRECT_URL_KEY = 'REDIRECT_URL'

export const NEGATIVE_PADDING_PATHS = [
  '/secure/onboard/t1-multi-providers',
  '/secure/onboard/t1-multi-providers/latest-provider-matches',
  '/secure/onboard/coaches',
  '/secure/onboard/coaches/latest-provider-matches',
  '/secure/onboard/provider/:id',
  '/secure/onboard/provider/:id/schedule',
  `/secure/index/${INTL_TREATMENT_RECOMMENDATIONS_PATH}`,
  '/secure/wellness/toolkit/:id',
]
