export const commonInputFieldBorderStyle = {
  inputContainerStyle: {
    borderRadius: 8,
  },
}

export const commonInputFieldStyling = (isMobileSized?: boolean, baseInputStyle?: React.CSSProperties) => {
  return {
    baseInputStyle: {
      marginBottom: isMobileSized ? 16 : 24,
      ...baseInputStyle,
    },
    selectFieldStyle: {
      borderRadius: 8,
    },
    ...commonInputFieldBorderStyle,
  }
}

export const pressableFieldStyling = (isMobileSized?: boolean, customStyle?: React.CSSProperties) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginTop: isMobileSized ? 8 : 0,
    marginBottom: 16,
    ...customStyle,
  }
}
