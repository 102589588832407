import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { BodyTextSize, Toast, toJS } from '@lyrahealth-inc/ui-core-crossplatform'

import { clearToastContent } from '../../../data/appGlobals/appGlobalsActions'
import { getToastId, getToastText, getToastTextSize, getToastType } from '../../../data/appGlobals/appGlobalsSelectors'

type ToastContanerProps = ConnectedProps<typeof connector>

const ToastContainer = ({ toastText, toastType, toastId, toastTextSize, clearToastContent }: ToastContanerProps) => {
  const showToast = toastText && toastId

  return (
    showToast && (
      <Toast
        text={toastText}
        toastType={toastType}
        id={toastId}
        textSize={toastTextSize ?? BodyTextSize.DEFAULT}
        onAnimationEnd={clearToastContent}
        delay={500}
      />
    )
  )
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    toastText: getToastText($$state),
    toastType: getToastType($$state),
    toastId: getToastId($$state),
    toastTextSize: getToastTextSize($$state),
  }
}

const mapDispatchToProps = {
  clearToastContent,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(toJS(ToastContainer))
