import { matchPath } from 'react-router'

import { utcToZonedTime } from 'date-fns-tz'
import { RequestOptions } from 'mixpanel-browser'

import {
  COMMON_BCX_SCREENS,
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  COMMON_SEARCH_FOR_CARE_SCREENS,
  getISO8601DateWithTimezone,
  S4C_PROPERTIES,
} from '@lyrahealth-inc/shared-app-logic'

import mixpanel from './mixpanel-browser'
import { APP_ID, APP_PLATFORM } from '../../common/constants/appConstants'
import { getOnboardUserFavoriteProviderRequestId } from '../../features/onboard/data/onboardSelectors'
import { getDeviceUUID, getShouldEmitMixpanelEventsForEnv } from '../appGlobals/appGlobalsSelectors'
import { getCustomerName } from '../customer/customerSelectors'
import { getId, getUserCountryCategory, getUserCountryName } from '../user/userSelectors'

export const MIXPANEL_PAGE = {
  ASSESSMENT_SUMMARY: 'Assessment Summary',
  MICROSITE: 'Microsite',
  UNSUBSCRIBE: 'Unsubscribe',
  EMAIL_VERIFICATION: 'Email Verification',
  REGISTRATION_CONNECT: 'Registration - Collect Email Address',
  REGISTRATION: 'Registration',
  CCBT_INTRO: 'CCBT_INTRO',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  PROVIDER_RESULTS: 'Provider Results',
  LOGIN: 'LOGIN',
  LOGGED_IN_HOME: 'Logged-In Home',
  PROFILE: 'Profile',
  EVENT_REGISTRATION: 'Event Registration',
  VIDEO_THERAPY_INTRO: 'Video Therapy Intro',
  VIDEO_CONTAINER: 'CA Handoff - Live Video',
  PROVIDER_SEARCH: 'CA Handoff - Provider Search',
  STRESS: 'Microsite - Stress',
  ANXIETY: 'Microsite - Anxiety',
  DEPRESSION: 'Microsite - Depression',
  WORKLIFE: 'Work-Life',
  APPOINTMENTS: 'Appointments',
  FAQ: 'FAQ',
  TREATMENT_OPTIONS: 'Treatment Options',
  COACHING: {
    BASE: 'Coaching',
    LOCATION: 'Coaching - Location',
    COACHING_SESSION_MODALITY: 'Coaching - Session Modality',
  },
  PROVIDER_RECOMMENDATIONS: 'Provider Recommendation Results',
  PROVIDER_CALENDAR: 'Provider Scheduling Calendar',
  PROVIDER_PROFILE: 'Provider Profile',
  APPOINTMENT_BOOKING: 'Appointment Booking',
  APPOINTMENT_REQUEST: 'Appointment Request',
  LOCATION: 'Location',
  AUD_UNAVAILABLE: 'AUD Unavailable',
  THERAPY_SELECTION: 'Therapy Selection',
  VERIFY_ACCOUNT: 'Verify Account',
  ASSIGNMENTS_HOME: 'Assignments Home',
  SESSIONS: 'Sessions',
  MESSAGES: 'Messages',
  LT_TUTORIAL: 'LT Tutorial',
  LT_PROGRESS: 'LT Progress',
  MEDS_OPTIONS_EXPLAINED: 'Meds Program Options',
  BHB_INELIGIBLE: 'BHB-ineligible',
  BHB_UNRESPONSIVE: 'BHB-unresponsive',
  CONTACT_CARE_TEAM: 'Contact Care Navigator Team',
  CONTACT_CARE_TEAM_CONFIRMATION: 'Contact Care Navigator Team - Confirmation',
  VIDEO_SESSION: 'video session',
  SATISFACTION_SURVEY: 'Satisfaction Survey',
  LCX_CLIENT_ONBOARDING: 'LCx client onboarding',
  HOME_BASE: 'homebase',
  TREATMENT_RECOMMENDATION: 'treatment recommendation',
  ZERO_RESULTS: 'zero results',
  ZERO_TREATMENT_RECOMMENDATIONS: 'zero treatment recommendations',
  CARE_PREFERENCES: 'care preferences',
  CARE_FOR_CHILD_SELECT_EXISTING: 'SearchCareForChild - select existing',
  TREATMENT_RECOMMENDATION_ASSERTIVE_TRIAGE: 'treatment recommendation with assertive triage',
  GENERAL_CN_CONTACT_PAGE: 'general CN contact page',
  APPOINTMENT_CONFLICT_MODAL: 'appointment conflict modal',
  APPOINTMENT_SELECTION: 'appointment selection',
  REBOOK: 'rebook',
}

export const MIXPANEL_PAGE_LOCATION = {
  CARE_NAVIGATOR_ENTRY_POINT: 'CN entrypoint',
  CARE_NAVIGATOR_ENTRY_POINT_INTERNATIONAL: 'international CN entrypoint',
  CARE_NAVIGATOR_ENTRY_POINT_ASSERTIVE_TRIAGE: 'assertive triage CN entrypoint',
  LANGUAGE_MODAL: 'language modal',
}

export const MIXPANEL_EVENTS = {
  PAGE_LOAD: 'Page Load',
  BUTTON_PRESS: 'Button Press',
  CHAT_COUNTRY_DETECTION_FAILURE: 'Chat country detection failure',
  STATUS: 'Status',
  FILTER_CHANGE: 'Filter Change',
  LIVE_MESSAGE_PRIMER: 'view live messaging primer',
  COACHING_SESSION_MODALITY: 'view LM vs LV coaching',
  VIEW_MOBILE_APP_PROMO: 'view lyra messaging promo',
  DISMISS_MOBILE_APP_PROMO: 'dismiss lyra messaging promo',
  GET_MOBILE_APP_PROMO: 'get lyra messaging app',
  SEND_MESSAGE: 'send message',
  VIEW_SATISFACTION_SURVEY: 'view satisfaction survey',
  SUBMIT_ADDITIONAL_SATISFACTION_SURVEY_INFO: 'submit additional satisfaction survey feedback',
  VIEW_MICROSITE: 'view microsite landing page',
  CHAT_LIVE: 'click chat live',
  FOOTER_CREATE_ACCOUNT: 'click create account',
  SELECT_PRIMARY_NEED: 'Select Primary Need',
  VIEW_EAP_HPI_HARD_CHECK: 'View EAP+HPI hard check',
  VIEW_HPI_HARD_CHECK: 'View HPI-only hard check',
  VIEW_HPI_INELIGIBLE: 'HPI-only ineligible',
  VIEW_EAP_HPI_INELIGIBLE: 'EAP+HPI ineligible',
  VIEW_HPI_PENDING: 'HPI-only unresponsive',
  VIEW_PROVIDER_CONTACT_INFO: 'view provider contact info',
  VIEW_ACTIVITIES_LIST: 'view activities list',
  CHOOSE_COUNTRY_FOR_ACCOUNT: 'Choose country for account',
  CLICK_DONT_SEE_YOUR_LANGUAGE: "Don't see your language",
  INITIAL_APPOINTMENT_BOOKING_COMPLETE: 'complete initial appointment booking',
  VIEW_CARE_NAVIGATOR_REQUEST_FORM_PAGE: 'view T1 CA consult request form',
  SUBMIT_CARE_NAVIGATOR_CONSULT_REQUEST: 'request T1 CA consult',
  CLICK_EMAIL_CARE_NAVIGATOR: 'click email cn',
  CLICK_CARE_NAVIGATOR_PHONE_NUMBER: 'click cn phone contact',
  CLICK_CARE_NAVIGATOR_EMAIL: 'click email contact',
  CLICK_CONTACT_CARE_NAVIGATOR: 'click to contact cn',
  PROFILE_WITH_EDITABLE_NAME_FIELDS_PAGE: 'Profile - With editable name fields page',
  LOCKED_PROFILE_WITH_EDITABLE_NAME_FIELDS_PAGE: 'Locked Profile - With editable name fields page',
  LOCKED_PROFILE_WITH_LOCKED_FIELDS_PAGE: 'Locked Profile - With locked fields page',
  LOCKED_PROFILE_NAME_CHANGE_PAGE: 'Locked Profile - Name change page',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_FOR_LIFE_EVENT: 'Locked Profile - Name Change - Click for life event',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_CARE_FOR_CHILD_DEPENDENT:
    'Locked Profile - Name Change - Click care for child dependent',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_CARE_FOR_SELF: 'Locked Profile - Name Change - Click care for self',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_CARE_FOR_ADULT_DEPENDENT:
    'Locked Profile - Name Change - Click care for adult dependent',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_REASON_NOT_LISTED_ON_PAGE:
    'Locked Profile - Name Change - Click reason not listed on page',
  OPEN_MESSAGE_PROVIDER_ALTERNATE_TIMES_MODAL: 'Alternate Times Prompt Button Clicked',
  DISMISS_MESSAGE_PROVIDER_ALTERNATE_TIMES_MODAL: 'Alternate Times Modal Dismiss Button Clicked',
  SEND_PROVIDER_ALTERNATE_TIMES: 'Alternate Times Modal Send Button Clicked',
  PAYMENT_DUE_INFRACTIONS_MODAL_SHOWN: 'Payment due infractions modal shown',
  PAYMENT_DUE_LEARN_MORE: 'Payment due learn more',
  PAYMENT_DUE_ADD_PAYMENT_METHOD: 'Payment due add payment method',
  PAYMENT_DUE_CLOSE_MODAL: 'Payment due close modal',
  INFRACTIONS_PAYMENT_ENTERED: 'Infractions payment entered',
  EXPERIMENT_STARTED: '$experiment_started',
}

export const MIXPANEL_ACTION = {
  NEW_SEARCH: 'New Search',
  CONTACT_US: 'Contact Us',
  CREATE_ACCOUNT: 'Create Account',
  VERIFY_ACCOUNT: 'Verify Account',
  SEND_EMAIL_VERIFICATION: 'Send Email Verification',
  ACCOUNT_VERIFICATION_SUCCESS: 'Account Verification Success',
  ACCOUNT_VERIFICATION_FAILED: 'Account Verification Failed',
  CONTINUE: 'Continue',
  CONTINUE_SUCCESS: 'Continue Success',
  CONTINUE_FAILED: 'Continue Failed',
  GO_TO_MY_STRENGTH: 'Go To My Strength',
  GO_TO_FAQ: 'Go To FAQ',
  CONTACT_CARE_TEAM: 'Contact Care Navigator Team',
  CONTACT_CARE_TEAM_FROM_AUD: 'contact care navigator team from AUD',
  CONTACT_LYRIANS_SUPPORT: 'Contact Lyrians Support',
  VIEW_WORKLIFE: 'View Worklife Services',
  SELECT_WORKLIFE: 'Select Worklife Service',
  UNSUBSCRIBE_OUTCOMES: 'Unsubscribe from assessment emails',
  /* ---------------NAV BAR----------------- */
  PROFILE: 'Profile',
  /* ---------------APPOINTMENT----------------- */
  ADD_TO_CALENDAR: 'Add To Calendar',
  EDIT_APPOINTMENT: 'Edit Appointment',
  CONFIRM_APPOINTMENT_CHANGE: 'Confirm Appointment Change',
  LIVE_VIDEO_SESSION: 'Live Video Session',
  PING_LYRA_THERAPY: 'ping lyra therapy to keep token alive',
  /* ---------------ONBOARD----------------- */
  PROVIDERS_MATCHED: 'Providers Matched',
  ZERO_PROVIDERS_MATCHED: 'Zero Providers Matched',
  ZERO_BIPOC_PROVIDERS_FOUND: 'zero provider results (BIPOC)',
  GO_TO_MYSTRENGTH: 'Go To Mystrength',
  GO_TO_DOD: 'Go to Doctor On Demand',
  NEXT: 'Next',
  SKIP: 'Skip',
  MENU: 'Menu',
  BACK: 'Back',
  ONSITE: 'Onsite',
  USER_SPECIFIED: 'User-Specified',
  END_STATE: 'End State',
  REMOVE_PROVIDER: 'Remove Provider',
  ADVANCED_SEARCH: 'Advanced Search',
  CCBT: 'CCBT',
  LIVE_VIDEO_THERAPY: 'Live Video Therapy',
  UNSUPPORTED_OPTION: 'Recommend Unsupported Medication Option',
  UNSUPPORTED_OPTION_ALTERNATIVE: 'Unsupported Medication - Select Additional Support Option',
  VIEW_LYRA_BENEFITS: 'Display Lyra Benefits Disclaimer',
  FIND_A_THERAPIST: 'Find a Therapist',
  REFILL_CONTROLLED_MEDS: 'Refill controlled medications',
  /* ------ONBOARD - STRESS MANAGEMENT------- */
  RECOMMEND_SMP: 'View SMP as the recommended option',
  FIND_A_STRESS_COACH: 'Find A Stress Coach',
  CLOSE_STRESS_EXPLANATION_MODAL: 'Close Stress Explanation Modal',
  GET_COACHES: 'Find My Stress Coach',
  LEARN_MORE: 'Learn More',
  BOOK_WITH_PROVIDER: 'Book',
  VIEW_FULL_PROFILE: 'View Full Profile',
  GO_BACK_TO_RECOMMENDATIONS: 'Back To Recommendations',
  VIEW_NEXT_CALENDAR: "View Next Coach's Calendar",
  SEND_DOWNLOAD_LINK: 'Send Download Link',
  BOOK_APPOINTMENT: 'Book Appointment',
  /* ---------PROVIDER RESULTS--------------- */
  SINGLE_PROVIDER: 'Single Provider',
  SUBMIT_EMAIL: 'Submit Email',
  MULTIPLE_PROVIDERS: 'Multiple Providers',
  NAVIGATOR: 'Navigator',
  VIEW_NEXT_AVAILABLE_APPOINTMENT: 'View Next Available Appointment',
  VIEW_SCHEDULE: 'View Schedule',
  COMPARE_AVAILABILITY: 'Compare Availability',
  CHECK_PROVIDER_AVAILABILITY: 'Check Provider Availability',
  DONE_CHECK_PROVIDER_AVAILABILITY: 'Done - Check Provider Availability',
  CHANGE_LOCATION: 'Change Location',
  GET_PROVIDER_CONTACT_DETAILS: 'Get Provider Contact Details',
  PROVIDER_DETAILS: 'Provider Details',
  SEE_ALL_MY_RECOMMENDATIONS: 'See All My Recommendations',
  VIEW_APPOINTMENT: 'View Appointment',
  CONTACT_FOR_AVAILABILITY: 'Contact for Availability',
  SEE_FULL_PROFILE: 'See Full Profile',
  /* ---------PROVIDER DETAILS---------------- */
  SEND_EMAIL: 'Send Email To Provider',
  SHOW_ADDITIONAL_PROVIDER_INFORMATION: 'Provider Profile - Show Additional Information',
  SHOW_PROVIDER_DETAILS: 'Provider Contact Card - Show Provider Details',
  /* ---------PROVIDER Schedule--------------- */
  SCHEDULE_APPOINTMENT: 'Schedule Appointment',
  CHANGE_DATE: 'Change Date',
  UPDATE_CONTACT: 'Update Contact',
  /* ---------------RESET PASSWORD----------------- */
  RESET_PASSWORD: 'Reset Password',
  RESET_PASSWORD_SUCCESS: 'Reset Password Success',
  RESET_PASSWORD_FAILED: 'Reset Password Failed',
  /* ---------------FORGOT PASSWORD----------------- */
  FORGOT_PASSWORD: 'Forgot Password',
  /* ---------------REQUEST RESET LINK----------------- */
  REQUEST_RESET_LINK: 'Request Reset Link',
  REQUEST_RESEND_RESET_LINK: 'Request Resend Reset Link',
  /* ---------------CONTACT MODAL----------------- */
  SUBMIT_CONTACT_FORM: 'Submit Contact Form',
  /* ---------------PROFILE----------------- */
  UPDATE_PROFILE_SUCCESS: 'Update Profile Success',
  UPDATE_PROFILE_FAILED: 'Update Profile Failed',
  EDIT_PROFILE_DATA_RETRIEVAL_FAILED: 'Profile Data Retrieval Failed',
  SUBMIT: 'Submit',
  /* ---------------FOOTER----------------- */
  CHAT: 'Chat',
  /* ---------------WORK-LIFE----------------- */
  GET_WORK_LIFE_SUPPORT: "Click 'Get Support' On Work-Life",
  /* ----------- FAQ --------------- */
  FAQ_PAGE_LOAD: 'FAQ page load',
  VIEW_FAQS: 'View FAQs',
  /* ----------- LYRA THERAPY --------------- */
  VIEW_ASSESSMENT: 'View Assessment',
  START_WORKSHEET: 'Start Worksheet',
  OPEN_WORKSHEET: 'Open Worksheet Overview',
  START_LESSON: 'Start Digital Lesson',
  CHANGE_SESSION_PERIOD: 'View Different Session Period',
  RESCHEDULE_SESSION: 'Reschedule Session',
  CANCEL_SESSION: 'Cancel Session',
  OPEN_ACTIVITY_ENTRY: 'Open Activity Entry from Message',
  SEND_MESSAGE: 'Send Message',
  CHECK_MESSAGES: 'Check for New Messages',
  OPEN_MESSAGE_URL: 'Open URL from Message',
  OPEN_GUIDE_LINK: 'open guide link',
  TOGGLE_MESSENGER: 'Toggle Messenger',
  INITIAL_APPOINTMENT_BOOKING: 'Initial Appointment Booking',
  // Contact Care Navigator
  CLICK_CARE_NAVIGATOR_CONTACT_OPTIONS: 'Click need something else',
}

export const MIXPANEL_USER_TYPE = {
  CLIENT: 'Client',
}

export const createScheduleTrackingData = ({ times, timeZone }: { times?: Array<string | null>; timeZone: string }) => {
  if (!times) {
    return {}
  }
  const availableDateTimes = times.filter((dateTime) => Boolean(dateTime))
  const calendarSlotsShown = availableDateTimes?.map((dateTime) => {
    if (!dateTime) {
      return
    }
    const date = utcToZonedTime(dateTime, timeZone)
    return getISO8601DateWithTimezone({
      date,
      timeZone,
    })
  })
  return {
    [S4C_PROPERTIES.NUM_CALENDAR_SLOTS]: availableDateTimes?.length,
    [S4C_PROPERTIES.CALENDAR_SLOTS_SHOWN]: calendarSlotsShown,
  }
}

let currentPage = ''

export function handleOnboardPageLoad(page: $TSFixMe, action = '') {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    // Fill in dispatch here
    // Handle on page load
    if (page) {
      currentPage = page
    }
    // Due to excessive Mixpanel event load, this flag defaults to False in non-prod environments
    const shouldEmitMixpanelEventsForEnv = getShouldEmitMixpanelEventsForEnv(getState())
    if (shouldEmitMixpanelEventsForEnv) {
      mixpanel.track(MIXPANEL_EVENTS.PAGE_LOAD, {
        page: page || currentPage,
        action,
        match_for: getState().getIn(['triageDemographics', 'matchFor']),
        matcher_request_id: getOnboardUserFavoriteProviderRequestId(getState()),
        country_category: getUserCountryCategory(getState()),
      })
    } else {
      console.log(
        'shouldEmitMixpanelEventsForEnv Feature Flag not set -- ' +
          MIXPANEL_EVENTS.PAGE_LOAD.toString() +
          ' mixpanel event NOT logged!',
      )
    }
  }
}

export function trackOnboardEvent({ event, action = '', page, ...rest }: MixpanelEvent, options?: RequestOptions) {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    // Due to excessive Mixpanel event load, this flag defaults to False in non-prod environments
    const shouldEmitMixpanelEventsForEnv = getShouldEmitMixpanelEventsForEnv(getState())
    if (shouldEmitMixpanelEventsForEnv) {
      const customerName = getCustomerName(getState())
      const eventData = {
        page,
        action,
        match_for: getState().getIn(['triageDemographics', 'matchFor'], ''),
        matcher_request_id: getOnboardUserFavoriteProviderRequestId(getState()),
        customer_id: customerName,
        country_category: getUserCountryCategory(getState()),
        ...rest,
      }

      const lyraId = getId(getState())
      ensureMixpanelIdentification(lyraId, customerName)

      mixpanel.track(event, eventData, options)
    }
  }
}

export function setMixpanelPage(page: $TSFixMe) {
  currentPage = page
}

export function initSuperProperties({ page, event, eventProps }: $TSFixMe) {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    const userId = getState().getIn(['user', 'id'], '')
    let mixpanelEvent = MIXPANEL_EVENTS.PAGE_LOAD
    if (page) {
      currentPage = page
    }

    // Setting the superProps does not currently work for mobile web for whatever reason.
    // We currently have a workaround for customer_id where we manually set customer_id for each event.
    const superProps = {
      ...(userId && {
        lyra_id: userId,
        customer_id: getCustomerName(getState()),
      }),
    }

    if (event) {
      mixpanelEvent = event
    }

    mixpanel.register(superProps)
    // Handle on page load
    const shouldEmitMixpanelEventsForEnv = getShouldEmitMixpanelEventsForEnv(getState())
    if (shouldEmitMixpanelEventsForEnv) {
      mixpanel.track(mixpanelEvent, { ...eventProps, page: currentPage })
    }
  }
}

export interface MixpanelEvent {
  [key: string]: any
  event: string
  action?: string
  page?: string
  options?: Dict
}

export function trackEventWithObj({ event, action = '', page, options, ...rest }: MixpanelEvent) {
  return function (dispatch: $TSFixMe, getState: $TSFixMe) {
    // Due to excessive Mixpanel event load, this flag defaults to False in non-prod environments
    const shouldEmitMixpanelEventsForEnv = getShouldEmitMixpanelEventsForEnv(getState())
    if (shouldEmitMixpanelEventsForEnv) {
      const customerId = getCustomerName(getState())
      const eventData = {
        page,
        action,
        match_for: getState().getIn?.(['triageDemographics', 'matchFor'], ''),
        customer_id: customerId,
        registered_country: getUserCountryName(getState()),
        country_category: getUserCountryCategory(getState()),
        [COMMON_MIXPANEL_PROPERTIES.LD_DEVICE_UUID]: getDeviceUUID(getState()),
        ...rest,
      }

      const lyraId = getId(getState())
      ensureMixpanelIdentification(lyraId, customerId)
      mixpanel.track(event, eventData, options)
    }
  }
}

function ensureMixpanelIdentification(lyraId: string | undefined, customerName: string) {
  if (lyraId) {
    // https://lyrahealth.atlassian.net/browse/LYRA-9308
    // Temporarially adding identify call here to attempt fixing the missing calls which causes mixpanel events to miss User ID property.
    // These calls should not trigger mixpanel API calls unless existing distinct_id is different from the id (lyraId) being set here.
    mixpanel.identify(lyraId)

    // register_once to make sure super props are set
    // it won't re-register if the prop value has not changed
    mixpanel.register_once({
      lyra_id: lyraId,
      customer_id: customerName,
    })
  } else {
    mixpanel.register_once({
      app_platform: APP_PLATFORM,
      app_id: APP_ID,
    })
  }
}

const ROUTE_TO_PAGE_MAP = {
  ['/secure/onboard/about-child/info']: COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_INFORMATION,
  ['/secure/onboard/about']: COMMON_SEARCH_FOR_CARE_SCREENS.TRIAGE,
  ['/secure/onboard/about/:id']: COMMON_SEARCH_FOR_CARE_SCREENS.TRIAGE,
  ['/secure/onboard/care-advocate-consultation/confirmation']: MIXPANEL_PAGE.CONTACT_CARE_TEAM_CONFIRMATION,
  ['/secure/onboard/care-navigator-options']: MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
  ['/secure/onboard/coaches']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_RESULTS,
  ['/secure/onboard/coaching/coach/:id']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_PROFILE,
  ['/secure/onboard/coaching/coaches']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_RESULTS,
  ['/secure/onboard/coaching/location']: COMMON_MIXPANEL_PAGE.LOCATION,
  ['/secure/onboard/contact-care-team']: MIXPANEL_PAGE.CONTACT_CARE_TEAM,
  ['/secure/onboard/contact-care-team/confirmation']: MIXPANEL_PAGE.CONTACT_CARE_TEAM_CONFIRMATION,
  ['/secure/onboard/match-location']: COMMON_MIXPANEL_PAGE.LOCATION,
  ['/secure/onboard/meds-options-explained']: COMMON_MIXPANEL_PAGE.MEDS_PROGRAM_OPTIONS,
  ['/secure/onboard/member-preferences']: COMMON_MIXPANEL_PAGE.RECOMMENDATIONS_EXPLAINED_PAGE,
  ['/secure/onboard/member-preferences/selection']: COMMON_MIXPANEL_PAGE.PREFERENCES_CATEGORY_PAGE,
  ['/secure/onboard/more-options']: COMMON_SEARCH_FOR_CARE_SCREENS.ALL_TREATMENT_OPTIONS,
  ['/secure/onboard/no-renew-providers']: COMMON_MIXPANEL_PAGE.AUD_UNAVAILABLE,
  ['/secure/onboard/provider']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_PROFILE,
  ['/secure/onboard/provider/:id']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_PROFILE,
  ['/secure/onboard/provider/:id/appointment']: COMMON_MIXPANEL_PAGE.APPOINTMENT_BOOKING,
  ['/secure/onboard/provider/:id/request']: COMMON_MIXPANEL_PAGE.APPOINTMENT_REQUEST,
  ['/secure/onboard/provider/:id/schedule']: COMMON_MIXPANEL_PAGE.PROVIDER_SCHEDULING_CALENDAR,
  ['/secure/onboard/t1-multi-providers']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_RESULTS,
  ['/secure/onboard/t1-provider/:id']: COMMON_SEARCH_FOR_CARE_SCREENS.PROVIDER_PROFILE,
  ['/secure/onboard/treatment-options']: COMMON_SEARCH_FOR_CARE_SCREENS.TREATMENT_RECOMMENDATIONS,
  ['/secure/index/search-care']: COMMON_MIXPANEL_PAGE.HOME_BASE,
  ['/secure/index/essentials']: COMMON_BCX_SCREENS.ESSENTIALS,
  ['/secure/index/assignments']: COMMON_MIXPANEL_PAGE.ASSIGNMENTS_HOME,
}

export function convertRouteToMixpanelPage(
  route: string,
  hasChildren = false,
): COMMON_SEARCH_FOR_CARE_SCREENS | COMMON_MIXPANEL_PAGE | string {
  // The about-child route can either be the child selection screen or the child information screen
  // depending on the number of children under this user.
  if (matchPath('secure/onboard/about-child', route)) {
    return hasChildren
      ? COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_SELECTION
      : COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_INFORMATION
  }

  for (const path of Object.keys(ROUTE_TO_PAGE_MAP)) {
    if (matchPath(path, route)) {
      return ROUTE_TO_PAGE_MAP[path]
    }
  }
  // if there are no matching pathes from the list above, just return the original route for logging
  return route
}
