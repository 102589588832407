export interface SearchForCareHomebaseHeaderExperimentJoinKeys {
  client_lyra_id: string
  device_uuid: string
  metric: SEARCH_FOR_CARE_HOMEBASE_METRICS
  description: string
  detected_country: string
  customer_name: string
}
export enum SEARCH_FOR_CARE_HOMEBASE_METRICS {
  VIEW_HOMEBASE = 'view homebase header',
}

export enum SEARCH_FOR_CARE_HOMEBASE_DESCRIPTIONS {
  HOMEBASE = 'When user lands on homebase',
}

export interface GenerateSearchForHeaderHomebaseExperimentPayloadParams {
  arm: string
  client_lyra_id: string
  metric: SEARCH_FOR_CARE_HOMEBASE_METRICS
  device_uuid: string
  customer_name: string
  description: SEARCH_FOR_CARE_HOMEBASE_DESCRIPTIONS
  detected_country: string
}
