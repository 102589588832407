import { IntlShape, MessageDescriptor } from 'react-intl'
import { ViewStyle } from 'react-native'

import { mapValues } from 'lodash-es'

import {
  ConfirmEligibilityRequest,
  convertDateStringFromISOToUSFormat,
  CustomerCopy,
  EAPEligibilityRequest,
  EAPEligibilityResponse,
  ELIGIBILITY_TYPES,
  EligibilityField,
  EligibilityInfo,
  EligibilityStatus,
  EligibilityType,
  extendMetadata,
  FieldSchema,
  FormMetadata,
  ThemeColors,
  UiMetadata,
  USER_MAX_AGE_REQUIREMENT,
  USER_MIN_AGE_REQUIREMENT,
  userInfoFieldLabels,
} from '@lyrahealth-inc/shared-app-logic'
import { formatMessageHelper } from '@lyrahealth-inc/shared-app-logic/src/features/intl'

import { RadioButtonType } from '../atoms'
import { IS_WEB } from '../constants'
import { commonInputFieldStyling } from '../organisms/formBody/sharedMetadata'
import { BasicInfoField, BasicInfoFormValues } from '../organisms/setUpAccountForm/basicInfoMetadata'
import { SetupAccountFormValues } from '../organisms/setUpAccountForm/SetUpAccountForm'

export type EligibilityFormValues = {
  eligibilityType?: EligibilityType
  employeeDOB?: string
  employeeFirstName?: string
  employeeLastName?: string
  employeeCustomerCodeField?: string
  dependentCustomerCodeField?: string
  dependentTypeId?: string
}

export const getDataFromFormForEAPEligibilityCheck = ({
  formValues,
}: {
  formValues: EligibilityFormValues & BasicInfoFormValues
}) => {
  const {
    userDOB,
    employeeDOB,
    userFirstName,
    userLastName,
    employeeFirstName,
    employeeLastName,
    eligibilityType,
    dependentTypeId,
  } = formValues

  // Depending on the employee or dependent type, the field name for the partner field changes
  let customerCode
  if (eligibilityType === ELIGIBILITY_TYPES.DEPENDENT) {
    customerCode = formValues[EligibilityField.DEPENDENT_CUSTOMER_CODE_FIELD_NAME]
  } else if (eligibilityType === ELIGIBILITY_TYPES.EMPLOYEE) {
    customerCode = formValues[EligibilityField.EMPLOYEE_CUSTOMER_CODE_FIELD_NAME]
  }

  return {
    dob: userDOB ? convertDateStringFromISOToUSFormat(userDOB) : undefined,
    customerCode,
    firstname: userFirstName ? userFirstName : undefined,
    lastname: userLastName ? userLastName : undefined,
    employeeFirstname: eligibilityType === ELIGIBILITY_TYPES.DEPENDENT ? employeeFirstName : undefined,
    employeeLastname: eligibilityType === ELIGIBILITY_TYPES.DEPENDENT ? employeeLastName : undefined,
    employeeDob:
      eligibilityType === ELIGIBILITY_TYPES.DEPENDENT && employeeDOB
        ? convertDateStringFromISOToUSFormat(employeeDOB) ?? undefined
        : undefined,
    eligibilityType: eligibilityType ?? 'employee',
    dependentTypeId,
  }
}

export const getDataFromFormForConfirmEAPEligibility = ({
  formValues,
  lyraId,
  customerName,
  customerCountryId,
  checkAdultDependentEligibilityUsingDependentInfo,
}: {
  lyraId: string
  customerName: string
  customerCountryId: number | undefined
  formValues: EligibilityFormValues & BasicInfoFormValues
  checkAdultDependentEligibilityUsingDependentInfo: boolean | undefined
}): ConfirmEligibilityRequest => {
  const { dob, customerCode, firstname, lastname, employeeFirstname, employeeLastname, employeeDob, eligibilityType } =
    getDataFromFormForEAPEligibilityCheck({ formValues })

  return {
    firstname: firstname || '',
    lastname: lastname || '',
    dob: dob || '',
    customerCode,
    employeeFirstname,
    employeeLastname,
    employeeDob,
    eligibilityType,
    lyraId,
    customerName,
    customerCountryId,
    checkAdultDependentEligibilityUsingDependentInfo,
  }
}

export interface EligibilityFormData {
  showEligibilityTips: boolean
  eligibilityStatus?: EligibilityStatus
  formSubmissionErrors?: { [key: string]: string }
  eligibilityMessage?: String
}

/**
 * Shared logic related to eligibility check in SetupAccount/Registration and Confirm Eligibility screens
 */
export async function getEligibilityFormData(
  customerName: string,
  customerCountryId: number | undefined,
  formValues: SetupAccountFormValues,
  generateEligibilityCheckSubmissionErrors: CustomerCopy['generateEligibilityCheckSubmissionErrors'],
  formatMessage: IntlShape['formatMessage'],
  fetchEAPEligibility: (request: EAPEligibilityRequest) => Promise<EAPEligibilityResponse>,
  checkAdultDependentEligibilityUsingDependentInfo = false,
  lyraId?: string | null,
): Promise<EligibilityFormData> {
  const eligibilityFormData: EligibilityFormData = {
    showEligibilityTips: false,
  }

  const eligibilityData = getDataFromFormForEAPEligibilityCheck({ formValues })
  const { eligibilityType } = eligibilityData
  const dataForEligibilityCheck: EAPEligibilityRequest = {
    ...eligibilityData,
    customerName,
    customerCountryId,
    lyraId: lyraId || null,
  }
  const eligibilityResponse = await fetchEAPEligibility(dataForEligibilityCheck)
  const eligibilityStatus = eligibilityResponse.eligibilityStatus
  eligibilityFormData.eligibilityStatus = eligibilityStatus
  eligibilityFormData.eligibilityMessage = eligibilityResponse.message

  const isEligible = eligibilityStatus === EligibilityStatus.ELIGIBLE

  if (!isEligible) {
    eligibilityFormData.showEligibilityTips = true
    eligibilityFormData.formSubmissionErrors = mapValues(
      generateEligibilityCheckSubmissionErrors?.(eligibilityType, checkAdultDependentEligibilityUsingDependentInfo),
      (message: MessageDescriptor) => formatMessage(message),
    )
  }

  return eligibilityFormData
}

export async function getAttestationEligibilityFormData(
  customerName: string,
  eligibilityInfo: EligibilityInfo,
  customerCountryId: number | undefined,
  formValues: EligibilityFormValues & BasicInfoFormValues,
  userFirstName: string,
  userLastName: string,
  userDOB: string | undefined,
  generateEligibilityCheckSubmissionErrors: CustomerCopy['generateEligibilityCheckSubmissionErrors'],
  formatMessage: IntlShape['formatMessage'],
  fetchEAPEligibility: (request: EAPEligibilityRequest) => Promise<EAPEligibilityResponse>,
) {
  const values: EligibilityFormValues & BasicInfoFormValues = {
    ...formValues,
    userFirstName,
    userLastName,
    userDOB,
  }

  if (eligibilityInfo.checkAdultDependentEligibilityUsingDependentInfo) {
    // Dependent's info are collected under the employee* field names from the form
    // when checkAdultDependentEligibilityUsingDependentInfo is enabled.
    // Overwriting user* fields using employee* here before using the data for eligibility checking.
    if (values.employeeFirstName) {
      values.userFirstName = values.employeeFirstName
      values.employeeFirstName = undefined
    }
    if (values.employeeLastName) {
      values.userLastName = values.employeeLastName
      values.employeeLastName = undefined
    }
    if (values.employeeDOB) {
      values.userDOB = values.employeeDOB
      values.employeeDOB = undefined
    }
  }

  return await getEligibilityFormData(
    customerName,
    customerCountryId,
    values,
    generateEligibilityCheckSubmissionErrors,
    formatMessage,
    fetchEAPEligibility,
  )
}

export const generateEligibilityTypeQuestions = (
  eligibilityInfo: EligibilityInfo,
  intl: IntlShape,
  baseInputStyle?: ViewStyle,
): FormMetadata => {
  const { hideDependentField, dependentRegistrationNomenclature } = eligibilityInfo

  return {
    schema: {
      type: 'object',
      properties: {
        [EligibilityField.ELIGIBILITY_TYPE]: {
          name: EligibilityField.ELIGIBILITY_TYPE,
          title: `#### ${formatMessageHelper(eligibilityInfo.eligibilityQuestionTitle, false, intl.formatMessage)}`,
          subTitle: eligibilityInfo.eligibilityQuestionSubtitle,
          subTitleComponent: eligibilityInfo.eligibilityQuestionSubtitleComponent,
          type: 'string',
          enum: [ELIGIBILITY_TYPES.EMPLOYEE, ELIGIBILITY_TYPES.DEPENDENT],
          enumNames: [
            intl.formatMessage({
              defaultMessage: 'Yes',
              description: 'Button text for user to declare they are an employee of the company',
            }),
            // Redundant code until we get rid of customer specific configs for eligibilityInfo.dependentButtonText and customer properties are set for them
            dependentRegistrationNomenclature
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      'No, I’m a dependent{dependentRegistrationNomenclature, select, householdMember { or household member} familyMember { or family member} spouseDomesticPartner {, spouse/domestic partner, or household member} other {}}',
                    description: 'Label for option declaring the current user is a dependent',
                  },
                  { dependentRegistrationNomenclature },
                )
              : eligibilityInfo.dependentButtonText ||
                intl.formatMessage({
                  defaultMessage: 'No, I’m a dependent',
                  description: 'Label for option declaring the current user is a dependent',
                }),
          ],
          buttonType: RadioButtonType.DESCRIPTION_NO_OUTLINE,
          validation: {
            isRequired: true,
          },
        },
      },
    },
    uiSchema: {
      'ui:order': [EligibilityField.ELIGIBILITY_TYPE],
      [EligibilityField.ELIGIBILITY_TYPE]: {
        'ui:widget': 'radio',
        'ui:options': {
          baseInputStyle: {
            marginBottom: hideDependentField ? 0 : 32,
            marginTop: 8,
            ...baseInputStyle,
          },
        },
      },
    },
  }
}

export const getDobUiSchema = (baseInputStyle?: React.CSSProperties): UiMetadata => {
  return {
    'ui:widget': IS_WEB ? 'datePickerSelect' : 'date',
    'ui:options': {
      baseInputStyle: {
        marginBottom: 32,
        ...baseInputStyle,
      },
      dateFieldDisplay: 'spinner',
      selectFieldStyle: {
        borderRadius: 8,
      },
      style: { borderRadius: 8 },
      ...(IS_WEB && { numYears: USER_MAX_AGE_REQUIREMENT.ADULT }),
    },
  }
}

export const getFullNameUiSchema = (isMobileSized?: boolean): UiMetadata[] => [
  {
    'ui:options': commonInputFieldStyling(isMobileSized),
  },
  {
    'ui:options': commonInputFieldStyling(isMobileSized),
  },
]

export const getEmployeeInformationFormMetadata = ({
  section1ItemsSchema,
  section1ItemsUiSchema,
  section2ItemsSchema,
  section2ItemsUiSchema,
  section3ItemsSchema,
  section3ItemsUiSchema,
  colors,
}: {
  section1ItemsSchema: FieldSchema[] // everything before firstname and lastname fields
  section1ItemsUiSchema: UiMetadata[]
  section2ItemsSchema: FieldSchema[] // firstname and lastname fields
  section2ItemsUiSchema: UiMetadata[]
  section3ItemsSchema: FieldSchema[] // everything after firstname and lastname fields
  section3ItemsUiSchema: UiMetadata[]
  colors: ThemeColors
}): FormMetadata => {
  return {
    schema: {
      type: 'object',
      properties: {
        [EligibilityField.EMPLOYEE_INFORMATION]: {
          type: 'array',
          condition: {
            parentField: EligibilityField.ELIGIBILITY_TYPE,
            parentValue: ELIGIBILITY_TYPES.DEPENDENT,
          },
          style: { marginBottom: 0 },
          // items are sectioned into multiple arrays depending if they are inline or not. This will be rendered by fieldTemplates.tsx.
          items: [
            {
              name: `${EligibilityField.EMPLOYEE_INFORMATION}-1`,
              type: 'array',
              style: { marginBottom: 0 },
              items: section1ItemsSchema,
            },
            {
              name: `${EligibilityField.EMPLOYEE_INFORMATION}-2`,
              type: 'array',
              inline: true,
              spaceBetweenFields: 16,
              items: section2ItemsSchema,
            },
            {
              name: `${EligibilityField.EMPLOYEE_INFORMATION}-3`,
              type: 'array',
              style: { marginBottom: 0 },
              items: section3ItemsSchema,
            },
          ],
        },
      },
    },
    uiSchema: {
      'ui:order': [EligibilityField.EMPLOYEE_INFORMATION],
      [EligibilityField.EMPLOYEE_INFORMATION]: {
        'ui:options': {
          backgroundColor: colors.backgroundSecondary,
          padding: 24,
          border: `1px solid ${colors.borderDefault}`,
          borderRadius: 8,
          marginBottom: 32,
        },
        items: [
          {
            items: section1ItemsUiSchema,
          },
          {
            items: section2ItemsUiSchema,
          },
          {
            items: section3ItemsUiSchema,
          },
        ],
      },
    },
  }
}

const eligibilityMetadata = (
  showInternationalFormat: boolean,
  hideDependentField: boolean,
  intl: IntlShape,
  eligibilityInfo: EligibilityInfo,
  colors: ThemeColors,
  employeeRegistrationNomenclature?: string,
  hideEmployeeInfo?: boolean,
  showEligibilityErrorBanner = false,
  isMobileSized?: boolean,
): FormMetadata => {
  const { isAttestation, checkAdultDependentEligibilityUsingDependentInfo, hasBlockingEligibilityCheck } =
    eligibilityInfo
  const eligibilityDependentTitle = !checkAdultDependentEligibilityUsingDependentInfo
    ? eligibilityInfo.eligibilityDependentTitle
    : null

  const userFirstNameTitle = intl.formatMessage(userInfoFieldLabels.FIRST_NAME, { showInternationalFormat })

  const userLastNameTitle = intl.formatMessage(userInfoFieldLabels.LAST_NAME, { showInternationalFormat })

  const showDependentFields = !hideDependentField || hideEmployeeInfo === true

  const showEligibilityErrorAsWarning = !hasBlockingEligibilityCheck

  const getNameFieldsForEmployee = (): FieldSchema[] => {
    // the order of the name fields should be flipped when user is international (last name, first name)
    // vs domestic (first name, last name)
    const items: FieldSchema[] = [
      {
        name: BasicInfoField.USER_FIRST_NAME,
        title: userFirstNameTitle,
        type: 'string',
        validation: {
          isRequired: true,
          isValidFirstName: { showInternationalFormat },
        },
        condition: {
          parentField: EligibilityField.ELIGIBILITY_TYPE,
          parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
        },
        widgetProps: {
          showErrorAsWarning: showEligibilityErrorAsWarning,
        },
      },
      {
        name: BasicInfoField.USER_LAST_NAME,
        title: userLastNameTitle,
        type: 'string',
        validation: {
          isRequired: true,
          isValidLastName: { showInternationalFormat },
        },
        condition: {
          parentField: EligibilityField.ELIGIBILITY_TYPE,
          parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
        },
        widgetProps: {
          showErrorAsWarning: showEligibilityErrorAsWarning,
        },
      },
    ]
    return showInternationalFormat ? items.reverse() : items
  }

  const getNameFieldsForDependent = (): FieldSchema[] => {
    let firstNameTitle
    if (checkAdultDependentEligibilityUsingDependentInfo) {
      firstNameTitle = userFirstNameTitle
    } else if (showInternationalFormat) {
      firstNameTitle = intl.formatMessage({
        defaultMessage: 'Their given name',
        description: 'Label text for first name form input',
      })
    } else {
      firstNameTitle = intl.formatMessage({
        defaultMessage: 'First name',
        description: 'Label text for first name of employee',
      })
    }

    let lastNameTitle
    if (checkAdultDependentEligibilityUsingDependentInfo) {
      lastNameTitle = userLastNameTitle
    } else if (showInternationalFormat) {
      lastNameTitle = intl.formatMessage({
        defaultMessage: 'Their surname / family name',
        description: 'Label text for last name form input',
      })
    } else {
      lastNameTitle = intl.formatMessage({
        defaultMessage: 'Last name',
        description: 'Label text for last name of employee',
      })
    }

    // the order of the name fields should be flipped when user is international (last name, first name)
    // vs domestic (first name, last name)
    const items: FieldSchema[] = [
      {
        name: EligibilityField.EMPLOYEE_FIRST_NAME,
        show: showDependentFields,
        title: firstNameTitle,
        type: 'string',
        validation: {
          isRequired: true,
          isValidFirstName: { showInternationalFormat },
        },
        condition: {
          parentField: EligibilityField.ELIGIBILITY_TYPE,
          parentValue: ELIGIBILITY_TYPES.DEPENDENT,
        },
        widgetProps: {
          showErrorAsWarning: showEligibilityErrorAsWarning,
        },
      },
      {
        name: EligibilityField.EMPLOYEE_LAST_NAME,
        show: showDependentFields,
        title: lastNameTitle,
        type: 'string',
        validation: {
          isRequired: true,
          isValidLastName: { showInternationalFormat },
        },
        condition: {
          parentField: EligibilityField.ELIGIBILITY_TYPE,
          parentValue: ELIGIBILITY_TYPES.DEPENDENT,
        },
        widgetProps: {
          showErrorAsWarning: showEligibilityErrorAsWarning,
        },
      },
    ]
    return showInternationalFormat ? items.reverse() : items
  }

  const getDobFieldForEmployee = (): { [key: string]: FieldSchema } => {
    const fieldName = BasicInfoField.USER_DATE_OF_BIRTH

    return {
      [fieldName]: {
        name: fieldName,
        title: intl.formatMessage({
          defaultMessage: 'Your date of birth',
          description: 'Date of birth field label',
        }),
        placeholder: '',
        type: 'string',
        condition: {
          parentField: EligibilityField.ELIGIBILITY_TYPE,
          parentValue: ELIGIBILITY_TYPES.EMPLOYEE,
        },
        validation: {
          isRequired: true,
        },
        widgetProps: {
          showErrorAsWarning: showEligibilityErrorAsWarning,
        },
      },
    }
  }

  const getDobFieldForDependent = (): FieldSchema => {
    let title
    if (checkAdultDependentEligibilityUsingDependentInfo) {
      title = intl.formatMessage({
        defaultMessage: 'Your date of birth',
        description: 'Date of birth field label',
      })
    } else if (showInternationalFormat) {
      title = intl.formatMessage({
        defaultMessage: 'Their date of birth',
        description: 'International date of birth field label',
      })
    } else {
      title = intl.formatMessage({
        defaultMessage: 'Date of birth',
        description: `Label text for employee's date of birth`,
      })
    }

    return {
      name: EligibilityField.EMPLOYEE_DATE_OF_BIRTH,
      show: showDependentFields,
      title,
      type: 'string',
      placeholder: '',
      condition: {
        parentField: EligibilityField.ELIGIBILITY_TYPE,
        parentValue: ELIGIBILITY_TYPES.DEPENDENT,
      },
      validation: {
        isRequired: true,
        isValidAge: {
          min: showInternationalFormat
            ? USER_MIN_AGE_REQUIREMENT.INTERNATIONAL_EMPLOYEE
            : USER_MIN_AGE_REQUIREMENT.DOMESTIC_EMPLOYEE,
          max: USER_MAX_AGE_REQUIREMENT.ADULT,
        },
      },
      widgetProps: {
        showErrorAsWarning: showEligibilityErrorAsWarning,
      },
    }
  }

  if (isAttestation) {
    return {
      schema: {
        type: 'object',
        properties: {
          ...(eligibilityDependentTitle && {
            [EligibilityField.DEPENDENT_HEADER]: {
              name: EligibilityField.DEPENDENT_HEADER,
              content: `#### ${formatMessageHelper(eligibilityDependentTitle, false, intl.formatMessage)}`,
              show: showDependentFields,
              condition: {
                parentField: EligibilityField.ELIGIBILITY_TYPE,
                parentValue: ELIGIBILITY_TYPES.DEPENDENT,
              },
            },
            [EligibilityField.ELIGIBILITY_ERROR_BANNER]: {
              name: EligibilityField.ELIGIBILITY_ERROR_BANNER,
              type: 'string',
              show: showEligibilityErrorBanner,
            },
          }),
          // employee's basic info fields for employee type, only shown when eligibility checking during verifying attestation
          ...(isAttestation && {
            [BasicInfoField.USER_FULL_NAME]: {
              type: 'array',
              inline: true,
              spaceBetweenFields: 16,
              items: getNameFieldsForEmployee(),
            },
            ...getDobFieldForEmployee(),
          }),
          // dependent's basic info fields for dependent type
          [EligibilityField.EMPLOYEE_FULL_NAME]: {
            type: 'array',
            inline: true,
            spaceBetweenFields: 16,
            items: getNameFieldsForDependent(),
          },
          [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: getDobFieldForDependent(),
          [EligibilityField.DEPENDENT_INELIGIBLE]: {
            name: EligibilityField.DEPENDENT_INELIGIBLE,
            show: hideDependentField,
            content: intl.formatMessage({
              defaultMessage:
                'You are not eligible for this benefit. For eligibility rules, please refer to the [FAQs](/faq).',
              description: 'Text for informing ineligible users during account registration',
            }),
            condition: {
              parentField: EligibilityField.ELIGIBILITY_TYPE,
              parentValue: ELIGIBILITY_TYPES.DEPENDENT,
            },
          },
        },
      },
      uiSchema: {
        'ui:order': [
          EligibilityField.DEPENDENT_HEADER,
          EligibilityField.ELIGIBILITY_ERROR_BANNER,
          ...(isAttestation ? [BasicInfoField.USER_FULL_NAME, BasicInfoField.USER_DATE_OF_BIRTH] : []),
          EligibilityField.EMPLOYEE_FULL_NAME,
          EligibilityField.EMPLOYEE_DATE_OF_BIRTH,
          EligibilityField.DEPENDENT_INELIGIBLE,
        ],
        ...(isAttestation && {
          [BasicInfoField.USER_FULL_NAME]: { items: getFullNameUiSchema(isMobileSized) },
          [BasicInfoField.USER_DATE_OF_BIRTH]: getDobUiSchema(),
        }),
        [EligibilityField.EMPLOYEE_DATE_OF_BIRTH]: getDobUiSchema(),
        [EligibilityField.ELIGIBILITY_ERROR_BANNER]: { 'ui:widget': 'inlineErrorBanner' },
        [EligibilityField.EMPLOYEE_FULL_NAME]: { items: getFullNameUiSchema(isMobileSized) },
      },
    }
  }

  return getEmployeeInformationFormMetadata({
    section1ItemsSchema: eligibilityDependentTitle
      ? [
          {
            name: EligibilityField.DEPENDENT_HEADER,
            content: `###### ${formatMessageHelper(eligibilityDependentTitle, false, intl.formatMessage)}`,
            show: showDependentFields,
            condition: {
              parentField: EligibilityField.ELIGIBILITY_TYPE,
              parentValue: ELIGIBILITY_TYPES.DEPENDENT,
            },
          },
          {
            name: EligibilityField.ELIGIBILITY_ERROR_BANNER,
            type: 'string',
            show: showEligibilityErrorBanner,
          },
        ]
      : [],
    section1ItemsUiSchema: [
      {},
      { 'ui:widget': showEligibilityErrorAsWarning ? 'inlineWarningBanner' : 'inlineErrorBanner' },
    ],
    section2ItemsSchema: getNameFieldsForDependent(),
    section2ItemsUiSchema: getFullNameUiSchema(isMobileSized),
    section3ItemsSchema: [
      getDobFieldForDependent(),
      {
        name: EligibilityField.DEPENDENT_INELIGIBLE,
        show: hideDependentField,
        content: intl.formatMessage({
          defaultMessage:
            'You are not eligible for this benefit. For eligibility rules, please refer to the [FAQs](/faq).',
          description: 'Text for informing ineligible users during account registration',
        }),
        condition: {
          parentField: EligibilityField.ELIGIBILITY_TYPE,
          parentValue: ELIGIBILITY_TYPES.DEPENDENT,
        },
      },
    ],
    section3ItemsUiSchema: [getDobUiSchema({ marginBottom: 0 }), {}],
    colors,
  })
}

export const generateEligibilityMetadata = ({
  eligibilityInfo,
  showInternationalFormat,
  intl,
  showEligibilityErrorBanner = false,
  isMobileSized,
  colors,
}: EligibilityMetadataProps): FormMetadata => {
  const { hideDependentField, employeeRegistrationNomenclature, hideEmployeeInfo } = eligibilityInfo
  const { isAttestation } = eligibilityInfo

  const eligibilityTypeQuestions = generateEligibilityTypeQuestions(eligibilityInfo, intl)
  if (eligibilityInfo?.checkAdultDependentEligibilityUsingDependentInfo && !isAttestation) {
    return eligibilityTypeQuestions
  } else {
    return extendMetadata(
      eligibilityTypeQuestions,
      eligibilityMetadata(
        showInternationalFormat,
        !!hideDependentField,
        intl,
        eligibilityInfo,
        colors,
        employeeRegistrationNomenclature,
        hideEmployeeInfo,
        showEligibilityErrorBanner,
        isMobileSized,
      ),
    )
  }
}

export type EligibilityMetadataProps = {
  eligibilityInfo: EligibilityInfo
  showInternationalFormat: boolean
  intl: IntlShape
  showEligibilityErrorBanner?: boolean
  isMobileSized?: boolean
  colors: ThemeColors
}
