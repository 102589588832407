import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import { useTheme } from 'styled-components/native'

import { Link } from '../../atoms'
import { AlertBanner } from '../../molecules'
import { BodyTextSize } from '../../styles'
import { AlertBannerTypes } from '../../ui-models/alertBanner/AlertBanner'
import { tID } from '../../utils'

export interface ReferralBannerProps {
  onPress: () => void
}

export const ReferralBanner: FunctionComponent<ReferralBannerProps> = ({ onPress }) => {
  const { colors } = useTheme()
  return (
    <View testID={tID('ReferralBanner')}>
      <AlertBanner
        style={AlertBannerTypes.INFO}
        contents={
          <FormattedMessage
            defaultMessage='It’s May Mental Health month! Spread the word and <link>refer a family member or colleagues</link>.'
            description='Banner text in pathways landing page for referrals'
            values={{
              link: (text: string) => (
                <Link
                  testID={tID('ReferralBanner-link')}
                  onPress={onPress}
                  size={BodyTextSize.DEFAULT}
                  text={text}
                  color={colors.textPrimary}
                  underline
                />
              ),
            }}
          />
        }
        show
        includeX={false}
        textSize={BodyTextSize.DEFAULT}
        minHeight='84px'
      />
    </View>
  )
}
