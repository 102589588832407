import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { REGISTRATION_EVENTS } from '@lyrahealth-inc/shared-app-logic'
import { SsoButton } from '@lyrahealth-inc/ui-core-crossplatform'
import {
  SSO_BUTTON_MESSAGE_TYPE,
  SSO_BUTTON_TYPE,
} from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/ssoButton/SsoButton'

import { GET_APPLE_SIGN_UP_ERROR, GET_APPLE_SIGN_UP_TOKEN } from '../../common/constants/appConstants'
import { useAppleAuth } from '../../common/hooks/useAppleAuth'
import { logToSumoLogic } from '../../common/utils/userUtils'
import { getAppleSSOClientId } from '../../data/appGlobals/appGlobalsSelectors'
import { trackEventWithObj } from '../../data/mixpanel'
import { getId } from '../../data/user/userSelectors'

type AppleRegistrationContainerProps = ConnectedProps<typeof connector>

const AppleRegistrationContainer = ({
  appleSSOClientId,
  userId,
  trackEventWithObj,
}: AppleRegistrationContainerProps) => {
  const { launchAppleAuthSignUp, appleSsoReady } = useAppleAuth(appleSSOClientId)

  const onAppleSignUpButtonPress = async () => {
    trackEventWithObj({ event: REGISTRATION_EVENTS.CLICK_SIGN_UP_WITH_APPLE })
    if (appleSsoReady) {
      launchAppleAuthSignUp()
        .catch((error) => {
          logToSumoLogic('lyraWebErrors', userId, {
            message: `Failed to initialize Apple authentication for registration: ${error}`,
          })
          // Dispatch error banner in parent window
          window.top?.postMessage(GET_APPLE_SIGN_UP_ERROR, '*')
        })
        .then((response) => {
          if (response?.authorization?.id_token) {
            // Stores Apple JWT to be used for BE verification when registering
            window.top?.postMessage({ message: GET_APPLE_SIGN_UP_TOKEN, token: response.authorization.id_token }, '*')
          }
        })
    }
  }

  return (
    <SsoButton
      type={SSO_BUTTON_TYPE.APPLE}
      messageType={SSO_BUTTON_MESSAGE_TYPE.SIGN_UP}
      onPress={onAppleSignUpButtonPress}
      style={{ margin: 5 }}
    />
  )
}

const mapStateToProps = ($$state: any) => {
  return {
    appleSSOClientId: getAppleSSOClientId($$state),
    userId: getId($$state),
  }
}

const connector = connect(mapStateToProps, { trackEventWithObj })

export default connector(AppleRegistrationContainer)
