import { fromJS } from 'immutable'
import { merge, pick } from 'lodash-es'
import { AnyAction } from 'redux'
import { createReducer } from 'redux-immutablejs'

import { HealthPlanKeys, SecondaryHealthPlanKeys } from '@lyrahealth-inc/shared-app-logic'

import {
  GET_ELIGIBILITY_OPTIONS,
  GET_HEALTHPLAN_PAYMENT,
  GET_PAYMENT_HISTORY,
  GET_UNRESOLVED_INFRACTIONS,
  REMOVE_HEALTHPLAN_INFO,
  REMOVE_HEALTHPLAN_PAYMENT,
  REMOVE_INFRACTION_PAYMENT,
  SET_HEALTHPLAN_DATA,
  SET_PATIENT_DATA,
  START_HEALTHPLAN_AND_INFRACTION_CARD_SETUP,
  STOP_HEALTHPLAN_AND_INFRACTION_CARD_SETUP,
  SUBMIT_HEALTHPLAN_INFO,
  SUBMIT_HEALTHPLAN_PAYMENT,
  SUBMIT_SECONDARY_HEALTHPLAN_INFO,
  UPDATE_HEALTHPLAN_AND_INFRACTION_CARD_REQUEST_TYPE,
  UPDATE_HEALTHPLAN_PAYMENT,
} from './healthPlanActions'

const $$initialState = fromJS({
  cardLast4: null,
  infractionCardLast4: null,
  cardLast4Type: null,
  infractionCardLast4Type: null,
  HPAndInfractionCardReminderType: 'modal',
})

const hpInfoKeys = [
  'customerPropertiesMap.healthPlanExtensionEnabled', // customer enables using health plan
  'customerPropertiesMap.healthPlanSessionLimit', // eg - 25
  'customerPropertiesMap.healthPlanExtensionNotificationThreshold', // delta value subtracted from healthPlanSessionLimit to determine when to notify user
  'healthPlansList', // insurance plans for the customer
  'usedSessionCount', // number of EAP (free session) used
  'sessionHistory', // EAP session history
  'isUsingHealthPlan', // EAP (free session) vs HP
  'isEligibleForHealthPlanExtension', // healthPlanExtensionEnabled && (insurancePlan.isEligible || insurancePlan.isUnknown)
  'receiveHealthPlanPrompt', // should the client be prompted to enter HP info
]

const paymentKeys = [
  // 'healthPlanPaymentAccountToken',
  'cardLast4',
  'infractionCardLast4',
]

const userInfoKeys = ['user.username']

const healthPlanDataReducer = ($$state: $TSFixMe, action: $TSFixMe) => {
  let healthPlanData = pick(action.data, [
    ...hpInfoKeys,
    ...HealthPlanKeys,
    ...SecondaryHealthPlanKeys,
    ...paymentKeys,
    ...userInfoKeys,
  ])
  // flatten
  healthPlanData = merge(healthPlanData, healthPlanData.customerPropertiesMap)
  delete healthPlanData.customerPropertiesMap
  $$state = $$state.merge(fromJS(healthPlanData))
  return $$state
}

export default createReducer($$initialState, {
  [SET_HEALTHPLAN_DATA]: healthPlanDataReducer,
  [SET_PATIENT_DATA]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = healthPlanDataReducer($$state, action)
    $$state = $$state.set('isPatientDataFetched', true)
    return $$state
  },

  [START_HEALTHPLAN_AND_INFRACTION_CARD_SETUP]: ($$state: $TSFixMe) => {
    $$state = $$state.set('showConfirmationScreen', true)
    return $$state
  },
  [STOP_HEALTHPLAN_AND_INFRACTION_CARD_SETUP]: ($$state: $TSFixMe) => {
    $$state = $$state.set('showConfirmationScreen', false)
    return $$state
  },
  [UPDATE_HEALTHPLAN_AND_INFRACTION_CARD_REQUEST_TYPE]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('HPAndInfractionCardReminderType', action.val)
    return $$state
  },
  [SUBMIT_HEALTHPLAN_INFO]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const updatedInfo = pick(action.payload.data, HealthPlanKeys)

    $$state = $$state.merge(fromJS(updatedInfo))
    return $$state
  },
  [SUBMIT_SECONDARY_HEALTHPLAN_INFO]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const updatedInfo = pick(action.payload.data, SecondaryHealthPlanKeys)

    $$state = $$state.merge(fromJS(updatedInfo))
    return $$state
  },
  [REMOVE_HEALTHPLAN_INFO]: ($$state: $TSFixMe) => {
    $$state = [...HealthPlanKeys, ...SecondaryHealthPlanKeys].reduce((map, key) => map.delete(key), $$state)
    return $$state
  },
  [SUBMIT_HEALTHPLAN_PAYMENT]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const submittedInfo = pick(action.payload?.data, paymentKeys)

    $$state = $$state.merge(fromJS(submittedInfo))
    return $$state
  },
  [UPDATE_HEALTHPLAN_PAYMENT]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const updatedInfo = pick(action.payload?.data, paymentKeys)

    $$state = $$state.merge(fromJS(updatedInfo))
    return $$state
  },
  [REMOVE_HEALTHPLAN_PAYMENT]: ($$state: $TSFixMe) => {
    $$state = $$state.delete('cardLast4')
    $$state = $$state.delete('cardLast4Type')
    return $$state
  },
  [REMOVE_INFRACTION_PAYMENT]: ($$state: $TSFixMe) => {
    $$state = $$state.delete('infractionCardLast4')
    $$state = $$state.delete('infractionCardLast4Type')
    return $$state
  },
  [GET_PAYMENT_HISTORY]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('paymentHistory', fromJS(action.payload.data))
    return $$state
  },
  [GET_ELIGIBILITY_OPTIONS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('eligibilityOptions', fromJS(action.data))
    return $$state
  },
  [GET_UNRESOLVED_INFRACTIONS]: ($$state: Map<string, any>, action: AnyAction) => {
    $$state = $$state.set('unresolvedInfractions', fromJS(action.data))
    return $$state
  },
  [GET_HEALTHPLAN_PAYMENT]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const cards = action.payload.data
    cards?.forEach((card: $TSFixMe) => {
      if (card.infractionConsent && card.infractionCardLast4) {
        $$state = $$state.set('infractionCardLast4Type', card.paymentType)
        $$state = $$state.set('infractionCardLast4', card.infractionCardLast4)
        $$state = $$state.set('infractionCardBrand', card.brand)
      }
      if (card.costSharingCardLast4) {
        $$state = $$state.set('cardLast4Type', card.paymentType)
        $$state = $$state.set('cardLast4', card.costSharingCardLast4)
        $$state = $$state.set('cardBrand', card.brand)
      }
    })
    return $$state
  },
})
