import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { REHYDRATE } from 'redux-persist/constants'

import { FetchStatus } from '@lyrahealth-inc/shared-app-logic'
import { ToastContent } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  CLEAR_TOAST_CONTENT,
  SET_APP_CONSTANTS_ON_PAGELOAD,
  SET_BYPASS_COMING_SOON_BEFORE_LAUNCH,
  SET_DETECTED_COUNTRY,
  SET_DEVICE_UUID,
  SET_ENABLE_MIXPANEL_EVENTS_FOR_ENV,
  SET_FETCH_DETECTED_COUNTRY_REQUEST_STATUS,
  SET_HAS_VERIFIED_TO_NOT_REDIRECT,
  SET_SHOULD_SHOW_COOKIE_BANNER,
  SET_SHOULD_SHOW_COOKIE_SETTINGS,
  SET_TOAST_CONTENT,
} from './appGlobalsActions'

const initialState = fromJS({
  detectedCountryFetchStatus: FetchStatus.PENDING,
  bypassComingSoonBeforeLaunch: false,
})

export default createReducer(initialState, {
  [SET_APP_CONSTANTS_ON_PAGELOAD]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.merge(fromJS(action.val))
    return $$state
  },
  [REHYDRATE]: ($$state: $TSFixMe) => {
    $$state = $$state.set('isHydrated', true)
    return $$state
  },
  [SET_HAS_VERIFIED_TO_NOT_REDIRECT]: ($$state: $TSFixMe) => {
    return $$state.set('hasVerifiedToNotRedirect', true)
  },
  [SET_SHOULD_SHOW_COOKIE_BANNER]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('shouldShowCookieBanner', action.data)
  },
  [SET_SHOULD_SHOW_COOKIE_SETTINGS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('shouldShowCookieSettings', action.data)
  },
  [SET_DETECTED_COUNTRY]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('detectedCountry', fromJS(action.data))
  },
  [SET_FETCH_DETECTED_COUNTRY_REQUEST_STATUS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('detectedCountryFetchStatus', action.data)
  },
  [SET_ENABLE_MIXPANEL_EVENTS_FOR_ENV]: ($$state: $TSFixMe, action: $TSFixMe) => {
    return $$state.set('shouldEmitMixpanelEventsForEnv', action.enableMixpanelEventsForEnv)
  },
  [SET_TOAST_CONTENT]: ($$state: Map<string, any>, action: { content: ToastContent }) => {
    $$state = $$state.set('toastText', fromJS(action.content.text))
    $$state = $$state.set('toastType', fromJS(action.content.toastType))
    $$state = $$state.set('toastId', fromJS(action.content.id))
    $$state = $$state.set('toastTextSize', fromJS(action.content.textSize))
    return $$state
  },
  [CLEAR_TOAST_CONTENT]: ($$state: Map<string, any>) => {
    $$state = $$state.set('toastText', null)
    $$state = $$state.set('toastType', null)
    $$state = $$state.set('toastId', null)
    $$state = $$state.set('toastTextSize', null)
    return $$state
  },
  [SET_BYPASS_COMING_SOON_BEFORE_LAUNCH]: ($$state: $TSFixMe, action: { bypassComingSoonBeforeLaunch: boolean }) => {
    return $$state.set('bypassComingSoonBeforeLaunch', action.bypassComingSoonBeforeLaunch)
  },
  [SET_DEVICE_UUID]: ($$state: $TSFixMe, action: { data: string }) => {
    return $$state.set('deviceUUID', action.data)
  },
})
