import React, { FunctionComponent, useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ViewStyle } from 'react-native'
import Animated, { useAnimatedStyle, withSpring } from 'react-native-reanimated'

import { utcToZonedTime } from 'date-fns-tz'
import styled, { useTheme } from 'styled-components/native'

import { PATHWAYS_TYPE, registrationMessages } from '@lyrahealth-inc/shared-app-logic'

import {
  ArrowIcon,
  ArrowIconDirection,
  BodyText,
  Headline,
  PressableOpacity,
  PrimaryButton,
  ProviderWavingLottie,
  Subhead,
} from '../../../atoms'
import { AppContext } from '../../../context'
import { HeroSection } from '../../../molecules/heroSection/HeroSection'
import { ReferralBanner } from '../../../organisms'
import { BodyTextSize, HeadlineSize, SubheadSize } from '../../../styles'
import { ThemeType } from '../../../utils'
import { tID } from '../../../utils/utils'
import { PATHWAY_ILLUSTRATIONS, PathwayCard } from '../../molecules/pathwayCard/PathwayCard'

export interface PathwaysHomepageHeroSectionProps {
  pathwaysType?: PATHWAYS_TYPE
  onCreateAccountButtonPress?: () => void
  onExploreOnYourOwnCTAPress?: () => void
  onProviderLedCardPress?: () => void
  onSelfCareCardPress?: () => void
  onNotifyMeOnLaunchPress?: () => void
  startDate?: string
  onReferralBannerPress?: () => void
}

const AnimatedArrowIconContainer = styled(Animated.View)({
  alignItems: 'center',
})

const MainCTAContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['24px'] : theme.spacing['16px'],
  marginBottom: '120px',
  ...(theme.breakpoints.isMinWidthTablet &&
    !theme.breakpoints.isMinWidthDesktop && {
      marginBottom: theme.spacing['40px'],
    }),
  ...(theme.breakpoints.isMinWidthMobileXxs &&
    !theme.breakpoints.isMinWidthMobileXs && {
      marginBottom: theme.spacing['56px'],
    }),
}))

const MainCTAMultiPathwayContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['40px'] : theme.spacing['48px'],
}))

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['4px'],
}))

const ProviderIllustrationContainer = styled.View<{ theme: ThemeType; isTabletSized: boolean }>(
  ({ theme, isTabletSized }) => ({
    marginBottom: isTabletSized ? theme.spacing['16px'] : theme.spacing['24px'],
    ...(isTabletSized && { height: '116px' }),
  }),
)

const SubheaderContainer = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  textAlign: 'center',
}))

const ExploreOnYourOwnTextComponent = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  padding: `${theme.spacing['12px']} ${theme.spacing['24px']}`,
  textAlign: 'center',
}))

const CardContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginRight: theme.breakpoints.isMobileSized
    ? !theme.breakpoints.isMinWidthMobileXs
      ? theme.spacing['8px']
      : theme.spacing['16px']
    : theme.spacing['32px'],
}))

const PathwayCardsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing['40px'],
  ...(theme.breakpoints.isMinWidthTablet &&
    !theme.breakpoints.isMinWidthDesktop && {
      marginTop: theme.spacing['24px'],
    }),
}))

const ProviderWavingLottieContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMinWidthTablet &&
    !theme.breakpoints.isMinWidthLaptop && {
      marginBottom: theme.spacing['20px'],
    }),
}))

export const PathwaysHomepageHeroSection: FunctionComponent<PathwaysHomepageHeroSectionProps> = ({
  pathwaysType = PATHWAYS_TYPE.SINGLE,
  onCreateAccountButtonPress,
  onExploreOnYourOwnCTAPress,
  onProviderLedCardPress,
  onSelfCareCardPress,
  onNotifyMeOnLaunchPress,
  startDate,
  onReferralBannerPress,
}) => {
  const { isReferralBannerEnabled } = useContext(AppContext)
  const [isExploreOnYourOwnCTAHovered, setIsExploreOnYourOwnCTAHovered] = useState(false)
  const [isSinglePathwayLottieHovered, setIsSinglePathwayLottieHovered] = useState(false)
  const { colors, breakpoints, spacing } = useTheme()
  const isTabletSized = breakpoints.isMinWidthTablet && !breakpoints.isMinWidthLaptop
  const isMobileSized = breakpoints.isMobileSized
  const isComingSoon = pathwaysType === PATHWAYS_TYPE.COMING_SOON
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: isExploreOnYourOwnCTAHovered ? withSpring(12) : withSpring(0) }],
    }
  })
  const { formatDate, formatMessage } = useIntl()
  const isMultiplePathways = pathwaysType === PATHWAYS_TYPE.MULTIPLE

  const exploreOnYourOwnText = (
    <FormattedMessage defaultMessage='Explore on your own' description='call to action to see rest of microsite' />
  )

  const animatedArrowIcon = (
    <AnimatedArrowIconContainer style={animatedStyle}>
      <ArrowIcon size={24} fillColor={colors.primaryButtonBackgroundHover} direction={ArrowIconDirection.DOWN} />
    </AnimatedArrowIconContainer>
  )

  const RegistrationButtonContainer = isMultiplePathways ? MainCTAMultiPathwayContainer : MainCTAContainer
  /** One off button sizing for microsite only */
  const registrationButtonTextSize = isMultiplePathways && !isMobileSized ? BodyTextSize.LARGE : undefined
  const registrationButtonStyle = isMultiplePathways && !isMobileSized ? ({ height: 60 } as ViewStyle) : undefined

  const registrationButton = (
    <RegistrationButtonContainer>
      <PrimaryButton
        text={formatMessage(registrationMessages.GET_STARTED)}
        onPress={() => {
          onCreateAccountButtonPress && onCreateAccountButtonPress()
        }}
        testID={tID('HeroSection-registrationButton')}
        customTextSize={registrationButtonTextSize}
        style={registrationButtonStyle}
      />
    </RegistrationButtonContainer>
  )

  const exploreOnYourOwnCTASinglePathway = (
    <PressableOpacity
      hitSlop={12}
      onPress={() => {
        onExploreOnYourOwnCTAPress && onExploreOnYourOwnCTAPress()
      }}
      onHoverIn={() => {
        setIsExploreOnYourOwnCTAHovered(true)
      }}
      onHoverOut={() => {
        setIsExploreOnYourOwnCTAHovered(false)
      }}
      testID={tID('HeroSection-exploreOnYourOwnCTA')}
    >
      <ExploreOnYourOwnTextComponent
        text={exploreOnYourOwnText}
        size={BodyTextSize.LARGE}
        color={colors.primaryButtonBackgroundHover}
      />
      {animatedArrowIcon}
    </PressableOpacity>
  )

  const onNotifyMeOnLaunch = (
    <MainCTAContainer>
      <PrimaryButton
        text={
          <FormattedMessage
            defaultMessage='Notify me on launch'
            description='Button to notify user on customer launch'
          />
        }
        onPress={() => {
          onNotifyMeOnLaunchPress && onNotifyMeOnLaunchPress()
        }}
        testID={tID('HeroSection-notifyMeOnLaunchButton')}
      />
    </MainCTAContainer>
  )

  const singlePathwaySubheader = (
    <SubheaderContainer
      text={
        <FormattedMessage
          defaultMessage='Meet with world-class mental health experts and discover our on demand wellness resources.'
          description='Summary of Lyra offerings'
        />
      }
    />
  )

  const multiplePathwayCardSection = (
    <PathwayCardsContainer>
      <CardContainer>
        <PathwayCard
          illustration={PATHWAY_ILLUSTRATIONS.PROVIDER_LED_ILLUSTRATION}
          onPress={() => {
            onProviderLedCardPress && onProviderLedCardPress()
          }}
        />
      </CardContainer>
      <PathwayCard
        illustration={PATHWAY_ILLUSTRATIONS.SELF_CARE_ILLUSTRATION}
        onPress={() => {
          onSelfCareCardPress && onSelfCareCardPress()
        }}
      />
    </PathwayCardsContainer>
  )

  const headerText = isComingSoon ? (
    <FormattedMessage
      defaultMessage='Get ready to feel your best'
      description='Header text, that encourages user to sign up to be notified'
    />
  ) : (
    <FormattedMessage
      defaultMessage='Let’s help you feel your best'
      description='Header text, that encourages user to find care by creating a Lyra account'
    />
  )
  const Header = ({ children, testId }: { children: React.ReactNode; testId: string }) => {
    const testID = tID(testId)
    return breakpoints.isMobileSized ? (
      <Subhead
        testID={testID}
        size={testId === 'HeroSection-multiPathwaysHeader' ? SubheadSize.LARGE : SubheadSize.MEDIUM}
        textAlign='center'
      >
        {children}
      </Subhead>
    ) : (
      <Headline
        testID={testID}
        size={breakpoints.isMinWidthLaptop ? HeadlineSize.MEDIUM : HeadlineSize.SMALL}
        textAlign='center'
      >
        {children}
      </Headline>
    )
  }

  const pathwaysContent = {
    [PATHWAYS_TYPE.SINGLE]: {
      header: (
        <>
          <ProviderWavingLottieContainer>
            <PressableOpacity
              onHoverIn={() => {
                setIsSinglePathwayLottieHovered(true)
              }}
              onHoverOut={() => {
                setIsSinglePathwayLottieHovered(false)
              }}
            >
              <ProviderIllustrationContainer isTabletSized={isTabletSized}>
                <ProviderWavingLottie bgColor='white' play={isSinglePathwayLottieHovered} />
              </ProviderIllustrationContainer>
            </PressableOpacity>
          </ProviderWavingLottieContainer>
          <HeaderContainer>
            <Header testId={'HeroSection-singlePathwayHeader'}>{headerText}</Header>
          </HeaderContainer>
        </>
      ),
      subHeader: singlePathwaySubheader,
      ctaSection: (
        <>
          {registrationButton}
          {exploreOnYourOwnCTASinglePathway}
        </>
      ),
    },
    [PATHWAYS_TYPE.MULTIPLE]: {
      header: (
        <HeaderContainer>
          <Header testId={'HeroSection-multiPathwaysHeader'}>{headerText}</Header>
        </HeaderContainer>
      ),
      subHeader: <></>,
      ctaSection: (
        <>
          {multiplePathwayCardSection}
          {registrationButton}
        </>
      ),
    },
    [PATHWAYS_TYPE.COMING_SOON]: {
      header: (
        <>
          <ProviderWavingLottieContainer>
            <PressableOpacity
              onHoverIn={() => {
                setIsSinglePathwayLottieHovered(true)
              }}
              onHoverOut={() => {
                setIsSinglePathwayLottieHovered(false)
              }}
            >
              <ProviderIllustrationContainer isTabletSized={isTabletSized}>
                <ProviderWavingLottie bgColor='white' play={isSinglePathwayLottieHovered} />
              </ProviderIllustrationContainer>
            </PressableOpacity>
          </ProviderWavingLottieContainer>
          <Subhead
            size={SubheadSize.MEDIUM}
            text={
              <FormattedMessage
                defaultMessage='Launching {startDate}'
                description='Subhead text indicating when date customer launches'
                values={{
                  startDate: formatDate(startDate, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    timeZone: 'UTC',
                  }),
                }}
              />
            }
          />
          <HeaderContainer>
            <Header testId={'HeroSection-comingSoonPathwaysHeader'}>{headerText}</Header>
          </HeaderContainer>
        </>
      ),
      subHeader: singlePathwaySubheader,
      ctaSection: (
        <>
          {onNotifyMeOnLaunch}
          {exploreOnYourOwnCTASinglePathway}
        </>
      ),
    },
  }

  const { header, subHeader, ctaSection } = pathwaysContent[pathwaysType]

  const contentContainerStyle = {
    ...(breakpoints.isMobileSized && { paddingTop: spacing[pathwaysType === PATHWAYS_TYPE.SINGLE ? '48px' : '64px'] }),
    ...(!breakpoints.isMobileSized && { justifyContent: 'center' }),
    ...(!breakpoints.isMinWidthMobileXxs && {
      flex: 1,
    }),
  } as ViewStyle

  const referralBannerStartDate = utcToZonedTime('2024-05-09T00:00:00', 'America/Los_Angeles')
  const referralBannerEndDate = utcToZonedTime('2024-05-31T23:59:59', 'America/Los_Angeles')
  const currentDate = new Date()
  const showReferralBanner =
    isReferralBannerEnabled &&
    !!onReferralBannerPress &&
    currentDate >= referralBannerStartDate &&
    currentDate <= referralBannerEndDate

  return (
    <>
      <HeroSection
        testID={'HeroSection-pathwaysHomepage'}
        header={header}
        subHeader={subHeader}
        ctaSection={ctaSection}
        useStaticBackground={isComingSoon}
        contentContainerStyle={contentContainerStyle}
      />
      {showReferralBanner && <ReferralBanner onPress={onReferralBannerPress} />}
    </>
  )
}
