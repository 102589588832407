import { useCallback, useEffect } from 'react'

import { User } from '@lyrahealth-inc/shared-app-logic'

type SnapEngageParams = {
  user: User
  events: { onOnline: () => void; onOffline: () => void; onDisabled: () => void }
  hideCloseIcon?: boolean
}

declare global {
  interface Window {
    SnapEngage: {
      hideButton: () => {}
      setCallback: (name: string, callback: () => void) => void
      setUserEmail: (username?: string) => void
      getAgentStatusAsync: (callback: (online: boolean) => void) => void
      startLink: () => void
      getCustomDesignIframe: () => Document
    }
    hasLoadedChat: boolean
  }
}

/**
 * Handles loading the snap engage chat live external library
 */
export const useSnapEngage = ({ user, events, hideCloseIcon = false }: SnapEngageParams) => {
  // Hide the default SnapEngage button since we are using our own. Make it non tabbable for accessibility.
  const hideButton = useCallback(() => {
    window.SnapEngage && window.SnapEngage.hideButton()
    const button = document.getElementById('designstudio-button')
    if (button) {
      button.setAttribute('tabindex', '-1')
      button.style.display = 'none'
    }
  }, [])

  const setSnapEngageProps = useCallback(() => {
    const role = user?.roles?.[0]?.role

    if (window.hasLoadedChat && role === 'ROLE_usr') {
      hideButton()
      window.SnapEngage.setCallback('Close', () => {
        hideButton()
      })
      window.SnapEngage.setUserEmail(user?.username)
    }
  }, [user?.roles, user?.username, hideButton])

  const loadChat = useCallback(() => {
    const hideCloseButtons = () => {
      const iframe = window.SnapEngage.getCustomDesignIframe()
      const xIcon = iframe.getElementById('icon-close')
      const minimizeIcon = iframe.getElementById('icon-minimize')
      if (xIcon && minimizeIcon && hideCloseIcon) {
        xIcon.style.display = 'none'
        minimizeIcon.style.display = 'none'
      }
    }
    const { onOnline, onOffline, onDisabled } = events
    setSnapEngageProps()
    if (window.hasLoadedChat) {
      window.SnapEngage.getAgentStatusAsync((online: boolean) => {
        // checking cypress allows tests to run 24/7 without worying about this service being online
        if (online && !window.Cypress) {
          onOnline()
          window.SnapEngage.startLink()
        } else {
          onOffline()
        }
      })
      hideCloseButtons()
    } else {
      onDisabled()
    }
  }, [events, setSnapEngageProps, hideCloseIcon])

  useEffect(() => {
    hideButton()
  }, [hideButton])

  return { loadChat }
}
