import React, { FunctionComponent } from 'react'
import { Path, Svg } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

/**
 * Lock Icon that can be used for elements related to Security
 */
export const LockIcon: FunctionComponent<LockIconProps> = ({ size = 20 }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} fill='none'>
      <Path
        d='M15.833 9.164H4.167c-.92 0-1.667.746-1.667 1.667v5.833c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.747 1.667-1.667v-5.833c0-.92-.746-1.667-1.667-1.667Z'
        stroke={colors.iconActive}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M5.832 9.164V5.831a4.167 4.167 0 0 1 8.333 0v3.333'
        stroke={colors.iconActive}
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  )
}

export type LockIconProps = {
  size?: string | number
}
