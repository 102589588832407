import { useMemo } from 'react'

import { uniq } from 'lodash-es'

import { CustomerPropertiesMap } from '../../../models'
import { PathwayOptions } from '../../pathways/constants'
import { ProgramCustomerPropertyId } from '../constants/customerProgram'

// Determines forked triage availability
export const useForkedTriageAvailability = ({
  customerPropertiesMap,
}: {
  customerPropertiesMap?: CustomerPropertiesMap
}) => {
  return useMemo(() => {
    let reasons: Array<{ propertyName: string; value: boolean }> = []

    if (customerPropertiesMap === undefined) return { availableOptions: [], showMultiCTAPathways: false, reasons }
    const essentialsDisabled = customerPropertiesMap[ProgramCustomerPropertyId.essentialsDisabled] === 'true'
    const shouldHideChildSearchCTA = customerPropertiesMap.shouldHideChildSearchCTA === 'true'
    const directAccessTherapyEnabled = customerPropertiesMap[ProgramCustomerPropertyId.directAccessTherapy] === 'true'

    reasons = [
      { propertyName: 'essentialsDisabled', value: essentialsDisabled },
      { propertyName: 'shouldHideChildSearchCTA', value: shouldHideChildSearchCTA },
    ]

    const neededProgramProperties = new Map<ProgramCustomerPropertyId, PathwayOptions[]>([
      [ProgramCustomerPropertyId.considerLyraTherapy, [PathwayOptions.SELF_SEARCH]],
      [ProgramCustomerPropertyId.directAccessTherapy, [PathwayOptions.SELF_SEARCH, PathwayOptions.CHILD_SEARCH]],
      [ProgramCustomerPropertyId.stressManagement, [PathwayOptions.PARENTING_TOOLKIT, PathwayOptions.COACHING_TOOLKIT]],
    ])

    let availableOptions: PathwayOptions[] = []

    neededProgramProperties.forEach((pathwayOptions, propertyName) => {
      if (customerPropertiesMap[propertyName] === 'true') {
        availableOptions = [...availableOptions, ...pathwayOptions]
        reasons.push({ propertyName, value: true })
      } else {
        reasons.push({ propertyName, value: false })
      }
    })

    /**
     * If direct access therapy is enabled, but child search is disabled, remove the available option
     */
    if (directAccessTherapyEnabled && shouldHideChildSearchCTA) {
      availableOptions.splice(availableOptions.indexOf(PathwayOptions.CHILD_SEARCH), 1)
    }

    /**
     * Remove duplicate pathway options
     */
    availableOptions = uniq(availableOptions)

    const showMultiCTAPathways = !essentialsDisabled && availableOptions.length > 2
    return { availableOptions, showMultiCTAPathways, reasons }
  }, [customerPropertiesMap])
}
