import React, { FunctionComponent } from 'react'
import { Dimensions, ViewStyle } from 'react-native'
import BackgroundVideo from 'react-player'

import styled, { useTheme } from 'styled-components/native'

import { CDN_ASSETS_LINK_BASE } from '@lyrahealth-inc/shared-app-logic'

import { ThemeType } from '../../utils'
import { tID } from '../../utils/utils'

const HeroSectionContainer = styled.View<{ height: number }>(({ height }) => ({
  position: 'relative',
  width: '100%',
  height,
  top: 0,
  left: 0,
}))

const BackgroundContainer = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
})

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  width: '100%',
  height: '100%',
  paddingHorizontal: theme.spacing['24px'],
  alignItems: 'center',
}))

const SectionContent = styled.View({
  justifyContent: 'center',
  alignItems: 'center',
})

const StaticBackgroundContainer = styled.ImageBackground({
  width: '100%',
  height: '100%',
  position: 'absolute',
})

export interface HeroSectionProps {
  header: JSX.Element
  subHeader?: JSX.Element
  ctaSection?: JSX.Element
  testID?: string
  useStaticBackground?: boolean
  contentContainerStyle?: ViewStyle
}

export const HeroSection: FunctionComponent<HeroSectionProps> = ({
  header,
  subHeader,
  ctaSection,
  testID = 'HeroSection',
  useStaticBackground,
  contentContainerStyle,
}) => {
  const { breakpoints } = useTheme()
  const { isMobileSized, isMinWidthMobileL, isMinWidthTablet } = breakpoints
  const webAspectRatio = 2888 / 1744
  const mobileAspectRatio = 780 / 1314
  const mediumAspectRatio = 1230 / 1770
  // since default behavior for video is to maintain aspect ratio, we increase
  // height by offset so the video's width completely fills width of window.
  const sizeBetweenTabletAndLargePhone = isMinWidthMobileL && !isMinWidthTablet
  const heightOffset = isMobileSized && !sizeBetweenTabletAndLargePhone ? 48 : 2

  const videoHeight =
    Math.round(
      Dimensions.get('window').width *
        (1 /
          (isMobileSized ? (sizeBetweenTabletAndLargePhone ? mediumAspectRatio : mobileAspectRatio) : webAspectRatio)),
    ) + heightOffset
  return (
    <HeroSectionContainer testID={tID(testID)} height={videoHeight}>
      <BackgroundContainer>
        {!useStaticBackground ? (
          <BackgroundVideo
            playsinline
            controls={false}
            url={`${CDN_ASSETS_LINK_BASE}/SoftSunriseWithBirdBgAnimated${
              isMobileSized ? (sizeBetweenTabletAndLargePhone ? 'Medium' : 'Mobile') : 'Web'
            }.mp4`}
            height='100%'
            width='100%'
            loop
            playing
            muted
            style={{ position: 'absolute', top: 0, left: 0 }}
            data-chromatic={'ignore'}
          />
        ) : (
          <StaticBackgroundContainer
            source={
              isMobileSized
                ? require('../../assets/microsite/comingSoonMicrositeBackgroundMobile.png')
                : require('../../assets/microsite/comingSoonMicrositeBackgroundDesktop.png')
            }
          />
        )}
        <ContentContainer style={contentContainerStyle}>
          <SectionContent>
            {header}
            {subHeader}
            {ctaSection}
          </SectionContent>
        </ContentContainer>
      </BackgroundContainer>
    </HeroSectionContainer>
  )
}
