import { MutableRefObject, ReactNode, useCallback } from 'react'
import { defineMessage } from 'react-intl'

import { useBackToTriage } from './useBackToTriage'
import { useGetTreatmentOptions } from './useGetTreatmentOptions'
import {
  useSelectTreatmentOption,
  UseSelectTreatmentOptionArgs,
  NavigationRoutes as UseSelectTreatmentOptionNavigationRoutes,
} from './useSelectTreatmentOption'
import { unsupportedTreatmentOptionsConfigurations as defaultUnsupportedTreatmentOptionsConfigurations } from '../../../features/common/constants/baseTreatmentOptionConstants'
import { S4C_EVENTS } from '../../../features/common/constants/mixpanelConstants'
import { SEARCHER_TYPE_TO_MATCH_FOR } from '../../../features/searchForCare/constants'
import { getUnsupportedTreatmentOptionsConfiguration } from '../../../features/searchForCare/utils'
import { CareNavigatorHeaderEntryPointModalProps, TriageSearch } from '../../../models'
import { GetWordCloudWordsActionData, SearcherInfo, WordCloudWords } from '../../../models/searchForCare/SearchForCare'
import { TREATMENT_OPTIONS_TYPE } from '../../common/constants/treatmentOptions'
import { TREATMENT_RECOMMENDATION_TIER } from '../../common/constants/treatmentRecommendationsConstants'

interface NavigationRoutes extends UseSelectTreatmentOptionNavigationRoutes {
  allTreatmentOptions: () => void
  contactCareNavigatorAssertiveTriage: () => void
  contactCareNavigatorConsolidated: () => void
  testimonials: () => void
  goBack: () => void
  medOptionsExplained: () => void
}

export interface useTreatmentRecommendationsArgs extends Omit<UseSelectTreatmentOptionArgs, 'navigationRoutes'> {
  matchFor: SEARCHER_TYPE_TO_MATCH_FOR
  userDateOfBirth?: string
  isCreatingSearch: boolean
  navigationRoutes: NavigationRoutes
  shouldSeeCareAdvocateRequestForm?: boolean
  searcherInfo?: SearcherInfo | null
  customerCopy?: { [key: string]: any }
  postTriageSearch: (arg: any) => Promise<any>
  footer?: ReactNode
  faqText?: string
  getIsAssertiveTriage: (tier?: TREATMENT_RECOMMENDATION_TIER) => boolean
  // Generic header props
  onExit: () => void
  a11yFocusedElementOnLoad?: MutableRefObject<any>
  shouldShowCareNavigatorHeaderEntrypoint: boolean
  careNavigatorHeaderEntryPointModalProps: CareNavigatorHeaderEntryPointModalProps
  careNavigatorConsolidatedBottomSheet: React.ReactNode
  isSidePanel?: boolean
  tollFreeNumbers?: string[]
  outOfCountryNumbers?: string[]
  benefitsPortalLink?: string
  searcherFirstName?: string
  shouldCreateDuplicateInProgressSearch?: boolean
  getWordCloudWords: (data: GetWordCloudWordsActionData) => Promise<any>
  wordCloudWords: WordCloudWords | null
  primaryNeed: string
  isUserInternational: boolean
  isDirectAccessMedsSupported: boolean
  isLctTeensEnabled: boolean
  isHealthPlanConfirmed: boolean
  isDedicatedCareNavigatorEnabled: boolean
  userCountryName: string
  isLyraSocialCareEnabled: boolean
}

/**
 * Returns all the props needed to render the treatment recommendation page
 */

export const useTreatmentRecommendations = ({
  treatmentRecs,
  isUserInternational,
  shouldSeeCareAdvocateRequestForm,
  currentProgramCoverageBreakdown,
  selectedSearch,
  searcherInfo,
  customerCopy,
  a11yFocusedElementOnLoad,
  postTriageSearch,
  isCreatingSearch,
  navigationRoutes,
  onExit,
  footer,
  faqText,
  getIsAssertiveTriage,
  shouldShowCareNavigatorHeaderEntrypoint,
  careNavigatorHeaderEntryPointModalProps,
  careNavigatorConsolidatedBottomSheet,
  isSidePanel,
  setMeetingFormat,
  patchTriageSearch,
  setSelectedTreatmentOption,
  trackEvent,
  tollFreeNumbers,
  outOfCountryNumbers,
  benefitsPortalLink,
  shouldCreateDuplicateInProgressSearch,
  getWordCloudWords,
  wordCloudWords,
  primaryNeed,
  isDirectAccessMedsSupported,
  isLctTeensEnabled,
  isHealthPlanConfirmed,
  isDedicatedCareNavigatorEnabled,
  userCountryName,
  isLyraSocialCareEnabled,
}: useTreatmentRecommendationsArgs) => {
  const { age: searcherAge } = searcherInfo ?? { age: 0 }
  const { treatmentOptions, configForSpecificTreatmentOption } = useGetTreatmentOptions(
    searcherAge,
    treatmentRecs,
    isUserInternational,
    customerCopy,
  )
  const handleBackPress = useBackToTriage({
    goBack: navigationRoutes.goBack,
    search: selectedSearch as TriageSearch,
    searcherInfo,
    country: userCountryName,
    lyraSocialCareEnabled: isLyraSocialCareEnabled,
    postTriageSearch,
    isCreatingSearch,
    shouldCreateDuplicateInProgressSearch,
    getWordCloudWords,
    wordCloudWords,
    primaryNeedsWord: primaryNeed,
    isLctTeensEnabled,
  })
  const contentTier = treatmentRecs?.shownTierPage

  const mainHeader =
    isUserInternational && contentTier === TREATMENT_RECOMMENDATION_TIER.TIER_3
      ? defineMessage({
          defaultMessage: 'We’re here to help you find the right counsellor',
          description: 'Main Header title for treatment recommendation',
        })
      : undefined // Will use default when undefined

  const selectTreatmentOption = useSelectTreatmentOption({
    navigationRoutes: {
      locationFinder: navigationRoutes.locationFinder,
      healthPlanInfo: navigationRoutes.healthPlanInfo,
    },
    selectedSearch,
    treatmentRecs,
    currentProgramCoverageBreakdown,
    setMeetingFormat,
    patchTriageSearch,
    setSelectedTreatmentOption,
    trackEvent,
    isHealthPlanConfirmed,
  })

  const isAssertiveTriage = useCallback(
    () => getIsAssertiveTriage(treatmentRecs?.shownTierPage),
    [getIsAssertiveTriage, treatmentRecs?.shownTierPage],
  )

  const unsupportedTreatmentOptionsConfiguration = getUnsupportedTreatmentOptionsConfiguration({
    unsupportedTreatmentOptionsConfigurations: defaultUnsupportedTreatmentOptionsConfigurations,
    excludedTreatmentOptionsReasons: treatmentRecs?.excludedTreatmentOptionsReasons,
    isUserInternational,
  })

  // If customerOverride is found as an exclusion reason, then the BE has filtered out other treatment options
  // based on a customer's requirements (e.g Kaiser referring members for a specific treatment) and we should
  // not show any other treatment options to the user
  // see: LW/TierOneTreatmentRecommendations.tsx
  const showOtherCareOptionsEntryPoint =
    treatmentRecs?.shownTierPage === TREATMENT_RECOMMENDATION_TIER.TIER_1 &&
    !treatmentRecs?.excludedTreatmentOptionsReasons.some((option) => {
      option?.reasons?.includes('customerOverride')
    }) &&
    !isAssertiveTriage()

  const handleTreatmentOptionPress = useCallback(
    (submittedTreatmentOption: TREATMENT_OPTIONS_TYPE) => {
      selectTreatmentOption(submittedTreatmentOption, Boolean(selectedSearch?.id))
    },
    [selectTreatmentOption, selectedSearch?.id],
  )

  const handleMoreRecommendationOptionsButtonPress = () => {
    if (isAssertiveTriage()) {
      navigationRoutes.contactCareNavigatorAssertiveTriage()
    } else {
      if (isDedicatedCareNavigatorEnabled) {
        careNavigatorHeaderEntryPointModalProps?.setIsShowingBottomSheet(
          !careNavigatorHeaderEntryPointModalProps?.isShowingBottomSheet,
        )
      } else {
        navigationRoutes.contactCareNavigatorConsolidated()
      }
    }
  }

  const handleLearnMorePress = () => {
    navigationRoutes.medOptionsExplained()
    trackEvent(S4C_EVENTS.CLICK_LEARN_MORE_FOR_MED_PROGRAMS)
  }

  return [
    {
      onTreatmentOptionButtonPress: handleTreatmentOptionPress,
      options: treatmentOptions,
      configForSpecificTreatmentOption,
      isInternational: isUserInternational,
      shouldShowAssertiveTriageCopy: shouldSeeCareAdvocateRequestForm,
      onMoreCareOptionsPress: navigationRoutes.allTreatmentOptions,
      onMoreRecommendationOptionsButtonPress: handleMoreRecommendationOptionsButtonPress,
      onReadMoreTestimonialsPress: navigationRoutes.testimonials,
      onLearnMorePress: handleLearnMorePress,
      a11yFocusedElementOnLoad,
      showOtherCareOptions: showOtherCareOptionsEntryPoint,
      programCoverage: currentProgramCoverageBreakdown,
      searcherType: searcherInfo?.type,
      footer,
      faqText,
      contentTier,
      tollFreeNumbers,
      outOfCountryNumbers,
      unsupportedTreatmentOptionsConfiguration,
      benefitsPortalLink,
      searcherFirstName: searcherInfo?.name,
      mainHeader,
      headerProps: {
        isInternational: isUserInternational,
        onBack: handleBackPress,
        onExit,
        testIdPrefix: 'NavBar',
        a11yFocusedElementOnLoad,
        shouldShowCareNavigatorHeaderEntrypoint,
        careNavigatorHeaderEntryPointModalProps,
        careNavigatorConsolidatedBottomSheet,
        isSidePanel,
      },
      isDirectAccessMedsSupported,
    },
    // Data needed for logging
    {
      treatmentOptions,
      configForSpecificTreatmentOption,
      isAssertiveTriage: isAssertiveTriage(),
    },
  ]
}
