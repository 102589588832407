import React, { FunctionComponent, useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { Divider } from '../../atoms/divider/Divider'
import { Link } from '../../atoms/link/Link'
import { IS_WEB } from '../../constants'
import { AppContext } from '../../context'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { CareNavigatorConsolidatedOptionsList } from '../../organisms/careNavigatorConsolidatedOptionsList/CareNavigatorConsolidatedOptionsList'
import { Flex1ScrollView } from '../../templates'
import { openUrl, ThemeType } from '../../utils'
import { tID } from '../../utils/utils'
import { ContactCareNavigatorBase } from '../contactCareNavigatorBase/ContactCareNavigatorBase'

export interface ContactCareNavigatorConsolidatedPageProps {
  customerPhoneNumber?: string
  isInternational: boolean
  isLyrianFlow?: boolean
  isZeroProvidersFlow?: boolean
  isHomebaseFlow?: boolean
  onExit?: () => void
  onBack?: () => void
  onPressEmail: () => void
  onPressFAQ: () => void
  onPressLiveChat: () => void
  onPressPhoneCall: () => void
  onPressScheduleCall: () => void
  showLiveChat: boolean
  showScheduleCall?: boolean
  useSidePanelStyle?: boolean
  hideXButton?: boolean
  forChild?: boolean
  childName?: string
  hideCustomerPhoneNumber?: boolean
  careNavigatorHeadshot?: string
  careNavigatorLicense?: string
  careNavigatorName?: string
  showMinimalCTAText?: boolean
}

const ScrollContainer = styled(Flex1ScrollView)<{ addMargin?: boolean; theme: ThemeType }>(
  ({ addMargin = false, theme }) => ({
    marginTop: addMargin ? theme.spacing['24px'] : 0,
    backgroundColor: theme.colors.backgroundSecondary,
    ...(!theme.breakpoints.isMobileSized && { maxHeight: '100%', overflowY: 'auto' }),
  }),
)

const DescriptionContainer = styled.View(({ theme }) => ({
  marginTop: theme.spacing['8px'],
}))

const FAQContainer = styled.View(({ theme }) => ({
  marginTop: theme.spacing['8px'],
}))

const DividerContainer = styled.View(({ theme }) => ({
  marginTop: theme.spacing['24px'],
}))

const InlineWrapper = styled.View({
  ...(IS_WEB && { display: 'inline' }),
})

export const ContactCareNavigatorConsolidatedPage: FunctionComponent<ContactCareNavigatorConsolidatedPageProps> = ({
  customerPhoneNumber,
  isInternational,
  isLyrianFlow = false,
  isZeroProvidersFlow = false,
  isHomebaseFlow = false,
  onExit,
  onBack,
  onPressEmail = noop,
  onPressFAQ = noop,
  onPressLiveChat = noop,
  onPressPhoneCall = noop,
  onPressScheduleCall = noop,
  showLiveChat,
  showScheduleCall,
  useSidePanelStyle = false,
  hideXButton = false,
  forChild = false,
  childName = '',
  hideCustomerPhoneNumber = false,
  careNavigatorHeadshot = '',
  careNavigatorLicense = '',
  careNavigatorName = '',
  showMinimalCTAText = false,
}) => {
  const { isDedicatedCareNavigatorEnabled } = useContext(AppContext)

  const { isMobileSized } = useMediaQuerySize()
  const { colors } = useTheme()
  // CN 3.0: New global navigation side panel will have a different copy for header text
  const useGetInTouchHeader = (isDedicatedCareNavigatorEnabled && useSidePanelStyle) || isHomebaseFlow
  const headerText = () => {
    if (isZeroProvidersFlow) {
      if (isLyrianFlow) {
        return (
          <FormattedMessage
            defaultMessage='Connect with Lyrian support for next steps'
            description='Header of page that offers connecting with a Lyrian support for general help'
          />
        )
      } else if (forChild) {
        return (
          <FormattedMessage
            defaultMessage='Contact a Care Navigator for next steps'
            description='Header of page that offers connecting with a Care Navigator for help with finding care for a child'
          />
        )
      } else {
        return (
          <FormattedMessage
            defaultMessage='Contact your Care Navigator for next steps'
            description='Header of page that offers connecting with a Care Navigator for general help'
          />
        )
      }
    } else if (useGetInTouchHeader) {
      return (
        <FormattedMessage
          defaultMessage='Get in touch'
          description='Header of page that offers connecting with a Care Navigator for general help'
        />
      )
    } else if (isLyrianFlow) {
      return (
        <FormattedMessage
          defaultMessage='Connect with Lyrian support'
          description='Header of page that offers connecting with a Lyrian support for general help'
        />
      )
    } else if (isInternational) {
      return (
        <FormattedMessage
          defaultMessage='Contact a Care Navigator'
          description='Header of page that offers connecting with a Care Navigator for general help'
        />
      )
    } else {
      return (
        <FormattedMessage
          defaultMessage='Contact your Care Navigator'
          description='Header of page that offers connecting with a Care Navigator for general help'
        />
      )
    }
  }

  const bodyText = () => {
    if (useSidePanelStyle) {
      if (isLyrianFlow) {
        return (
          <FormattedMessage
            defaultMessage='We can help you refine your search for care, find the right provider, or troubleshoot any technical issues.'
            description='Description of how a Care Navigator can give the user support any time. The Care Navigator can also help users search for care, finding the right provider, and troubleshoot technical issues.'
          />
        )
      } else if (isInternational) {
        return (
          <FormattedMessage
            defaultMessage='If you’d like to talk, we’re here for you 24/7. Our team is also available to answer your questions, review care options, and discuss language preferences.'
            description='Description of how a Care Navigator can give the user support any time. The Care Navigator can also help users search for care, finding the right provider, and troubleshoot technical issues.'
          />
        )
      } else if (isDedicatedCareNavigatorEnabled) {
        return (
          <FormattedMessage
            defaultMessage='Our Care Navigator team is here to help if you have questions or need help finding care. Clinicians are also available for immediate crisis support at <link>{phone}</link>, option 2.'
            description='Description of how a Care Navigator can give the user support any time and also informs that clinicians are available. The Care Navigator can also help users search for care, finding the right provider, and troubleshoot technical issues.'
            values={{
              phone: customerPhoneNumber,
              link: (phone: string) => <Link text={phone} underline onPress={() => openUrl(`tel:${phone}`)} />,
            }}
          />
        )
      } else {
        return (
          <FormattedMessage
            defaultMessage='We’re here for you: we can help you refine your search for care, find the right provider, or troubleshoot any technical issues. Our clinicians are available 24/7 for immediate crisis support at <link>{phone}</link>, option 2.'
            description='Description of how a Care Navigator can give the user support any time. The Care Navigator can also help users search for care, finding the right provider, and troubleshoot technical issues.'
            values={{
              phone: customerPhoneNumber,
              link: (phone: string) => <Link text={phone} underline onPress={() => openUrl(`tel:${phone}`)} />,
            }}
          />
        )
      }
    } else if (isLyrianFlow) {
      return (
        <FormattedMessage
          defaultMessage='We can help you refine your search, understand all of your options, and find the right provider.'
          description='Description of how a Lyrian Support member can help search for treatment, understand their options and find suitable provider.'
        />
      )
    } else if (isZeroProvidersFlow) {
      if (forChild) {
        return (
          <FormattedMessage
            defaultMessage='Thank you for taking the time to tell us about {childName}. The next step will be to connect with a Care Navigator who will work with you to find the right provider for {childName}.'
            description='Description text thanking the user for their time to describe their child and what comes next is the opportunity to connect to a Care Navigator who can give the user support to find the right provider for their child.'
            values={{ childName }}
          />
        )
      } else {
        return (
          <FormattedMessage
            defaultMessage='Thank you for taking the time to tell us about yourself. The next step in your care journey will be to connect with a Care Navigator who will work with you to find the right provider.'
            description='Description text thanking the user for their time to describe themselves and what comes next is the opportunity to connect to a Care Navigator who can give the user support to find the right provider.'
          />
        )
      }
    } else if (isInternational) {
      return (
        <FormattedMessage
          defaultMessage='If you’d like to talk, we’re here for you 24/7. Our team is also available to answer your questions, review care options, and discuss language preferences.'
          description='Description of how the user may contact Lyra to talk or for support to help search for care, go over treatment options, and finding the right provider.'
        />
      )
    } else {
      return (
        <FormattedMessage
          defaultMessage='We’re here for you 24/7. Your Care Navigator is available to give you confidential, in-the-moment support. They can also help you refine your search for care, review your options, and find the right provider.'
          description='Description of how a Care Navigator can give the user private support right now. The Care Navigator can also help users search for care, go over treatment options, and finding the right provider'
        />
      )
    }
  }

  const FAQLink = (
    <Link
      text={<FormattedMessage defaultMessage='FAQs' description='Abbreviation for frequently asked questions' />}
      onPress={onPressFAQ}
      testID={tID('ContactCareNavigatorPage-faq-link')}
      underline
    />
  )

  return (
    <ScrollContainer addMargin={useGetInTouchHeader && isMobileSized}>
      <ContactCareNavigatorBase
        header={headerText()}
        backgroundColor={useSidePanelStyle ? colors.backgroundTransparent : colors.backgroundSecondary}
        onExit={onExit}
        onBack={onBack}
        useSidePanelStyle={useSidePanelStyle}
        hideXButton={hideXButton}
        useGetInTouchHeaderStyle={useGetInTouchHeader}
      >
        <DescriptionContainer>
          <InlineWrapper>
            <BodyText
              size={BodyTextSize.DEFAULT}
              text={bodyText()}
              testID={tID(
                !isLyrianFlow && !isInternational
                  ? 'ContactCareNavigatorPage-directClinicalLine-text'
                  : 'ContactCareNavigatorPage-body-text',
              )}
            />
          </InlineWrapper>
        </DescriptionContainer>
        {!(isInternational || isZeroProvidersFlow || isLyrianFlow) && (
          <FAQContainer>
            <BodyText
              size={BodyTextSize.DEFAULT}
              text={
                useSidePanelStyle ? (
                  <FormattedMessage
                    defaultMessage='You can also check out the {FAQ}.'
                    description='Message offering a quick source of help with a link to frequently asked questions'
                    values={{
                      FAQ: FAQLink,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage='Check out the {FAQ} for some quick answers.'
                    description='Message offering a quick source of help with a link to frequently asked questions'
                    values={{ FAQ: FAQLink }}
                  />
                )
              }
              testID={tID('ContactCareNavigatorPage-faq-body-link')}
            />
          </FAQContainer>
        )}
        {!isDedicatedCareNavigatorEnabled && (
          <DividerContainer>
            <Divider />
          </DividerContainer>
        )}
        <CareNavigatorConsolidatedOptionsList
          customerPhoneNumber={customerPhoneNumber}
          isInternational={isInternational}
          isLyrians={isLyrianFlow}
          isHomebaseFlow={isHomebaseFlow}
          isSidePanel={useSidePanelStyle}
          onPressEmail={onPressEmail}
          onPressFAQ={onPressFAQ}
          onPressLiveChat={onPressLiveChat}
          onPressPhoneCall={onPressPhoneCall}
          onPressScheduleCall={onPressScheduleCall}
          showLiveChat={showLiveChat}
          showScheduleCall={showScheduleCall}
          hideCustomerPhoneNumber={hideCustomerPhoneNumber}
          careNavigatorHeadshot={careNavigatorHeadshot}
          careNavigatorLicense={careNavigatorLicense}
          careNavigatorName={careNavigatorName}
          showMinimalCTAText={showMinimalCTAText}
        />
      </ContactCareNavigatorBase>
    </ScrollContainer>
  )
}
