import { useEffect, useRef } from 'react'

import { S4C_HOMEBASE_HEADER_PROPERTY_VALUE } from '../../common/constants/mixpanelConstants'
import {
  SEARCH_FOR_CARE_HOMEBASE_DESCRIPTIONS,
  SEARCH_FOR_CARE_HOMEBASE_METRICS,
} from '../../experiments/searchForCareExperimentTypes'
import { ExperimentPayload } from '../../experiments/types'
import { generateSearchForCareHomebaseHeaderExperimentPayload } from '../../experiments/utils'
import { getIsCenterIllustration, getIsSingleCTA } from '../startCareHomeBaseUtil'
import { SearchForCareHeaderExperimentVariant } from '../types'

export interface useSearchForCareHomebaseExperimentArgs {
  searchForCareHeaderVariants: SearchForCareHeaderExperimentVariant | null
  userCountryName: string
  customerName: string
  userId: string
  deviceUUID: string
  postExperimentCallback?: (payload: ExperimentPayload) => void
  postLDCallback?: (payload: ExperimentPayload) => void
  logToSumo?: (userId: string, message: string) => void
}

export const useSearchForCareHomebaseExperiment = ({
  searchForCareHeaderVariants,
  userCountryName,
  customerName,
  userId,
  deviceUUID,
  postExperimentCallback,
  postLDCallback,
  logToSumo,
}: useSearchForCareHomebaseExperimentArgs) => {
  const isSearchForCareHeaderCenterIllustration = searchForCareHeaderVariants
    ? getIsCenterIllustration(searchForCareHeaderVariants.illustrationVariant)
    : false
  const isSearchForCareHeaderSingleCTA = searchForCareHeaderVariants
    ? getIsSingleCTA(searchForCareHeaderVariants.callToActionVariant)
    : false
  const illustrationArm = isSearchForCareHeaderCenterIllustration
    ? S4C_HOMEBASE_HEADER_PROPERTY_VALUE.ILLUSTRATION
    : S4C_HOMEBASE_HEADER_PROPERTY_VALUE.PROVIDER_PHOTO
  const ctaArm = isSearchForCareHeaderSingleCTA
    ? S4C_HOMEBASE_HEADER_PROPERTY_VALUE.SINGLE_CTA
    : S4C_HOMEBASE_HEADER_PROPERTY_VALUE.MULTIPLE_CTA
  const inSearchForCareExperiment = searchForCareHeaderVariants !== null
  const searchForCareArm = inSearchForCareExperiment ? `${ctaArm} - ${illustrationArm}` : ''
  const hasPostExperiment = useRef<boolean>(false)

  useEffect(() => {
    if (inSearchForCareExperiment && !hasPostExperiment.current) {
      const experimentPayload = generateSearchForCareHomebaseHeaderExperimentPayload({
        arm: searchForCareArm,
        detected_country: userCountryName,
        metric: SEARCH_FOR_CARE_HOMEBASE_METRICS.VIEW_HOMEBASE,
        customer_name: customerName,
        description: SEARCH_FOR_CARE_HOMEBASE_DESCRIPTIONS.HOMEBASE,
        device_uuid: deviceUUID,
        client_lyra_id: userId,
      })

      if (postExperimentCallback) {
        try {
          postExperimentCallback(experimentPayload)
          hasPostExperiment.current = true
        } catch (error) {
          const errorMsg = 'Unable to post to experiment endpoint for search for care experiment'
          console.error(errorMsg)
          if (logToSumo) {
            logToSumo(userId, `${errorMsg} - ${error}`)
          }
        }
      }

      if (postLDCallback) {
        try {
          postLDCallback(experimentPayload)
        } catch (error) {
          const errorMsg = 'Unable to post to launch darkly endpoint for search for care experiment'
          console.error(errorMsg)
          if (logToSumo) {
            logToSumo(userId, `${errorMsg} - ${error}`)
          }
        }
      }
    }
  }, [
    customerName,
    deviceUUID,
    inSearchForCareExperiment,
    isSearchForCareHeaderCenterIllustration,
    isSearchForCareHeaderSingleCTA,
    logToSumo,
    postExperimentCallback,
    postLDCallback,
    searchForCareArm,
    searchForCareHeaderVariants,
    userCountryName,
    userId,
  ])

  return {
    isSearchForCareHeaderCenterIllustration,
    isSearchForCareHeaderSingleCTA,
    inSearchForCareExperiment,
    searchForCareArm,
  }
}
