import { ProgramNames } from '../../models/programs/Programs'

// TODO: Eventually this could be reconciled with PROGRAMS in
// apps/lyrahealthwebapp/src/common/constants/customerProgramAndTreatmentOptionConstants.js
export const PROGRAM_SESSION_RESCHEDULE_MAP = {
  [ProgramNames.DIRECT_ACCESS_MEDS]: true,
  [ProgramNames.MedicationManagement]: false,
  [ProgramNames.SELF_CARE_APPS]: false,
  [ProgramNames.DIRECT_ACCESS_THERAPY]: true,
  [ProgramNames.BlendedCareTherapy]: true,
  [ProgramNames.Coaching]: true,
  [ProgramNames.SingleSessionCoaching]: true,
  [ProgramNames.AlcoholUseDisorderTherapy]: true,
  [ProgramNames.ICASTherapy]: false,
}

export const RESCHEDULE_SESSION_HOUR_LIMIT = 24

export const CANCEL_SESSION_HOUR_LIMIT = 24

export enum CANCELATION_OPTIONS {
  'needMoreTime' = 'needMoreTime',
  'lookingOtherCareOptions' = 'lookingOtherCareOptions',
  'readyToGraduate' = 'readyToGraduate',
  'emergency' = 'emergency',
  'notFeelingWell' = 'notFeelingWell',
  'schedulingConflict' = 'schedulingConflict',
  'other' = 'other',
}
