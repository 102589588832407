import './styles/bootstrap-custom'
import 'font-awesome/css/font-awesome.min.css'
import '@lyrahealth-inc/ui-core/lib/root.module.scss'
import './styles/main.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'

import App from './App'
import store from './data/storeConfiguration/store'

if (!window.Cypress && ['production', 'staging'].includes(process.env.NODE_ENV ?? '')) {
  Sentry.init({
    environment: process.env.NODE_ENV === 'production' ? 'production' : 'staging',
    dsn: 'https://4120d1848fecaa8effb4395c050ea4b3@o525650.ingest.sentry.io/4506419357941760',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      ...(process.env.NODE_ENV === 'production'
        ? [
            Sentry.replayIntegration({
              beforeErrorSampling(event) {
                // Check if the event is an error event
                if (event.exception) {
                  // Check if the event has a 404 error
                  const requestErrors = event.exception?.values?.filter(
                    (value) => value.type === 'Error' && value?.value?.includes('404'),
                  )

                  // If there is a 404 error, return false to filter it out
                  if (requestErrors?.length ?? 0 > 0) {
                    return false
                  }
                }

                // Otherwise, allow the error to be sampled
                return true
              },
            }),
          ]
        : []),
    ],
    sampleRate: 0.25,
    tracesSampleRate: 0.002,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/.*lyrahealth\.com\/services/],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.005,
  })
}

const REACT18_CONCURRENT_MODE = false
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

window.onload = function () {
  const rootEl = document.getElementById('root')
  if (rootEl === null) {
    throw new Error('Could not find root element')
  }

  // LyraWeb was upgraded to React 18 without resolving StrictMode issues.
  // Many libraries still in use, including redux v5, are not compatible with React 18's concurrent rendering.
  //
  // Wrap the application in StrictMode to raise potential errors while in development.
  //
  // See:
  // https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
  // https://github.com/reactwg/react-18/discussions/64
  //
  if (REACT18_CONCURRENT_MODE) {
    const root = createRoot(rootEl)
    root.render(
      <React.StrictMode>
        <App store={store} />
      </React.StrictMode>,
    )
  } else {
    // In React 18, Suspense has different concurrent semantics that will break the app in various places.
    // <Suspense /> is used for code splitting with react-router v6.
    // Resorting to ReactDOM.render to opt out of concurrent rendering and behave more like React 17.
    // This is technically unsupported in React 18 and may have other consequences or prevent us
    // from using newer concurrent features.
    // eslint-disable-next-line
    ReactDOM.render(<App store={store} />, rootEl)
  }
}
