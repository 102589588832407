import { EligibilityType } from '../../features'

export interface EAPEligibilityRequest {
  customerName?: string
  lyraId: string | null | undefined
  eligibilityType?: EligibilityType
  firstname?: string | null | undefined
  lastname?: string | null | undefined
  dob?: string | null | undefined
  customerCode?: string | null | undefined
  employeeFirstname?: string | null | undefined
  employeeLastname?: string | null | undefined
  employeeDob?: string | null | undefined
  customerCountryId?: number | undefined
}

export enum EligibilityStatus {
  ELIGIBLE = 'eligible',
  INELIGIBLE = 'ineligible',
  UNKNOWN = 'unknown',
}

export interface EAPEligibilityResponse {
  eligibilityStatus: EligibilityStatus
  message?: string
}

export interface ConfirmEligibilityRequest {
  customerName: string
  lyraId: string
  customerCountryId: number | undefined
  eligibilityType: EligibilityType
  firstname: string
  lastname: string
  dob: string
  customerCode?: string
  employeeFirstname?: string
  employeeLastname?: string
  employeeDob?: string
  checkAdultDependentEligibilityUsingDependentInfo: boolean | undefined
}
