import React from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  DirectLinkIntent,
  DirectLinkSource,
  FORKED_TRIAGE_ENTRY_POINTS,
  PathwayOptions,
  PathwaysRedirectUrls,
  PROGRAM_TAXONOMY,
  SEARCHER_TYPE_TO_MATCH_FOR,
  TRIAGE_ENTRY_POINTS,
} from '@lyrahealth-inc/shared-app-logic'

import { useGoToDirectPathBooking } from '../../../common/hooks/useGoToDirectPathBooking'
import { useNavigateToSearchForCare } from '../../../common/hooks/useNavigateToSearchForCare'
import { getId } from '../../../data/user/userSelectors'
import { handleZeroViewSelect } from '../../appointmentDashboard/data/appointmentDashboardActions'
import { setSelectedPathwayOption, setTriageEntryPoint } from '../../register/data/registerActions'
import { setSearcherInfo } from '../../triage/data/triageActions'
import ForkedTriageContainer from '../ForkedTriageContainer'
import { forkedTriageNavigationRoutes } from '../types'

type Props = ConnectedProps<typeof connector>

const ForkedTriage: React.FC<Props> = ({
  setSelectedPathwayOption,
  setSearcherInfo,
  handleZeroViewSelect,
  setTriageEntryPoint,
}) => {
  const { pathname, state } = useLocation()
  const navigate = useNavigate()
  const isLoggedIn = Boolean(useSelector(getId))
  const navigateToSearchForCare = useNavigateToSearchForCare({ handleZeroViewSelect, setSearcherInfo })
  const goToDirectPath = useGoToDirectPathBooking()

  const setPathwayOptionAndNavigateToRegistration = (
    pathwayOption: PathwayOptions,
    mixpanelEntryPoint: FORKED_TRIAGE_ENTRY_POINTS,
  ) => {
    setSelectedPathwayOption(pathwayOption)
    navigate('/register/connect', { state: { ...state, mixpanelEntryPoint } })
  }

  const navigateToRegistrationRoutes: forkedTriageNavigationRoutes = {
    onSelfSearchPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.SELF_SEARCH,
        FORKED_TRIAGE_ENTRY_POINTS.FIND_CARE_FOR_SELF,
      )
    },
    onChildSearchPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.CHILD_SEARCH,
        FORKED_TRIAGE_ENTRY_POINTS.FIND_CARE_FOR_CHILD,
      )
    },
    onCoachingToolkitPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.COACHING_TOOLKIT,
        FORKED_TRIAGE_ENTRY_POINTS.COACHING_FOR_SELF,
      )
    },
    onParentingToolkitPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.PARENTING_TOOLKIT,
        FORKED_TRIAGE_ENTRY_POINTS.COACHING_FOR_CHILD,
      )
    },
    onMedsConsultPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.MEDICATION_CONSULTATION,
        FORKED_TRIAGE_ENTRY_POINTS.MEDICATION_CONSULTATION_FOR_SELF,
      )
    },
  }

  const navigateToLoggedInRoutes: forkedTriageNavigationRoutes = {
    onSelfSearchPress: () => {
      setSelectedPathwayOption(PathwayOptions.SELF_SEARCH)
      setTriageEntryPoint(TRIAGE_ENTRY_POINTS.FORKED_TRIAGE)
      navigateToSearchForCare()
    },
    onChildSearchPress: () => {
      setSelectedPathwayOption(PathwayOptions.CHILD_SEARCH)
      setTriageEntryPoint(TRIAGE_ENTRY_POINTS.FORKED_TRIAGE)
      handleZeroViewSelect({ whoAmIHereFor: SEARCHER_TYPE_TO_MATCH_FOR.OTHER_CHILD }, navigate, pathname)
    },
    onCoachingToolkitPress: () => {
      setSelectedPathwayOption(PathwayOptions.COACHING_TOOLKIT)
      navigate(PathwaysRedirectUrls.COACHING_TOOLKIT, { state })
    },
    onParentingToolkitPress: () => {
      setSelectedPathwayOption(PathwayOptions.PARENTING_TOOLKIT)
      navigate(PathwaysRedirectUrls.PARENTING_TOOLKIT, { state })
    },
    onMedsConsultPress: () => {
      setSelectedPathwayOption(PathwayOptions.MEDICATION_CONSULTATION)
      goToDirectPath({
        params: {
          clientele: PROGRAM_TAXONOMY.clientele.individual,
          treatment: PROGRAM_TAXONOMY.treatment.medicationManagement,
          partner: PROGRAM_TAXONOMY.partner.blendedCare,
          offering: PROGRAM_TAXONOMY.offering.default,
          directLinkIntent: DirectLinkIntent.MEDS_CONSULT,
          directLinkSource: DirectLinkSource.FORKED_TRIAGE,
        },
      })
    },
  }

  return (
    <ForkedTriageContainer navigationRoutes={isLoggedIn ? navigateToLoggedInRoutes : navigateToRegistrationRoutes} />
  )
}

const connector = connect(null, {
  setSelectedPathwayOption,
  setSearcherInfo,
  handleZeroViewSelect,
  setTriageEntryPoint,
})

export default connector(ForkedTriage)
