import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { createPageViewEventName, PAGES } from '@lyrahealth-inc/shared-app-logic'

import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'

export function useTrackViewPage(page: PAGES, properties?: { [key: string]: any }) {
  const dispatch = useAppDispatch()
  const [pageTracked, setPageTracked] = useState('')
  const { search } = useLocation()

  useEffect(() => {
    const track = () => {
      dispatch(trackEventWithObj({ event: createPageViewEventName(page), ...properties }))
    }
    if (page !== pageTracked && !search.includes('utm_medium=tbd')) {
      setPageTracked(page)
      track()
    }
  }, [dispatch, page, properties, pageTracked, search])
}
