import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { LayoutChangeEvent, Pressable, View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { useCountdownTimer } from '@lyrahealth-inc/shared-app-logic'

import {
  BodyText,
  CloseIcon,
  Headline,
  PrimaryButton,
  PrivacyIllustration,
  Subhead,
  TextButton,
  TimeoutIllustration,
} from '../../atoms'
import { HeadlineSize, SubheadSize } from '../../styles'
import { Flex1View } from '../../templates'
import { tID } from '../../utils/utils'

export interface InactivityTimeoutProps {
  warningDuration?: number
  onPressResume: () => void
  onPressSignOut: () => void
  onPressSignIn: () => void
  onPressClose?: () => void
  onWarningTimeUp: () => void
  onHeightChange?: (height: number) => void
  showClose?: boolean
  inactivityState?: INACTIVITY_STATE
  isWeb?: boolean
}

export enum INACTIVITY_STATE {
  WARNING = 'warning',
  SIGNED_OUT = 'signed_out',
}

interface InactivityContent {
  header: JSX.Element
  bodyText: JSX.Element
  buttons: { text: JSX.Element; onPress: () => void; other?: JSX.Element }
  image: JSX.Element
  imagePaddingTop?: number
  imagePaddingBottom?: number
}

const InactivityTimeoutContainer = styled(View)(({ theme }) => ({
  display: 'flex',
  flexDirection: theme.breakpoints.isMobileSized ? 'column-reverse' : 'row',
  justifyContent: 'space-between',
  padding: theme.breakpoints.isMinWidthTablet ? `16px` : '32px 16px 16px 16px',
}))

const ContentsContainer = styled(Flex1View)(({ theme }) => ({
  justifyContent: theme.breakpoints.isMinWidthTablet ? 'space-between' : 'flex-end',
  ...(theme.breakpoints.isMinWidthTablet && { maxWidth: '416px' }),
}))

const BodyContainer = styled(Flex1View)({})

const ButtonsContainer = styled.View(({ theme }) => ({
  flexDirection: theme.breakpoints.isMinWidthTablet ? 'row' : 'column',
  marginTop: theme.spacing['24px'],
  marginBottom: theme.breakpoints.isMinWidthTablet ? '0px' : theme.spacing['24px'],
}))

const ImageContainer = styled.View<{ paddingTop?: number; paddingBottom?: number }>(
  ({ theme, paddingTop = 0, paddingBottom = 0 }) => ({
    alignItems: 'center',
    paddingTop: `${paddingTop}px`,
    paddingBottom: `${paddingBottom}px`,
    alignSelf: 'center',
    flex: 1,
    ...(!theme.breakpoints.isMinWidthTablet && { flexDirection: 'row', flexBasis: 'auto' }),
  }),
)

const HeadlineStyled = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const SubheadStyled = styled(Subhead)(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const TextButtonStyled = styled(TextButton)(({ theme }) => ({
  alignSelf: 'center',
  ...(theme.breakpoints.isMinWidthTablet && { marginLeft: '40px' }),
  ...(!theme.breakpoints.isMinWidthTablet && { marginTop: '28px' }),
}))

const CloseIconContainer = styled(Pressable)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing['4px'],
  top: theme.spacing['4px'],
}))

export const InactivityTimeout: FunctionComponent<InactivityTimeoutProps> = ({
  warningDuration = 60,
  onPressResume,
  onPressSignOut,
  onPressSignIn,
  onPressClose,
  onWarningTimeUp,
  onHeightChange,
  showClose = false,
  inactivityState = INACTIVITY_STATE.WARNING,
  isWeb = true,
}) => {
  const [loggedOutState, setLoggedOutState] = useState(inactivityState)
  const countdown = useCountdownTimer(warningDuration, isWeb)

  const { breakpoints } = useTheme()
  const content: { [key in INACTIVITY_STATE]: InactivityContent } = {
    [INACTIVITY_STATE.WARNING]: {
      header: (
        <FormattedMessage
          defaultMessage='Are you still there?'
          description='Header text asking if the user is still active'
        />
      ),
      bodyText: (
        <FormattedMessage
          defaultMessage='We noticed you haven’t been active in a while. To protect your privacy we will sign you out in <bold>{countdown} {countdown, plural, =1 {second} other {seconds}}</bold>'
          description='Text warning user about how much time is left before they are logged out'
          values={{
            bold: (chunks: any) => {
              return <BodyText dataSet={{ chromatic: 'ignore' }} text={chunks} bold />
            },
            countdown: Math.floor(countdown),
          }}
        />
      ),
      buttons: {
        text: <FormattedMessage defaultMessage='Resume' description='Button text to stay logged in' />,
        onPress: onPressResume,
        other: (
          <TextButtonStyled
            text={<FormattedMessage defaultMessage='Sign out' description='Button text to sign out of the account' />}
            onPress={onPressSignOut}
            testID={tID('InactivityTimeout-secondaryButton')}
            accessibilityLabel={'Sign out'}
          />
        ),
      },
      image: <TimeoutIllustration useSmall={!breakpoints.isMinWidthTablet} />,
      imagePaddingBottom: breakpoints.isMinWidthLaptop ? 16 : 24,
    },
    [INACTIVITY_STATE.SIGNED_OUT]: {
      header: (
        <FormattedMessage
          defaultMessage='To protect your privacy, we’ve logged you out. '
          description='Header text stating user has been logged out'
        />
      ),
      bodyText: (
        <FormattedMessage
          defaultMessage='We’re always here so rejoin us when you’re ready.'
          description='Text telling user to come back to the app when ready'
        />
      ),
      buttons: {
        text: <FormattedMessage defaultMessage='Sign In' description='Button text to sign in' />,
        onPress: onPressSignIn,
      },
      image: <PrivacyIllustration useSmall={!breakpoints.isMinWidthTablet} />,
      imagePaddingBottom: breakpoints.isMinWidthLaptop ? 20 : 24,
    },
  }
  const activeContent = content[loggedOutState]

  const handleLayoutChange = (event: LayoutChangeEvent) => {
    if (onHeightChange) {
      onHeightChange(event.nativeEvent.layout.height)
    }
  }

  useEffect(() => {
    if (countdown === 0 && loggedOutState === INACTIVITY_STATE.WARNING) {
      setLoggedOutState(INACTIVITY_STATE.SIGNED_OUT)
      onWarningTimeUp()
    }
  }, [countdown, onWarningTimeUp, loggedOutState])

  return (
    <InactivityTimeoutContainer
      nativeID='InactivityTimeout'
      testID={tID('InactivityTimeout')}
      onLayout={handleLayoutChange}
    >
      {showClose && (
        <CloseIconContainer testID={tID('InactivityTimeout-close')} onPress={onPressClose}>
          <CloseIcon size={16} />
        </CloseIconContainer>
      )}
      <ContentsContainer>
        <BodyContainer testID={tID('InactivityTimeout-body')}>
          {!breakpoints.isMinWidthTablet ? (
            <SubheadStyled text={activeContent.header} size={SubheadSize.LARGE} />
          ) : (
            <HeadlineStyled text={activeContent.header} size={HeadlineSize.SMALL} />
          )}
          <BodyText text={activeContent.bodyText} />
        </BodyContainer>
        <ButtonsContainer>
          <PrimaryButton
            text={activeContent.buttons.text}
            onPress={activeContent.buttons.onPress}
            testID={tID('InactivityTimeout-primaryButton')}
            fullWidth={!breakpoints.isMinWidthTablet}
          />
          {activeContent.buttons.other}
        </ButtonsContainer>
      </ContentsContainer>
      <ImageContainer paddingTop={activeContent.imagePaddingTop} paddingBottom={activeContent.imagePaddingBottom}>
        {activeContent.image}
      </ImageContainer>
    </InactivityTimeoutContainer>
  )
}
