export enum CustomerName {
  WALMART = 'walmart',
  STARBUCKS = 'starbucks',
  APPLE = 'apple',
  ATT_UNION = 'attunion',
}

export type CustomerRedirectionProps = {
  shouldShowBanner: boolean
  onLinkPress: () => void
}

export type CustomerInstanceMap = {
  [customerName: string]: {
    instances: string[] // other instances' customer names. currently only support 1 alternative instance per customer
    launchDate?: string
  }
}

export type CustomerInstanceRedirectionProps = {
  customerInstanceMap: CustomerInstanceMap
  customerName: string
  navigate: (newCustomerName: string) => void
  trackEvent: (event: string, props?: Record<string, unknown>) => void
}

export type SubpopulationRedirectionProps = {
  customerInstanceMap: CustomerInstanceMap
  customerName: string
  newCustomerIndex: number
  navigate: (newCustomerName: string) => void
  trackEvent: (event: string, props?: Record<string, unknown>) => void
}
