declare global {
  interface Window {
    initializeZoho: (args: { widgetCode: string; languageCode: string; countryCode: string; testMode: boolean }) => void
    openZohoChat: (args: { companyName: string; countryName: string }) => void
  }
}

export const initializeICASChat = ({
  customerLanguage,
  countryIsoCode,
  testMode = true,
}: {
  customerLanguage: string
  countryIsoCode: string
  testMode?: boolean
}) => {
  const hostName = window.location.host
  const isProduction = !(hostName.includes('localhost') || hostName.includes('staging'))
  window.initializeZoho({
    widgetCode: isProduction
      ? 'c74e234b6c4a94b905020bcd1fd0c709771f8ad6d9d485deafa43f863bff06e5'
      : 'a8f1afc834944fe50b5aa15c77945685912129ebed265943b39d8c28c960d82cca798bf0fdc90ee130111e8acb6855c7',
    languageCode: customerLanguage,
    countryCode: countryIsoCode,
    testMode,
  })
}

export const openICASChat = ({ customerName, countryName }: { customerName: string; countryName: string }) => {
  try {
    window.openZohoChat({ companyName: customerName, countryName })
  } catch (error) {
    console.error('openICASChat failed with', error)
  }
}
