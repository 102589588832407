// @intl project:microsite

import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Pressable } from 'react-native'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { scroller } from 'react-scroll'

import classnames from 'classnames'
import { fromJS, List } from 'immutable'
import { isEmpty } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import {
  CARE_ADVISOR_EMAIL,
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  COMMON_REGISTRATION_SCREENS,
  ICAS_CONTACT_EMAIL,
  INTEGRATED_BENEFITS_EVENTS,
  LOGIN_EVENTS,
  LYRIANS_SUPPORT_LINK,
  MIXPANEL_CARE_NAVIGATOR_OPTIONS,
  MIXPANEL_EVENT_ATTRIBUTES,
  PATHWAYS_ENTRY_POINTS,
  REFERRAL_ENTRY_POINTS,
  S4C_EVENTS,
  S4C_PROPERTIES,
  shouldLinkToWorkHub,
  useFlags,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import { HamburgerMenu, NavButton, useIsMobile } from '@lyrahealth-inc/ui-core'
import {
  CareNavigatorHeaderEntryPointButton,
  HeaderNavMenu,
  Linking,
  LyraLogo,
  mailTo,
  openUrl,
  ReferButton,
  tID,
  toJS,
  useFetcher,
  useMediaQuerySize,
} from '@lyrahealth-inc/ui-core-crossplatform'
import { HeaderNavMenuProps } from '@lyrahealth-inc/ui-core-crossplatform/src/organisms/headerNavMenu/HeaderNavMenu'
import { benefitsHeaderText } from '@lyrahealth-inc/ui-core-crossplatform/src/pages/integratedBenefitsPage/IntegratedBenefitsPage'

import AccountSetupHeader from './AccountSetupHeader'
import DefaultHeader from './DefaultHeader'
import styles from './header.module.scss'
import LyraTherapyHeader from './LyraTherapyHeader'
import MobileHeader from './MobileHeader'
import NavBarHeader from './NavBarHeader'
import OnboardingNavBar from './OnboardingNavBar'
import RegistrationHeader from './RegistrationHeader'
import { setToastContent } from '../../../data/appGlobals/appGlobalsActions'
import { getDetectedCountryISO, getShouldShowLiveChat } from '../../../data/appGlobals/appGlobalsSelectors'
import { getCurrentCountryName } from '../../../data/currentCountry/currentCountrySelectors'
import {
  getBeforeLaunch,
  getCustomerLaunchDate,
  getCustomerName,
  getCustomerPhone,
  getCustomerWorkLifeBenefits,
  getDefaultDirectCareNavigatorBookingLink,
  getEssentialsDisabled,
  getHealthPlanExtensionEnabled,
  getHideExtraHeaderOptions,
  getIsCustomerLyra,
  getIsWorkhubDisabled,
} from '../../../data/customer/customerSelectors'
import { resetHealthPlan } from '../../../data/logout/logoutActions'
import * as MixpanelActions from '../../../data/mixpanel'
import { convertRouteToMixpanelPage, MIXPANEL_EVENTS, trackEventWithObj } from '../../../data/mixpanel'
import mixpanel from '../../../data/mixpanel/mixpanel-browser'
import {
  getDirectDedicatedCareNavigatorInfo,
  getDirectDedicatedCareNavigatorLink,
} from '../../../data/user/userActions'
import {
  getId,
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
  getShouldUserSeeFAQLinkInHamburgerMenu,
  getUserCountryCode,
  getUserDedicatedCareNavigatorInfo,
  getUserDirectDedicatedCareNavigatorLink,
  getUsername,
  getUserSalesforceId,
} from '../../../data/user/userSelectors'
import { getHealthPlanPaymentCardLast4 } from '../../../features/healthPlan/data/healthPlanSelectors'
import LanguageSelector from '../../../features/intl/LanguageSelector'
import { isInLoginFlow } from '../../../features/login/loginUtils'
import {
  HPI_ROUTES,
  PAGE_ROUTES,
  PROFILE_CONFIRMATION_PAGES,
  PROFILE_EDIT_PAGES,
} from '../../../features/onboard/data/page-navigation/location-actions'
import { isInRegisterFlow } from '../../../features/register/data/registerUtils'
import {
  BACKGROUND_INFO_FORM_PATH,
  BASIC_INFO_FORM_PATH,
  CARE_TEAM_BACKGROUND_INFO_FORM_PATH,
  CARE_TEAM_BASIC_INFO_FORM_PATH,
  HEALTH_PLAN_BACKGROUND_INFO_FORM_PATH,
  HEALTH_PLAN_BASIC_INFO_FORM_PATH,
  PARENT_LED_TEEN_WELCOME_PAGE_PATH,
  REQUIRE_ELIGIBILITY_FORM_PATH,
} from '../../constants/registrationFormConstants'
import { ICAS_CHILD_PATH, INTL_TREATMENT_RECOMMENDATIONS_PATH } from '../../constants/routingConstants'
import { withTrackIntegratedBenefits } from '../../hocs/withTrackIntegratedBenefits'
import { initializeICASChat, openICASChat } from '../../utils/ICASChatUtils'
import { rerouteFromMicrosite } from '../../utils/micrositeUtils'
import { goToCareNavigatorSchedulingPage } from '../../utils/onboardUtils'
import { shareReferral } from '../../utils/referralUtils'
import { matchPaths } from '../../utils/stringUtils'
import { hasRole } from '../../utils/userUtils'

export const Header = ({
  user,
  videoSessionOpen,
  isLyraTherapy,
  carepage,
  opaque,
  appointmentSize,
  shouldUserSeeFAQLinkInHamburgerMenu,
  essentialsDisabled,
  isWorkhubDisabled,
  beforeLaunch,
  isUserInternational,
  customerPhoneNumber,
  userId,
  resetHealthPlan,
  getDirectDedicatedCareNavigatorLink,
  userDirectDedicatedCareNavigatorLink,
  defaultDirectCareNavigatorBookingLink,
  email,
  isLyrian,
  salesforceId,
  launchDate,
  showLiveChat,
  isClientHPI,
  trackEventWithObj,
  countryIsoCode,
  customerName,
  countryName,
  isUserAssumedInternational,
  trackIntegratedBenefits,
  detectedCountryIsoCode,
  setToastContent,
  userDedicatedCareNavigatorInfo,
  getDirectDedicatedCareNavigatorInfo,
}: $TSFixMe) => {
  const { isMobileSized, isMinWidthLaptop } = useMediaQuerySize()
  const intl = useIntl()
  const { colors } = useTheme()
  const {
    isLanguageSelectorEnabled,
    isDisplayLanguageEnabled,
    shouldShowCareNavigatorHeaderEntrypoint,
    isInternationalFAQEnabled,
    isReferralEnabled,
    isReferralBannerEnabled,
    isDedicatedCareNavigatorEnabled,
  } = useFlags()
  const navigate = useNavigate()
  const { pathname, state, search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const entryPoint = queryParams.get('entryPoint')

  const isLoggedIn = !isEmpty(user)
  const { activeLanguage } = useLyraIntl()
  const pageName = useMemo(() => convertRouteToMixpanelPage(pathname), [pathname])

  const isFindEmployerPage = carepage && pathname === '/'

  const fromPathName = state?.from
  const fromLatestMatches =
    fromPathName &&
    matchPaths(fromPathName, [
      '/secure/onboard/t1-multi-providers/latest-provider-matches',
      '/secure/onboard/coaches/latest-provider-matches',
    ])
  const isRegistrationOnboardingPage = pathname.includes(PAGE_ROUTES.REGISTRATION_ONBOARDING)
  const entryPointByUrl = location.pathname.includes('/election')
    ? COMMON_MIXPANEL_PAGE.ELECTION_STRESS_TOOLKIT
    : location.pathname === '/'
    ? COMMON_MIXPANEL_PAGE.MICROSITE
    : null
  const [isMobile] = useIsMobile(Number(styles.x_tablet_min_width))

  const { PAGE, ENTRY_POINT } = COMMON_MIXPANEL_PROPERTIES

  const isInternational = isLoggedIn ? isUserInternational : isUserAssumedInternational

  // Initialize Care Navigator Entry Point variables
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [startChat, setStartChat] = useState(false)
  useEffect(() => {
    if (startChat) {
      setStartChat(false)
    }
  }, [startChat])

  useEffect(() => {
    if (isInternational) {
      const isoCode = countryIsoCode || detectedCountryIsoCode
      initializeICASChat({ customerLanguage: activeLanguage, countryIsoCode: isoCode, testMode: true })
    }
  }, [countryIsoCode, isInternational, detectedCountryIsoCode, activeLanguage])

  useEffect(() => {
    if (search.includes('utm_medium=tbd')) {
      shareReferral({
        source: 'welcome-email',
        formatMessage: intl.formatMessage,
        setToastContent,
        trackEventWithObj,
        isMobileSized,
        entryPoint: REFERRAL_ENTRY_POINTS.WELCOME_EMAIL,
      })
    }
  }, [customerName, intl.formatMessage, isMobileSized, search, setToastContent, trackEventWithObj])

  useFetcher([
    [
      getDirectDedicatedCareNavigatorInfo,
      [userId],
      isDedicatedCareNavigatorEnabled && userDedicatedCareNavigatorInfo == null,
    ],
  ])

  const onPressFAQ = () => {
    if (isLoggedIn) {
      openUrl(`${window.location.protocol}//${window.location.host}/faq`)
    } else {
      if (homePage) {
        setIsModalOpen(false)
        scroller.scrollTo('faq', { smooth: true })
      } else {
        navigate('/#faq')
      }
    }
  }

  const onPressEmail = () => {
    trackEventWithObj({
      event: MIXPANEL_EVENTS.CLICK_EMAIL_CARE_NAVIGATOR,
      [PAGE]: COMMON_MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [ENTRY_POINT]: COMMON_MIXPANEL_PAGE.GLOBAL_NAV_CARE_NAVIGATOR,
    })
    if (isLyrian) {
      window.open(LYRIANS_SUPPORT_LINK, '_blank', 'noreferrer')
    } else if (isInternational) {
      mailTo(ICAS_CONTACT_EMAIL)
    } else {
      mailTo(CARE_ADVISOR_EMAIL)
    }
  }

  const onPressLiveChat = () => {
    trackEventWithObj({
      event: MIXPANEL_EVENTS.CHAT_LIVE,
      [PAGE]: COMMON_MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [ENTRY_POINT]: COMMON_MIXPANEL_PAGE.GLOBAL_NAV_CARE_NAVIGATOR,
    })
    if (isInternational) {
      setIsModalOpen(false)
      openICASChat({ customerName, countryName })
    } else {
      setIsModalOpen(false)
      setStartChat(!startChat)
    }
  }

  const onPressPhoneCall = () => {
    trackEventWithObj({
      event: MIXPANEL_EVENTS.CLICK_CARE_NAVIGATOR_PHONE_NUMBER,
      [PAGE]: COMMON_MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [ENTRY_POINT]: COMMON_MIXPANEL_PAGE.GLOBAL_NAV_CARE_NAVIGATOR,
    })
    if (isInternational || !isLoggedIn) {
      return navigate('/care-team-phone-numbers')
    }
    return Linking.openURL(`tel:${customerPhoneNumber}`)
  }

  const onPressScheduleCall = () => {
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_SCHEDULE_A_CN_CALL,
      [PAGE]: COMMON_MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [ENTRY_POINT]: COMMON_MIXPANEL_PAGE.GLOBAL_NAV_CARE_NAVIGATOR,
    })
    const getCareNavigatorLink = isDedicatedCareNavigatorEnabled
      ? Promise.resolve(userDedicatedCareNavigatorInfo.ScheduleOnceURL)
      : getDirectDedicatedCareNavigatorLink(userId).catch((error: string) => {
          console.error('Failed to get the user’s dedicated care navigator link', error)
          return userDirectDedicatedCareNavigatorLink
        })

    getCareNavigatorLink.then((directDedicatedCareNavigatorLink: string) => {
      goToCareNavigatorSchedulingPage({
        defaultDirectCareNavigatorBookingLink,
        directDedicatedCareNavigatorLink,
        name,
        email,
        salesforceId,
        launchDate,
        isLyrian,
      })
    })
  }

  if (isReferralBannerEnabled && search.includes('utm_source=coming-soon-microsite-banner')) {
    return null
  }

  if (carepage && pathname !== '/' && !isLoggedIn) {
    return null
  }

  if (pathname.includes('satisfaction') || pathname.includes('verify-attestation')) {
    return null
  }

  // Use container headers for health plan and payment forms
  if (
    HPI_ROUTES.some((hpiRoute) => pathname.includes(hpiRoute) && pathname.includes(PAGE_ROUTES.PROFILE.COVERAGE_CHECK))
  ) {
    return null
  }

  if (matchPaths(location.pathname, PROFILE_EDIT_PAGES) || matchPaths(location.pathname, PROFILE_CONFIRMATION_PAGES)) {
    return null
  }

  if (
    matchPaths(location.pathname, [
      '/secure/onboard/about',
      '/secure/onboard/about/:id',
      '/secure/onboard/treatment-options',
      '/secure/onboard/match-location',
      `/secure/index/${ICAS_CHILD_PATH}`,
      `/secure/index/${INTL_TREATMENT_RECOMMENDATIONS_PATH}`,
    ])
  ) {
    // Triage, Treatment recs, and registration onboarding have custom navigation actions, so the navbar is included in the respective containers.
    return null
  } else if (
    pathname.includes('/secure/onboard/') ||
    pathname.includes('/secure/coverage-check/onboard') ||
    pathname.includes('/secure/coverage-check/option') ||
    pathname.includes('/secure/coverage-check/provider') ||
    matchPaths(location.pathname, ['/secure/wellness/toolkit/:id'])
  ) {
    const hideBackButton =
      matchPaths(location.pathname, ['/secure/wellness/toolkit/:id']) &&
      entryPoint === COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_BACKGROUND_INFO
    return <OnboardingNavBar showBackButton={!hideBackButton} />
  }

  // Hide the header for when user is within assignment subpages
  if (!isMinWidthLaptop && pathname.includes('assignments/')) {
    return null
  }

  const handleLogin = () => {
    const state = entryPointByUrl ? { state: { mixpanelEntryPoint: entryPointByUrl } } : {}
    navigate(PAGE_ROUTES.LOGIN, state)
  }

  const handleLogout = () => {
    trackEventWithObj({
      event: LOGIN_EVENTS.LOG_OUT,
    })
    mixpanel.reset()
    resetHealthPlan()
    pendo?.clearSession?.()
    window.location.href = '/logout'
  }

  const handleProfile = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault()
    trackEventWithObj({
      event: MixpanelActions.MIXPANEL_EVENTS.BUTTON_PRESS,
      action: MixpanelActions.MIXPANEL_ACTION.PROFILE,
    })
    navigate(PAGE_ROUTES.PROFILE.DEFAULT)
  }

  const handleBenefitsClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault()
    trackIntegratedBenefits({
      event: INTEGRATED_BENEFITS_EVENTS.VIEW_INTEGRATED_BENEFITS_PAGE,
      entryPoint: COMMON_MIXPANEL_PAGE.HOME_BASE,
      action: MixpanelActions.MIXPANEL_ACTION.MENU,
    })
    navigate('/secure/index/search-care/benefits')
  }

  const handleMenuBtnClick = () => {
    trackEventWithObj({
      event: MixpanelActions.MIXPANEL_EVENTS.BUTTON_PRESS,
      action: MixpanelActions.MIXPANEL_ACTION.MENU,
    })
  }

  const handleGetCareClick = () => {
    rerouteFromMicrosite({ user, navigate })
  }

  const handleSignUpClick = () => {
    rerouteFromMicrosite({
      user,
      navigate,
      mixpanelEntryPoint: entryPointByUrl || PATHWAYS_ENTRY_POINTS.GLOBAL_NAV_BAR,
    })
  }

  const handleFaqClick = (
    e?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e?.preventDefault()
    trackEventWithObj({
      event: MixpanelActions.MIXPANEL_EVENTS.BUTTON_PRESS,
      action: MixpanelActions.MIXPANEL_ACTION.GO_TO_FAQ,
    })
    if (isLoggedIn) {
      navigate('/faq')
    } else {
      navigate('/#faq')
    }
  }

  const handleReferralClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault()

    shareReferral({
      source: 'hamburger-menu',
      formatMessage: intl.formatMessage,
      setToastContent,
      trackEventWithObj,
      isMobileSized,
      entryPoint: REFERRAL_ENTRY_POINTS.HAMBURGER_MENU,
    })
  }

  const handleHomeClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault()
    navigate('/secure/index/search-care')
  }

  const handleCareClick = () => {
    navigate('/secure/index/assignments', {
      state: { [COMMON_MIXPANEL_PROPERTIES.REFERRER_PAGE]: pageName },
    })
  }

  const handleSessionsClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault()
    navigate('/secure/index/sessions')
  }

  const handleProgressClick = () => {
    navigate('/secure/index/progress')
  }

  const handleEssentialsClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault()
    shouldLinkToWorkHub(essentialsDisabled === true, isWorkhubDisabled)
      ? navigate(
          `/secure/index/work-hub?${COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT}=${MIXPANEL_EVENT_ATTRIBUTES.ENTRY_POINT.NAV_BAR}`,
          {
            state: { [COMMON_MIXPANEL_PROPERTIES.REFERRER_PAGE]: pageName },
          },
        )
      : navigate(
          `/secure/index/essentials?${COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT}=${MIXPANEL_EVENT_ATTRIBUTES.ENTRY_POINT.NAV_BAR}`,
          {
            state: { [COMMON_MIXPANEL_PROPERTIES.REFERRER_PAGE]: pageName },
          },
        )
  }

  const shouldShowFAQs = () => shouldUserSeeFAQLinkInHamburgerMenu || isInternationalFAQEnabled

  const shouldShowEssentialsLT = () => !essentialsDisabled || !isWorkhubDisabled

  const shouldShowEssentials = () =>
    isLoggedIn && !carepage && !isLyraTherapy && shouldShowEssentialsLT() && !isRegistrationOnboardingPage

  const shouldShowHome = () => isLoggedIn && !carepage && !isLyraTherapy && !isRegistrationOnboardingPage

  const shouldShowSessions = () =>
    isLoggedIn && !carepage && appointmentSize > 0 && !isLyraTherapy && !isRegistrationOnboardingPage

  const shouldShowReferral = () => isReferralEnabled

  const mixpanelCareNavigatorClick = () => {
    const entrypoint = entryPointByUrl ? entryPointByUrl : COMMON_MIXPANEL_PAGE.GLOBAL_NAV_CARE_NAVIGATOR
    trackEventWithObj({
      event: S4C_EVENTS.CLICK_TO_CONTACT_CN,
      [ENTRY_POINT]: entrypoint,
    })
    trackEventWithObj({
      event: S4C_EVENTS.VIEW_GENERAL_CONTACT_CN_TEAM,
      [ENTRY_POINT]: entrypoint,
      [S4C_PROPERTIES.CONTACT_OPTIONS]: MIXPANEL_CARE_NAVIGATOR_OPTIONS({
        isInternationalUser: isInternational,
        isLyrian,
        isSidePanel: true,
      }),
    })
  }

  if (pathname === REQUIRE_ELIGIBILITY_FORM_PATH || pathname === PARENT_LED_TEEN_WELCOME_PAGE_PATH) {
    return (
      <NavBarHeader
        style={{ backgroundColor: colors.backgroundSecondary }}
        onExit={pathname === REQUIRE_ELIGIBILITY_FORM_PATH ? handleLogout : () => navigate(PAGE_ROUTES.SECURE_INDEX)}
        borderBottomColor={pathname === PARENT_LED_TEEN_WELCOME_PAGE_PATH ? 'transparent' : undefined}
        shouldShowCareNavigatorHeaderEntrypoint
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isInternational={isInternational}
        customerPhoneNumber={customerPhoneNumber}
        isMobile={isMobileSized}
        isLyrian={isLyrian}
        showLiveChat={showLiveChat}
        startLiveChat={startChat}
        onPressEmail={onPressEmail}
        onPressFAQ={onPressFAQ}
        onPressPhoneCall={onPressPhoneCall}
        onPressScheduleCall={onPressScheduleCall}
        onPressLiveChat={onPressLiveChat}
        onExitContactCareNavigatorModal={() => setIsModalOpen(false)}
        onCareNavigatorHeaderEntryPointButtonPress={() => {
          setIsModalOpen(!isModalOpen)
          mixpanelCareNavigatorClick()
        }}
        userDedicatedCareNavigatorInfo={userDedicatedCareNavigatorInfo}
      />
    )
  }

  if (
    pathname.includes(PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE) ||
    pathname.includes(PAGE_ROUTES.PATHWAYS.SELF_CARE) ||
    pathname.includes(PAGE_ROUTES.FAMILY_CARE_OPTIONS) ||
    (pathname.includes(PAGE_ROUTES.SEARCH_CARE.BENEFITS) && isMobileSized)
  ) {
    const extraProps = pathname.includes(PAGE_ROUTES.SEARCH_CARE.BENEFITS)
      ? {
          showBackButton: true,
          title: benefitsHeaderText,
          backgroundColor: colors.backgroundPrimary,
          shouldShowCareNavigatorHeaderEntrypoint: false,
          borderBottomColor: colors.borderDefault,
        }
      : { shouldShowCareNavigatorHeaderEntrypoint: true, isTransparent: true }
    return (
      <NavBarHeader
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isInternational={isInternational}
        customerPhoneNumber={customerPhoneNumber}
        isMobile={isMobileSized}
        isLyrian={isLyrian}
        showLiveChat={showLiveChat}
        startLiveChat={startChat}
        showScheduleCall={isLoggedIn}
        onBack={() => navigate(-1)}
        onPressEmail={onPressEmail}
        onPressFAQ={onPressFAQ}
        onPressPhoneCall={onPressPhoneCall}
        onPressScheduleCall={onPressScheduleCall}
        onPressLiveChat={onPressLiveChat}
        onExitContactCareNavigatorModal={() => setIsModalOpen(false)}
        onCareNavigatorHeaderEntryPointButtonPress={() => {
          if (isDedicatedCareNavigatorEnabled) getDirectDedicatedCareNavigatorInfo(userId)
          setIsModalOpen(!isModalOpen)
        }}
        userDedicatedCareNavigatorInfo={userDedicatedCareNavigatorInfo}
        {...extraProps}
      />
    )
  }

  const generateLogoHref = () => {
    if (isLoggedIn && hasRole(user, 'ROLE_care_manager')) {
      return '/secure/care-manager/provider-matcher/'
    } else if (isLoggedIn && hasRole(user, 'ROLE_usr')) {
      return '/secure/index/'
    } else {
      return '/'
    }
  }

  const logoClickHandler = () => {
    navigate(generateLogoHref())
  }

  const homePage = ['/'].includes(pathname)

  const LOGGED_IN_BUTTONS = [
    {
      condition: () => carepage,
      button: (
        <NavButton
          href='/secure/care-manager/provider-matcher/'
          text={intl.formatMessage({ defaultMessage: 'Direct Access', description: 'Link to direct access' })}
          styleType=''
          key='navButton'
        />
      ),
    },
    {
      condition: shouldShowHome,
      button: (
        <li>
          {/* @ts-expect-error TS(2322): Type '{ children: Element; href: string; className... Remove this comment to see the full error message */}
          <a
            href='#0'
            className={`${pathname.includes('search-care') ? 'active' : ''} lt-searchforcare updated-look-feel`}
            aria-current={pathname.includes('search-care') ? 'page' : null}
            data-test-id='LTHeader-homeTab'
            onClick={(e) => {
              handleHomeClick(e)
            }}
          >
            <FormattedMessage defaultMessage='Home' description='Link to the home page' />
          </a>
        </li>
      ),
    },
    {
      condition: shouldShowSessions,
      button: (
        <li>
          {/* @ts-expect-error TS(2322): Type '{ children: Element; href: string; className... Remove this comment to see the full error message */}
          <a
            href='#0'
            className={`${pathname.includes('sessions') ? 'active' : ''} lt-sessions updated-look-feel`}
            aria-current={pathname.includes('sessions') ? 'page' : null}
            data-test-id='Header-appointmentsTab'
            onClick={(e) => {
              handleSessionsClick(e)
            }}
          >
            <FormattedMessage
              defaultMessage='Sessions'
              description="Link to the appointments dashboard page which shows the patient's scheduled sessions"
            />
          </a>
        </li>
      ),
    },
    {
      condition: shouldShowEssentials,
      button: (
        <li>
          {/* @ts-expect-error TS(2322): Type '{ children: Element; href: string; className... Remove this comment to see the full error message */}
          <a
            href='#0'
            className={`${
              pathname.includes('essentials') || pathname.includes('work-hub') ? 'active' : ''
            } lt-essentials updated-look-feel`}
            aria-current={pathname.includes('essentials') || pathname.includes('work-hub') ? 'page' : null}
            data-test-id='Header-essentialsTab'
            onClick={(e) => {
              handleEssentialsClick(e)
            }}
          >
            <FormattedMessage defaultMessage='Library' description='Link to Lyra Essentials and Work Hub' />
          </a>
        </li>
      ),
    },
  ]

  const isLogInPage = isInLoginFlow(pathname)
  const loggedInButtons = LOGGED_IN_BUTTONS.map(({ condition, button }) => {
    return condition() && button
  })
  const LOGGED_OUT_BUTTONS = [
    {
      condition: () => (isLanguageSelectorEnabled && !isLoggedIn && (homePage || isFindEmployerPage)) || beforeLaunch,
      button: <LanguageSelector textColor={colors.linkDefault} customActionTextColor={colors.linkHover} />,
    },
    {
      condition: () => !carepage && isLogInPage && isReferralEnabled,
      button: (
        <ReferButton
          onPress={() => {
            shareReferral({
              source: 'login-page',
              formatMessage: intl.formatMessage,
              setToastContent,
              trackEventWithObj,
              isMobileSized,
              entryPoint: REFERRAL_ENTRY_POINTS.LOGIN_PAGE,
            })
          }}
        />
      ),
    },

    {
      condition: () => isDisplayLanguageEnabled && ((isLogInPage && !carepage) || isRegistrationOnboardingPage),
      button: <LanguageSelector textColor={colors.linkDefault} dropdownAlignment='right' />,
    },
    {
      condition: () => !isLoggedIn && !isLogInPage && !beforeLaunch,
      button: (
        <HamburgerMenu
          className='hidden-sm hidden-md hidden-lg'
          alignment='right'
          iconColor={!opaque ? 'white' : undefined}
          key='hamburgerMenu'
        >
          <li>
            <a href='#0' onClick={handleGetCareClick}>
              <FormattedMessage
                defaultMessage='Get Care'
                description='A link for a prospective client to start searching for care'
              />
            </a>
          </li>
          <li>
            <a href='#0' onClick={handleLogin}>
              <FormattedMessage defaultMessage='Log In' description='A short link to the login page' />
            </a>
          </li>
        </HamburgerMenu>
      ),
    },
  ]
  const loggedOutButtons = LOGGED_OUT_BUTTONS.map(({ condition, button }) => {
    return condition() && button
  })

  const pathsWithBackButton = ['/secure/index/provider/', '/secure/index/coach/', '/secure/onboard']
  const handleBackButtonClick: (() => void) | undefined =
    pathsWithBackButton.some((path) => pathname.startsWith(path)) && !fromLatestMatches
      ? () => {
          navigate(-1)
        }
      : undefined
  let customClass = ''
  const lyraLogo = (
    <Pressable
      onPress={logoClickHandler}
      accessibilityRole='button'
      accessibilityLabel={intl.formatMessage({
        defaultMessage: 'Lyra Logo',
        description: 'Button that takes user to home page',
      })}
      testID={tID('Header-Lyra-Logo')}
    >
      <LyraLogo width={54} height={31} fillColor={colors.linkDefault} />
    </Pressable>
  )
  let ghosted = false
  if (homePage || beforeLaunch || isFindEmployerPage) {
    customClass = classnames(styles['header-microsite-ghosted-relative-transparent'], {
      [styles.loggedOut]: !isLoggedIn,
    })
    if (opaque && !carepage) {
      customClass = `${styles['header-microsite-opaque']}`
    } else {
      ghosted = true
    }
  } else if (isInRegisterFlow(pathname) || isLogInPage || pathname.includes(PAGE_ROUTES.REGISTRATION_ONBOARDING)) {
    customClass = styles['transparent-header']
  } else {
    customClass = styles['lt-header']
    if (videoSessionOpen) {
      customClass = classnames(customClass, styles['video-session-open'])
    }
  }

  const pathsWithMobileHeader = [
    '/care-team-phone-numbers',
    '/secure/profile/update-location',
    '/secure/profile/update-notification-settings',
    '/secure/profile/update-personal-information',
    '/secure/profile/update-child-information',
    PAGE_ROUTES.PROFILE.ELIGIBILITY,
    PAGE_ROUTES.PROFILE.SETTINGS,
    PAGE_ROUTES.PROFILE.SESSION_HISTORY,
    PAGE_ROUTES.PROFILE.HEALTH_PLAN_AND_PAYMENTS,
    PAGE_ROUTES.PROFILE.PRIVACY_AND_LEGAL,
  ]
  if (isMobile && pathsWithMobileHeader.includes(pathname)) {
    return <MobileHeader pathname={pathname} isClientHPI={isClientHPI} />
  }

  if (
    [
      BASIC_INFO_FORM_PATH,
      BACKGROUND_INFO_FORM_PATH,
      HEALTH_PLAN_BASIC_INFO_FORM_PATH,
      HEALTH_PLAN_BACKGROUND_INFO_FORM_PATH,
      CARE_TEAM_BASIC_INFO_FORM_PATH,
      CARE_TEAM_BACKGROUND_INFO_FORM_PATH,
    ].some((path) => pathname.startsWith(path))
  ) {
    return (
      <AccountSetupHeader
        customClass={customClass}
        handleBackButtonClick={handleBackButtonClick}
        handleLogout={handleLogout}
        handleLogin={handleLogin}
        trackMenuClick={handleMenuBtnClick}
        lyraLogo={lyraLogo}
        // @ts-expect-error TS(2322): Type '{ customClass: string; handleBackButtonClick... Remove this comment to see the full error message
        handleRegister={handleGetCareClick}
        ghost={ghosted}
      />
    )
  }

  const hideLoginButton =
    [
      '/login',
      '/worklife',
      '/unsubscribe',
      '/verify-attestation',
      '/forgot-password',
      '/reset-password',
      PAGE_ROUTES.CARE_TEAM_PHONE_NUMBERS,
      PAGE_ROUTES.REGISTRATION_ONBOARDING,
      PAGE_ROUTES.PATHWAYS.SELF_CARE,
    ].includes(pathname) ||
    beforeLaunch ||
    carepage
  const navigationMenuProps = {
    className: classnames(
      styles.loggedInNavigationHeader,
      shouldShowCareNavigatorHeaderEntrypoint && styles['no-margin'],
    ),
  }
  if (['/register'].some((path) => pathname.startsWith(path))) {
    return (
      <RegistrationHeader
        customClass={customClass}
        handleBackButtonClick={handleBackButtonClick}
        handleLogout={handleLogout}
        handleLogin={handleLogin}
        trackMenuClick={handleMenuBtnClick}
        lyraLogo={lyraLogo}
        handleRegister={handleGetCareClick}
        ghost={ghosted}
      />
    )
  }

  const headerNavMenuProps: HeaderNavMenuProps = {
    handleBenefitsPress: handleBenefitsClick,
    handleAccountPress: handleProfile,
    handleFAQsPress: handleFaqClick,
    handleReferralPress: handleReferralClick,
    handleLogOutPress: handleLogout,
    handleHomePress: handleHomeClick,
    handleCarePress: handleCareClick,
    handleSessionsPress: handleSessionsClick,
    handleProgressPress: handleProgressClick,
    handleEssentialsPress: handleEssentialsClick,
    shouldShowFAQs,
    shouldShowReferral,
    shouldShowEssentials: shouldShowEssentialsLT,
    currentPathname: pathname,
    trackMenuClick: handleMenuBtnClick,
  }

  const headerNavMenuLT = {
    ...headerNavMenuProps,
    shouldShowEssentials: shouldShowEssentialsLT,
    shouldShowProgress: () => true,
    shouldShowCare: () => true,
    shouldShowHome: () => true,
    shouldShowSessions: () => true,
  }

  const headerNavMenuPropsDefaultHeader = {
    ...headerNavMenuProps,
    shouldShowHome,
    shouldShowSessions,
    shouldShowEssentials,
  }

  const CareNavigatorHeaderEntryPointButtonComponent = (
    <CareNavigatorHeaderEntryPointButton
      isActive={isModalOpen}
      isInternational={isInternational}
      onPress={() => {
        if (!homePage || isLoggedIn) getDirectDedicatedCareNavigatorInfo(userId)
        mixpanelCareNavigatorClick()
        setIsModalOpen(!isModalOpen)
      }}
    />
  )

  const lyraTherapyHeader = (
    <LyraTherapyHeader
      customClass={customClass}
      handleBackButtonClick={handleBackButtonClick}
      handleLogout={handleLogout}
      handleLogin={handleLogin}
      trackMenuClick={handleMenuBtnClick}
      user={fromJS(user)}
      lyraLogo={lyraLogo}
      shouldHideLoginButton={hideLoginButton}
      handleRegister={handleGetCareClick}
      loggedOutButtons={loggedOutButtons}
      loggedInButtons={loggedInButtons}
      ghost={ghosted}
      activeTab={pathname}
      essentialsDisabled={essentialsDisabled}
      isWorkhubDisabled={isWorkhubDisabled}
      shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      isInternational={isInternational}
      customerPhoneNumber={customerPhoneNumber}
      onPressEmail={onPressEmail}
      onPressFAQ={onPressFAQ}
      onPressPhoneCall={onPressPhoneCall}
      onPressScheduleCall={onPressScheduleCall}
      onPressLiveChat={onPressLiveChat}
      onExit={() => setIsModalOpen(!isModalOpen)}
      isMobile={isMobileSized}
      mixpanelCareNavigatorClick={mixpanelCareNavigatorClick}
      isLyrian={isLyrian}
      showLiveChat={showLiveChat}
      startLiveChat={startChat}
      navigationMenuProps={navigationMenuProps}
      headerNavMenu={<HeaderNavMenu {...headerNavMenuLT} />}
      handleHomeClick={handleHomeClick}
      handleCareClick={handleCareClick}
      handleSessionsClick={handleSessionsClick}
      handleProgressClick={handleProgressClick}
      handleEssentialsClick={handleEssentialsClick}
      userDedicatedCareNavigatorInfo={userDedicatedCareNavigatorInfo}
      CareNavigatorHeaderEntryPointButton={CareNavigatorHeaderEntryPointButtonComponent}
    />
  )

  const showCareNavigatorButtonInMicrositeHeader = homePage && !beforeLaunch && !carepage

  const defaultHeader = (
    <DefaultHeader
      customClass={customClass}
      handleBackButtonClick={handleBackButtonClick}
      handleLogout={handleLogout}
      handleLogin={handleLogin}
      trackMenuClick={handleMenuBtnClick}
      user={isRegistrationOnboardingPage ? undefined : fromJS(user)}
      lyraLogo={lyraLogo}
      handleLogoClick={logoClickHandler}
      shouldHideLoginButton={hideLoginButton}
      handleRegister={handleGetCareClick}
      loggedOutButtons={loggedOutButtons}
      loggedInButtons={loggedInButtons}
      loginButtonClassName={styles.updatedLoginButton}
      ghost={ghosted}
      navigationMenuProps={navigationMenuProps}
      shouldShowCareNavigatorHeaderEntrypoint={
        (shouldShowCareNavigatorHeaderEntrypoint && isLoggedIn && !isRegistrationOnboardingPage) ||
        showCareNavigatorButtonInMicrositeHeader
      }
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      isInternational={isInternational}
      customerPhoneNumber={customerPhoneNumber}
      onPressEmail={onPressEmail}
      onPressFAQ={onPressFAQ}
      onPressPhoneCall={onPressPhoneCall}
      onPressScheduleCall={onPressScheduleCall}
      onPressLiveChat={onPressLiveChat}
      onExit={() => setIsModalOpen(!isModalOpen)}
      isMobile={isMobileSized}
      isLyrian={isLyrian}
      showLiveChat={showLiveChat}
      startLiveChat={startChat}
      showScheduleCall={isLoggedIn}
      handleSignUpClick={handleSignUpClick}
      headerNavMenu={<HeaderNavMenu {...headerNavMenuPropsDefaultHeader} />}
      userDedicatedCareNavigatorInfo={userDedicatedCareNavigatorInfo}
      CareNavigatorHeaderEntryPointButton={CareNavigatorHeaderEntryPointButtonComponent}
    />
  )

  return isLyraTherapy && !videoSessionOpen ? lyraTherapyHeader : defaultHeader
}

const mapStateToProps = ($$state: $TSFixMe, ownProps: $TSFixMe) => {
  const customerName = getCustomerName($$state)
  const episodes = $$state.getIn(['lyraTherapy', 'episodes'], List())
  const isLyraTherapy = !episodes.isEmpty()

  return {
    isLyraTherapy,
    appointmentSize: $$state.getIn(['appointmentDashboard', 'appointments']).size ?? 0,
    user: $$state.get('user'),
    videoSessionOpen: $$state.getIn(['lyraTherapy', 'video', 'sessionOpen']),
    carepage: ownProps.carepage ?? customerName === 'care',
    opaque: ownProps.opaque ?? customerName === 'care',
    worklifeEnabled: !!getCustomerWorkLifeBenefits($$state),
    hideExtraHeaderOptions: getHideExtraHeaderOptions($$state),
    shouldUserSeeFAQLinkInHamburgerMenu: getShouldUserSeeFAQLinkInHamburgerMenu($$state),
    essentialsDisabled: getEssentialsDisabled($$state),
    isWorkhubDisabled: getIsWorkhubDisabled($$state),
    beforeLaunch: getBeforeLaunch($$state),
    isUserInternational: getIsUserInternational($$state),
    customerPhoneNumber: getCustomerPhone($$state),
    userId: getId($$state),
    userDirectDedicatedCareNavigatorLink: getUserDirectDedicatedCareNavigatorLink($$state),
    defaultDirectCareNavigatorBookingLink: getDefaultDirectCareNavigatorBookingLink($$state),
    email: getUsername($$state),
    isLyrian: getIsCustomerLyra($$state),
    salesforceId: getUserSalesforceId($$state),
    launchDate: getCustomerLaunchDate($$state),
    showLiveChat: getShouldShowLiveChat($$state),
    isClientHPI: getHealthPlanExtensionEnabled($$state) || getHealthPlanPaymentCardLast4($$state),
    countryIsoCode: getUserCountryCode($$state),
    customerName,
    countryName: getCurrentCountryName($$state),
    isUserAssumedInternational: getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer($$state),
    detectedCountryIsoCode: getDetectedCountryISO($$state),
    userDedicatedCareNavigatorInfo: getUserDedicatedCareNavigatorInfo($$state),
  }
}

const mapDispatchToProps = {
  getDirectDedicatedCareNavigatorLink,
  trackEventWithObj,
  resetHealthPlan,
  setToastContent,
  getDirectDedicatedCareNavigatorInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(withTrackIntegratedBenefits(Header)))
